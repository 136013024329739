import React, { useEffect, useState } from 'react';
import IncludeSideBar from '../../../Components/Sidebar/IncludeSideBar';

function ReportedUsers() {
  const generateReport = require('../../../apis/generate_report');
  const [reportData, setReportData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isFocused, setIsFocused] = useState(false)
  const [inputPageNumber, setInputPageNumber] = useState(''); // State to hold the input page number
  const [inputFocus, setInputFocus] = useState(false);
  const [buttonFocus, setButtonFocus] = useState(false);
  const [pageWindow, setPageWindow] = useState({ start: 1, end: 20 });

  const [isLoading, setIsLoading] = useState(true);

  
  const generateReportvideo = async (page, searchTerm = '') => {
    try {
      setIsLoading(true)

      const response = await generateReport.generateReport(page, searchTerm);
      console.log(response, "response");
      setReportData(response.data);
      setTotalPages(response.pagination.totalPages);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching videoReport:", error);
      // Handle the error as needed
      setIsLoading(false);

    }
  };

  useEffect(() => {
    generateReportvideo(currentPage, searchTerm);
  }, [currentPage, searchTerm]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // const handleNext = () => {
  //   if (currentPage < totalPages) {
  //     setCurrentPage(currentPage + 1);
  //     setPageWindow({
  //       start: Math.floor((currentPage - 1) / 20) * 20 + 1,
  //       end: Math.min(Math.floor((currentPage - 1) / 20) * 20 + 20, totalPages),
  //     });
  //   }
   
  // };

  const handleNext = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
  
      // Check if the new page is outside the current page window, update the window
      if (newPage > pageWindow.end) {
        setPageWindow({
          start: pageWindow.start + 20,
          end: Math.min(pageWindow.end + 20, totalPages),
        });
      }
    }
  };
  const handlePrevious = () => {
    if (currentPage > 1) {
      // setCurrentPage((prevPage) => prevPage - 1);
      setCurrentPage(currentPage - 1);
      setPageWindow({
        start: Math.floor((currentPage - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((currentPage - 1) / 20) * 20 + 20, totalPages),
      });
     
    }
  };
 
  

  const handleNextPageWindow = () => {
    if (pageWindow.end < totalPages) {
      setPageWindow({
        start: pageWindow.start + 19,
        end: Math.min(pageWindow.end + 19, totalPages),
      });
    }
  };

  const handlePreviousPageWindow = () => {
    if (pageWindow.start > 1) {
      setPageWindow({
        start: pageWindow.start - 20,
        end: pageWindow.end - 20,
      });
    }
  };

  const handlePageClick = (page) => {
    handlePageChange(page);
    if (page === pageWindow.end && pageWindow.end < totalPages) {
      handleNextPageWindow();
    } else if (page === pageWindow.start && pageWindow.start > 1) {
      handlePreviousPageWindow();
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    setPageWindow({
      start: Math.floor((totalPages - 1) / 20) * 20 + 1,
      end: totalPages,
    });
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    setPageWindow({
      start: 1,
      end: Math.min(20, totalPages),
    });
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(inputPageNumber, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setPageWindow({
        start: Math.floor((pageNumber - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((pageNumber - 1) / 20) * 20 + 20, totalPages),
      });
      setInputPageNumber('');
    } else {
      alert(`Please enter a valid page number between 1 and ${totalPages}`);
    }
  };
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };


  return (
    <IncludeSideBar>
      <div>
        <p>
          Here we are displaying the All reports.
        </p>
        {reportData.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Description</th>
                <th>Reason</th>
                <th>Reporter ID/Email/Username</th>
                <th>Video ID</th>
                <th>Created At</th>
                <th>Updated At</th>
              </tr>
            </thead>
            <tbody>
              {reportData.map((report) => (
                <tr key={report.id}>
                  <td>{report.id}</td>
                  <td>{report.description}</td>
                  <td>{report.reason}</td>
                  <td>{report.reporterId}--{report.reportedUser.email}--{report.reportedUser.username}</td>
                  <td>{report.videoId}</td>
                  <td>{new Date(report.createdAt).toLocaleDateString()}</td>
                  <td>{new Date(report.updatedAt).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className="pagination">
          <button onClick={handleFirstPage} disabled={currentPage === 1}>
            First
          </button>
          <button onClick={handlePrevious} disabled={currentPage === 1}>
            Previous
          </button>
          {/* {Array.from(
            { length: pageWindow.end - pageWindow.start + 1 },
            (_, index) => pageWindow.start + index
          ).map((page) => (
            <button
              key={page}
              onClick={() => handlePageClick(page)}
              className={currentPage === page ? 'active' : ''}
              style={{
                backgroundColor: currentPage === page ? 'red' : '',
                color: 'white',
              }}
            >
              {page}
            </button>
          ))} */}
          {Array.from(
    { 
      length: Math.min(pageWindow.end - pageWindow.start + 1, totalPages) 
    },
    (_, index) => pageWindow.start + index
  ).map((page) => (
    <button
      key={page}
      onClick={() => handlePageClick(page)}
      className={currentPage === page ? 'active' : ''}
      style={{
        backgroundColor: currentPage === page ? 'red' : '',
        color: 'white',
      }}
    >
      {page}
    </button>
  ))}
          <button onClick={handleNext} disabled={currentPage === totalPages}>
            Next
          </button>
          <button onClick={handleLastPage} disabled={currentPage === totalPages}>
            Last
          </button>
        </div>



{/* direct page */}
<div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
                    <input
                        type="number"
                        value={inputPageNumber}
                        onChange={(e) => setInputPageNumber(e.target.value)}
                        placeholder="Page No"
                        style={{
                            padding: '5px',
                            fontSize: '14px',
                            // border: '1px solid #ccc',
                            border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,

                            borderRadius: '4px 0 0 4px',
                            outline: 'none',
                            width: '90px'
                        }}
                        onFocus={() => setInputFocus(true)}
                        onBlur={() => setInputFocus(false)}
                    />
                    <button
                        onClick={handleGoToPage}
                        style={{
                            padding: '6.5px 10px',
                            fontSize: '14px',
                            border: '1px solid #ccc',
                            borderLeft: 'none',
                            borderRadius: '0 4px 4px 0',
                            // backgroundColor: '#007bff',
                            
                        backgroundColor: `${buttonFocus ? 'green' : '#007bff'}`,
                        color: 'white',
                    cursor: 'pointer',
                    outline: 'none',
                        }}

                   
                    >
                    Go
                </button>
            </div>

      </div>
    </IncludeSideBar>
  );
}

export default ReportedUsers;
