import React, { useState, useEffect } from 'react';
// Assuming getRoseTransaction is the function that fetches the transaction data
import IncludeSideBar from '../../../Components/Sidebar/IncludeSideBar'

function FeedRoseTransation() {

  return (
    <IncludeSideBar> 
    <div>
      <h2 style={{textAlign:'center'}}>Feed Rose Transactions</h2>
     
    </div>
    </IncludeSideBar>
  );
}

export default FeedRoseTransation;
