

import React, { useEffect, useState } from 'react';
import IncludeSideBar from '../../../Components/Sidebar/IncludeSideBar';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

function AdminTransation() {
  const getVideoGift = require('../../../apis/diamond_transaction');
  const [transactions, setTransactions] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isFocused, setIsFocused] = useState(false);
  const [inputPageNumber, setInputPageNumber] = useState('');
  const [inputFocus, setInputFocus] = useState(false);
  const [buttonFocus, setButtonFocus] = useState(false);
  const [pageWindow, setPageWindow] = useState({ start: 1, end: 20 });
  // New state for date range filter
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null); // State to track selected row

  const fetchTransactions = async (page, searchTerm = '', startDate = null, endDate = null) => {
    setIsLoading(true);
    try {
      const filters = { page, searchTerm };

      if (startDate) {
        filters.startDate = startDate.toISOString();
      }

      if (endDate) {
        filters.endDate = endDate.toISOString();
      }
      const response = await getVideoGift.getAdminTransation(filters);
      console.log(response,'response admin'); // Check the structure of the response

      // Assuming response.data.transactions contains the list of transactions
      const transactionsData = response.data.transactions || [];
      setTransactions(transactionsData);
      setFilteredUsers(transactionsData); // Set filteredUsers to transactions initially
      setTotalPages(response?.data?.pagination?.totalPages);
      console.log(response?.pagination, 'totalPagestotalPagestotalPagestotalPages')

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching Diamond Transactions:", error);
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchTransactions(currentPage, searchTerm);
  // }, [currentPage, searchTerm]);
  useEffect(() => {
    fetchTransactions(currentPage, searchTerm, startDate, endDate);
  }, [currentPage, searchTerm, startDate, endDate]);
  
  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    return date.toLocaleString(); // Adjust toLocaleString parameters for custom formatting
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    const filtered = transactions.filter(
      (user) =>
        (user.sender?.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.sender?.email?.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (user.receiver?.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.receiver?.email?.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    setFilteredUsers(filtered);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);

      // Check if the new page is outside the current page window, update the window
      if (newPage > pageWindow.end) {
        setPageWindow({
          start: pageWindow.start + 20,
          end: Math.min(pageWindow.end + 20, totalPages),
        });
      }
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageWindow({
        start: Math.floor((currentPage - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((currentPage - 1) / 20) * 20 + 20, totalPages),
      });
    }
  };

  const handleNextPageWindow = () => {
    if (pageWindow.end < totalPages) {
      setPageWindow({
        start: pageWindow.start + 19,
        end: Math.min(pageWindow.end + 19, totalPages),
      });
    }
  };

  const handlePreviousPageWindow = () => {
    if (pageWindow.start > 1) {
      setPageWindow({
        start: pageWindow.start - 20,
        end: pageWindow.end - 20,
      });
    }
  };

  const handlePageClick = (page) => {
    handlePageChange(page);
    if (page === pageWindow.end && pageWindow.end < totalPages) {
      handleNextPageWindow();
    } else if (page === pageWindow.start && pageWindow.start > 1) {
      handlePreviousPageWindow();
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    setPageWindow({
      start: Math.floor((totalPages - 1) / 20) * 20 + 1,
      end: totalPages,
    });
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    setPageWindow({
      start: 1,
      end: Math.min(20, totalPages),
    });
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(inputPageNumber, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setPageWindow({
        start: Math.floor((pageNumber - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((pageNumber - 1) / 20) * 20 + 20, totalPages),
      });
      setInputPageNumber('');
    } else {
      alert(`Please enter a valid page number between 1 and ${totalPages}`);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const handleFilterApply = () => {
    // Fetch transactions with the applied date range
    setIsLoading(true);

    fetchTransactions(currentPage, searchTerm, startDate, endDate);
  };
  const loaderStyle = {
    border: '8px solid #f3f3f3', // Light grey
    borderTop: '8px solid #3498db', // Blue
    borderRadius: '50%',
    width: '30px', // Size of the loader
    height: '30px', // Size of the loader
    animation: 'spin 1s linear infinite',
    alignItems:'center',
    margin: '0 auto', // Center the loader

    

  };
  const handleRowClick = (transactionId) => {
    setSelectedRow(transactionId);
  };
  return (
    <IncludeSideBar>
      <div style={{ overflowX: 'auto' }}>
        <h2 style={{textAlign:'center',marginTop:'10px'}}>Admin Send Diamond To User Transaction</h2>
        <input
          type="text"
          placeholder="Search by username or email"
          value={searchTerm}
          onChange={handleSearch}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={{
            borderWidth: isFocused ? '10px' : '1px',
            borderStyle: 'solid',
            borderColor: isFocused ? 'green' : 'red',
          }}
        />
        <div style={{ marginBottom: '20px', display: 'flex',  alignItems: 'center' }}>
          <label style={{ marginRight: '10px' }}>Filter  </label>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
              maxDate={new Date()} // Ensure start date is not in the future
              dateFormat="dd:MM:yyyy" // Format date as dd:mm:yyyy

              style={{
                padding: '5px',
                borderRadius: '4px',
                border: '1px solid #ccc',
                fontSize: '14px',
              }}
            />
            <span style={{ margin: '0 10px' }}>to</span>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              placeholderText="End Date"
              minDate={startDate} // Ensure end date is not before start date
              maxDate={new Date()} // Disable selecting future dates
              dateFormat="dd:MM:yyyy" // Format date as dd:mm:yyyy

              style={{
                padding: '5px',
                borderRadius: '4px',
                border: '1px solid #ccc',
                fontSize: '14px',
              }}
            />
            <button
              style={{
                padding: '6px 12px',
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            onClick={handleFilterApply}
            >
              Apply Filter
            </button>
          </div>
        </div>
        {isLoading && <div style={loaderStyle}></div>} {/* Circular Loader */}

        <table>
          <thead>
            <tr>
              <th> ID</th>
              <th>Transaction ID</th>
              <th>Transaction Date & Time</th>
              <th>Transaction Diamonds</th>
             
              <th>Receiver Username</th>
              <th>Receiver Email</th>
              <th>Receiver Type</th>


              <th>Sender Username</th>
              <th>Sender Email</th>
              <th>Sender Type</th>

            </tr>
          </thead>
          <tbody>
            {Array.isArray(filteredUsers) && filteredUsers.length > 0 ? (
              filteredUsers.map((transaction) => (
                <tr key={transaction.id}
                onClick={() => handleRowClick(transaction.id)}

                style={{
                  backgroundColor: selectedRow === transaction.id ? '#007bff' : 'transparent',
                  color: selectedRow === transaction.id ? 'white' : 'black',
                  cursor: 'pointer', // Change cursor to pointer for better UX
                }}
                >
                  <td
                 
                  >{transaction.id}</td>
                  <td>{transaction.transaction_id}</td>
                  <td>{formatCreatedAt(transaction.createdAt)}</td>
                  <td>{transaction.diamond_value}</td>
              
                  <td>{transaction?.receiver?.username}</td>
                  <td>{transaction?.receiver?.email}</td>

                  <td>{transaction?.receiver_type}</td>

                  <td>{transaction?.sender?.username}</td>
                  <td>{transaction?.sender?.email}</td>
                  <td>{transaction?.sender_type}</td>


                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">No transactions found</td>
              </tr>
            )}
          </tbody>

        </table>

        <div className="pagination">
          <button onClick={handleFirstPage} disabled={currentPage === 1}>
            First
          </button>
          <button onClick={handlePrevious} disabled={currentPage === 1}>
            Previous
          </button>
          {Array.from(
            { length: Math.min(pageWindow.end - pageWindow.start + 1, totalPages) },
            (_, index) => pageWindow.start + index
          ).map((page) => (
            <button
              key={page}
              onClick={() => handlePageClick(page)}
              className={currentPage === page ? 'active' : ''}
              style={{
                backgroundColor: currentPage === page ? 'red' : '',
                color: 'white',
              }}
            >
              {page}
            </button>
          ))}
          <button onClick={handleNext} disabled={currentPage === totalPages}>
            Next
          </button>
          <button onClick={handleLastPage} disabled={currentPage === totalPages}>
            Last
          </button>
        </div>

        {/* Direct page input */}
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
          <input
            type="number"
            value={inputPageNumber}
            onChange={(e) => setInputPageNumber(e.target.value)}
            placeholder="Page No"
            style={{
              padding: '5px',
              fontSize: '14px',
              border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,
              borderRadius: '4px 0 0 4px',
              outline: 'none',
              width: '90px',
            }}
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
          />
          <button
            onClick={handleGoToPage}
            style={{
              padding: '6px 12px',
              fontSize: '14px',
              border: `2px solid ${buttonFocus ? 'green' : '#ccc'}`,
              backgroundColor: '#007bff',
              color: 'white',
              borderRadius: '0 4px 4px 0',
              cursor: 'pointer',
            }}
            onFocus={() => setButtonFocus(true)}
            onBlur={() => setButtonFocus(false)}
          >
            Go
          </button>
        </div>
      </div>
    </IncludeSideBar>
  );
}

export default AdminTransation;
