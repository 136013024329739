import React, { useEffect, useState, useCallback } from "react";
import IncludeSideBar from "../../Components/Sidebar/IncludeSideBar";
import useAuth from "../../../src/useAuth";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function AllFeed() {
  const allfeedApis = require("../../../src/apis/feed");
  const allUserApis = require("../../../src/apis/users");

  const { user } = useAuth();
  const hasViewCommentAccess = user?.permissions?.Feed?.['View Comment']?.selected || user?.role === 'superadmin';
  const hassubfetureEditComment = user?.permissions?.Feed?.['View Comment']?.subfeatures?.['Edit Comment'] || user?.role === 'superadmin';
  const hassubfetureDeleteComment = user?.permissions?.Feed?.['View Comment']?.subfeatures?.['Delete Comment'] || user?.role === 'superadmin';
  const hasMuteComment = user?.permissions?.Feed?.['Mute Comment']?.selected || user?.role === 'superadmin';
console.log(hasMuteComment,'mute coomment')
  // console.log(hassubfetureEditComment,'hassubfetureEditComment')
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Add a loading state
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [AllFeed, setAllfeed] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [pageRange, setPageRange] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]); // Visible page range
  const [inputPageNumber, setInputPageNumber] = useState(''); // State to hold the input page number
  const [inputFocus, setInputFocus] = useState(false);
  const [buttonFocus, setButtonFocus] = useState(false);
  const [expandedPosts, setExpandedPosts] = useState({});
  const [openfeedmodel, setopenfeedmodel] = useState(false)
  const [feedinfo, setfeedinfo] = useState([])
  const [selectedRow, setSelectedRow] = useState(null); // State to track selected row
  const [isBlockModelOpen, SetisBlockModelOpen] = useState(false)
  const[searchresult,setsearcresult]=useState([])

  const FeatchAllfeedInfo = async (page, searchTerm = "") => {
    try {
      setLoading(true);

      const response = await allfeedApis.GetAllFeed(page, searchTerm);
      setAllfeed(response?.data?.posts || []);
      console.log(response?.data?.posts, 'response?.data?.posts')
      setTotalPages(response?.data?.pagination?.totalPages || 1);
      setFilteredUsers(response?.data?.posts || [])

    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FeatchAllfeedInfo(currentPage, searchTerm);
  }, [currentPage, searchTerm]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    const filtered = AllFeed.filter(
      (user) =>
        user?.user?.username.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredUsers(filtered);
  };

  // Dynamically update the initial page range based on totalPages
  useEffect(() => {
    const initialRange = [];
    const rangeSize = 10;
    const totalVisiblePages = Math.min(rangeSize, totalPages);

    for (let i = 1; i <= totalVisiblePages; i++) {
      initialRange.push(i);
    }
    setPageRange(initialRange);
  }, [totalPages]);

  const updatePageRange = (startPage) => {
    const rangeSize = 10; // Number of pages visible at a time
    const newRange = [];

    for (
      let i = startPage;
      i < startPage + rangeSize && i <= totalPages;
      i++
    ) {
      newRange.push(i);
    }

    setPageRange(newRange);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);

    // If the clicked page is the last in the current range, load the next range
    if (page === pageRange[pageRange.length - 1] && page < totalPages) {
      updatePageRange(page);
    }

    // If the clicked page is the first in the current range and not the first page
    if (page === pageRange[0] && page > 1) {
      updatePageRange(page - 9);
    }
  };

  const handleFirst = () => {
    setCurrentPage(1);
    updatePageRange(1);
  };

  const handleLast = () => {
    setCurrentPage(totalPages);
    const lastRangeStart = Math.max(
      1,
      Math.floor((totalPages - 1) / 10) * 10 + 1
    );
    updatePageRange(lastRangeStart);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      // Check if we are at the start of the current visible range
      if (currentPage === pageRange[0]) {
        // If we are at the first page of the range, move to the previous set of pages
        const previousRangeStart = Math.max(pageRange[0] - 10, 1); // Ensure start page is >= 1
        updatePageRange(previousRangeStart);
      } else {
        setCurrentPage(currentPage - 1);
      }
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      // If we are at the end of the current visible range, load the next range
      if (currentPage === pageRange[pageRange.length - 1] && currentPage < totalPages) {
        updatePageRange(pageRange[pageRange.length - 1]);
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const handleGoToPage = () => {
    const page = parseInt(inputPageNumber, 10);

    // Ensure the page number is a valid number and within the valid range
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);

      // Calculate the range for the current page
      const startPage = Math.floor((page - 1) / 10) * 10 + 1;
      updatePageRange(startPage);
    } else {
      alert('Please enter a valid page number!');
    }
  };


  const handleToggle = (postId) => {
    setExpandedPosts((prev) => ({
      ...prev,
      [postId]: !prev[postId], // Toggle the state for the specific post ID
    }));
  };

  const showfeed = (post) => {
    setopenfeedmodel(true)
    setfeedinfo(post)
  }
  const close = () => {
    setopenfeedmodel(false)
    setfeedinfo(null)

  }
  const [ShowAllCommentModel, setShowAllCommentModel] = useState(false)
  const [SelectCommentpostid, setSelectCommentpostid] = useState(null)
  const [CommentData, setCommentData] = useState([])
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const OpenShowCommentModel = (postid) => {
    setSelectCommentpostid(postid)
    GetAllfeedCommentById(postid)
    setShowAllCommentModel(true)
  }
  const closeViewCommentModal = () => {
    setShowAllCommentModel(false)
    setIsEditModalOpen(false)
  };

  const GetAllfeedCommentById = async (postId) => {
    try {
      setLoading(true);

      const response = await allfeedApis.GetAllfeedCommentById(postId);
      console.log(response, 'responseresponseresponse,feed')
      setCommentData(response?.data)
    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      setLoading(false);
    }
  };
  const [openEditmodel, setEditmodel] = useState(false)

  const HandleComment = async (payload) => {
    try {
      console.log(payload, 'payloadpayload')
      const response = await allfeedApis.EditFeedcomment(payload);
      if (response.success) {

        alert(response.message)
        setEditmodel(false)

        GetAllfeedCommentById(SelectCommentpostid)

      }
      // After data is fetched, set loading to false
      setLoading(false)

    } catch (e) {
      console.log(e)
    }
  }

  const HandleFeedCommenDelete = async (id) => {
    try {
      const response = await allfeedApis.DeleteFeedComment(id);
      if (response.success) {

        alert(response.message)

        GetAllfeedCommentById(SelectCommentpostid)

      }
      // After data is fetched, set loading to false
      setLoading(false)

    } catch (e) {
      console.log(e)
    }
  }
  const [clickedButton, setClickedButton] = useState(null);

  const handleButtonClick = (postid, buttonType) => {
    setClickedButton({ postid, buttonType }); // Set the clicked button
  };

  const handleNoAccess = () => {
    alert('You are not able to access this feature.');
  };
  const handleRowClick = (transactionId) => {
    setSelectedRow(transactionId);
  };


  const OpenBlockModel = () => {
    SetisBlockModelOpen(true)
  }

  const CloseModel = () => {
    SetisBlockModelOpen(false)
    setsearcresult(null)


  }
  const FeatchUsers = async (query) => {
    try {
      const response = await allfeedApis.FetchUserInfo(query);
      console.log(response, 'response')
      setsearcresult(response?.users)
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  const handleCommentMuteUser=async(formData)=>{
    // console.log(userId, totalSeconds, reason, 'userId,totalSeconds,reason')
    const userId = formData.get("userId");

  // const mutefor='feed'
  
    try {
      // const response = await allUserApis.MuteUserComment(userId, mutefor,totalSeconds, reason);
      const response = await allUserApis.MuteUserComment(userId,formData);

      if(response.success){
        alert('Comment has been muted successfully')
        SetisBlockModelOpen(false)
        setsearcresult(null)
      }else{
        alert(response.message)
        SetisBlockModelOpen(false)

        setsearcresult(null)

      }
      // console.log('User has been muted successfully:', response);
      // Perform any necessary action after a successful update on the server
    } catch (error) {
      console.error('Error updating user status:', error);
    }
  }
  
  const blockuser = {
    background: 'red',
    float: 'right',
    margin: '4px',
    borderRadius: '20px'
  };
  return (
    <IncludeSideBar>
      <h1>All Feed Info</h1>
      <button  onClick={()=>{
        if(hasMuteComment){
          OpenBlockModel()
        }else{
          handleNoAccess()
        }
      }}
      style={{
        ...blockuser,
        backgroundColor:
        !hasMuteComment
          ? '#C5C6C7'
          : 'red', // Set red background when there is access
      cursor: !hasMuteComment ? 'not-allowed' : 'pointer', //
      }}
      >Mute Feed Comment Section</button>

      <div>
        <input
          type="text"
          placeholder="Search by username"
          value={searchTerm}
          onChange={handleSearch}
        />
        {loading && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
        {!loading && (
          <table>
            <thead>
              <tr>
                <th>Post ID</th>
                <th>Profile Pic</th>
                <th>Username</th>
                <th>Nickname</th>
                <th>Post Topic</th>
                <th>Description</th>
                <th>Comment</th>
                <th>Like</th>
                <th>Share</th>
                <th>Created At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.length > 0 ? (
                filteredUsers.map((post) => {
                  const description = post?.description || "N/A"; // Fallback to "N/A" if no description
                  const isExpanded = expandedPosts[post.id]; // Check if this post is expanded
                  return (
                    <tr key={post.id}
                      onClick={() => handleRowClick(post.id)}

                      style={{
                        backgroundColor: selectedRow === post.id ? '#91b7e6' : 'transparent',
                        color: selectedRow === post.id ? 'white' : 'black',
                        cursor: 'pointer', // Change cursor to pointer for better UX
                      }}
                    >
                      <td>{post?.id}</td>
                      <td>
                        {post?.user?.profile_pic ? (
                          <img
                            src={post?.user?.profile_pic}
                            alt="Profile"
                            onClick={() => showfeed(post)}

                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "20%",
                            }}
                          />
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>{post?.user?.username || "N/A"}</td>
                      <td>{post?.user?.nickname || "N/A"}</td>
                      <td>{post?.post_topic || "N/A"}</td>
                      <td>
                        {isExpanded ? (
                          <>
                            {description}
                            <button onClick={() => handleToggle(post.id)} style={{
                              marginLeft: '5px',
                              padding: '2px 5px', // Adjust padding
                              fontSize: '12px',   // Smaller font size
                              height: '25px',     // Adjust height
                              cursor: 'pointer',
                            }}>
                              Show Less
                            </button>
                          </>
                        ) : (
                          <>
                            {description.length > 20 ? description.slice(0, 20) + '...' : description}
                            {description.length > 20 && (
                              <button onClick={() => handleToggle(post.id)} style={{
                                marginLeft: '5px',
                                padding: '2px 5px', // Adjust padding
                                fontSize: '12px',   // Smaller font size
                                height: '25px',     // Adjust height
                                cursor: 'pointer',
                              }}>
                                Show More
                              </button>
                            )}
                          </>
                        )}

                      </td>
                      <td>{post?.comment || 0}</td>
                      <td>{post?.like || 0}</td>
                      <td>{post?.share || 0}</td>
                      <td>
                        {post.createdAt
                          ? new Date(post.createdAt).toLocaleDateString()
                          : "N/A"}
                      </td>
                      <td>
                        <button onClick={() => {
                          if (hasViewCommentAccess) {
                            OpenShowCommentModel(post.id)
                            handleButtonClick(post.id, 'viewComment');

                          } else {
                            handleNoAccess()
                          }
                        }}
                          style={{
                            backgroundColor:
                              clickedButton?.postid === post.id &&
                                clickedButton?.buttonType === 'viewComment'
                                ? 'red'
                                : !hasViewCommentAccess
                                  ? '#C5C6C7'
                                  : '',
                            cursor: !hasViewCommentAccess ? 'not-allowed' : 'pointer', // Changes cursor when disabled
                          }}
                        >View Comment</button>

                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="11">No posts found</td>
                </tr>
              )}

            </tbody>
          </table>
        )}
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <button onClick={handleFirst} disabled={currentPage === 1}>
              First
            </button>
            <button onClick={handlePrevious} disabled={currentPage === 1}>
              Previous
            </button>
            {pageRange.map((page) => (
              <button
                key={page}
                onClick={() => handlePageClick(page)}
                style={{
                  padding: "5px 10px",
                  backgroundColor: currentPage === page ? "red" : "#f8f9fa",
                  color: currentPage === page ? "white" : "black",
                  border: "1px solid #ddd",
                  cursor: "pointer",
                }}
              >
                {page}
              </button>
            ))}
            <button onClick={handleNext} disabled={currentPage === totalPages}>
              Next
            </button>
            <button onClick={handleLast} disabled={currentPage === totalPages}>
              Last
            </button>
          </div>
          {/* direct page */}
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
            <input
              type="number"
              value={inputPageNumber}
              onChange={(e) => setInputPageNumber(e.target.value)}
              placeholder="Page No"
              style={{
                padding: '5px',
                fontSize: '14px',
                // border: '1px solid #ccc',
                border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,

                borderRadius: '4px 0 0 4px',
                outline: 'none',
                width: '90px'
              }}
              onFocus={() => setInputFocus(true)}
              onBlur={() => setInputFocus(false)}
            />
            <button
              onClick={handleGoToPage}
              style={{
                padding: '6.5px 10px',
                fontSize: '14px',
                border: '1px solid #ccc',
                borderLeft: 'none',
                borderRadius: '0 4px 4px 0',
                // backgroundColor: '#007bff',

                backgroundColor: `${buttonFocus ? 'green' : '#007bff'}`,
                color: 'white',
                cursor: 'pointer',
                outline: 'none',
              }}


            >
              Go
            </button>
          </div>

        </div>
        {
          openfeedmodel && (
            <OpenFeedinfo
              close={close}
              feeddata={feedinfo}

            />
          )

        }
        {
          ShowAllCommentModel && (

            <CommentsModal
              groupedComments={CommentData}
              onClose={closeViewCommentModal}
              HandleFeedComment={HandleComment}
              openEditmodel={openEditmodel}
              setEditmodel={setEditmodel}
              HandleFeedCommenDelete={HandleFeedCommenDelete}
              handleNoAccess={handleNoAccess}
              hassubfetureEditComment={hassubfetureEditComment}
              hassubfetureDeleteComment={hassubfetureDeleteComment}
            />
          )

        }

        {
          isBlockModelOpen && (
            <BlockCommentModel
              CloseModel={CloseModel}
              FeatchUsers={FeatchUsers}
              searchresult={searchresult}
              onMute={handleCommentMuteUser}
            />
          )
        }
      </div>
    </IncludeSideBar>
  );
}

export default AllFeed;

const OpenFeedinfo = ({ close, feeddata }) => {
  const modalStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: '1000',
  };

  const modalContentStyles = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    width: '600px',
    height: '80vh',
    maxHeight: '80vh',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    overflow: 'auto',
    position: 'relative',
  };

  const gridContainerStyles = {
    display: 'grid',
    gap: '10px',
    width: '100%',
    height: '100%',
  };

  const getGridTemplate = (count) => {
    if (count === 1) return '1fr';
    if (count === 2) return '1fr 1fr';
    if (count === 3) return '1fr / 1fr 1fr';
    return 'repeat(auto-fill, minmax(100px, 1fr))';
  };

  const imageContainerStyles = {
    position: 'relative',
  };

  const imageStyles = {
    width: '100%',
    height: '100%',
    // objectFit: 'cover',
    borderRadius: '5px',
  };

  const imageNumberStyles = {
    position: 'absolute',
    top: '5px',
    left: '5px',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    color: 'white',
    padding: '2px 8px',
    fontSize: '12px',
    borderRadius: '3px',
  };

  const closeButtonStyles = {
    backgroundColor: '#6c757d',
    color: 'white',
    padding: '10px 20px',
    margin: '10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    position: 'absolute',
    top: '10px',
    right: '10px',
  };

  const images = feeddata?.NestedPicturePosts || [];

  return (
    <div style={modalStyles}>
      <div style={modalContentStyles}>
        <h1>IMAGE</h1>
        <div
          style={{
            ...gridContainerStyles,
            gridTemplateColumns: getGridTemplate(images.length),
          }}
        >
          {images.map((image, index) => (
            <div key={image.id || index} style={imageContainerStyles}>
              <img
                src={`https://dpcst9y3un003.cloudfront.net/${image?.img_url}`}

                alt={`NestedImage ${index + 1}`}
                style={imageStyles}

              />
              <span style={imageNumberStyles}>#{index + 1}</span>
            </div>
          ))}
        </div>
        <button style={closeButtonStyles} onClick={close}>
          Close
        </button>
      </div>
    </div>
  );
};


// **********************comment*******************




const CommentsModal = ({ groupedComments, onClose, HandleFeedComment, openEditmodel, setEditmodel, HandleFeedCommenDelete, handleNoAccess, hassubfetureEditComment, hassubfetureDeleteComment }) => {
  const [editedText, setEditedText] = useState('');
  const [commentId, setCommentId] = useState(null);

  const handleedit = (id, data) => {
    setCommentId(id)
    setEditedText(data)
  }
  const HandleEditFeedComment = () => {
    const payload = {
      comment: editedText,
      commentId: commentId
    }
    HandleFeedComment(payload)
  }
  const HandleCommentDelete = (id) => {
    // console.log(id,'iddddddddddd')
    HandleFeedCommenDelete(id)
  }
  const HandleClean = () => {
    setEditedText('')
  }
  const modalStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000,
  };

  const modalContentStyles = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    width: '80%',
    maxWidth: '800px',
    maxHeight: '90vh',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    position: 'relative',
  };

  const closeButtonStyles = {
    backgroundColor: '#6c757d',
    color: 'white',

  };
  const tableStyles = {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
  };
  const buttonStyles = {
    backgroundColor: '#007BFF',
    color: 'white',
    padding: '5px 10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
    margin: '2px',
  };

  return (
    <div style={modalStyles}>
      <div style={modalContentStyles}>
        <h1>
          Feed Comment
        </h1>
        {/* <div style={scrollableContentStyles}> */}

        <CommentsTableContent groupedComments={groupedComments} openEditmodel={openEditmodel} handleedit={handleedit} setEditmodel={setEditmodel} HandleCommentDelete={HandleCommentDelete} handleNoAccess={handleNoAccess} hassubfetureEditComment={hassubfetureEditComment} hassubfetureDeleteComment={hassubfetureDeleteComment} />
        {/* </div> */}
        {openEditmodel && (
          <div style={modalStyles}>
            <div style={modalContentStyles}>
              <h2>Edit Comment (ID: {commentId})</h2>

              <textarea
                value={editedText}
                onChange={(e) => setEditedText(e.target.value)}
                rows={4}
                style={{
                  width: '100%',
                  padding: '10px',
                  marginBottom: '20px',
                  borderRadius: '5px',
                  resize: 'vertical',
                }}
              />

              <div>
                <button style={buttonStyles} onClick={HandleEditFeedComment}>
                  Save
                </button>
                <button style={{ ...buttonStyles, background: 'red' }} onClick={HandleClean} >
                  Clean
                </button>
                <button
                  style={{ ...buttonStyles, background: 'gray' }}
                  onClick={() => setEditmodel(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        <button style={closeButtonStyles} onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};








// ********************new*******



const CommentsTableContent = ({ groupedComments, parentId = 0, level = 0, setEditmodel, openEditmodel, handleedit, HandleCommentDelete, handleNoAccess, hassubfetureEditComment, hassubfetureDeleteComment }) => {
  const [visibleReplies, setVisibleReplies] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [commentsPerPage] = useState(5);
  const [pageRange, setPageRange] = useState([1, 2, 3, 4]);

  const toggleReplies = (commentId) => {
    setVisibleReplies((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  const findReplies = useCallback(
    (commentId) => groupedComments[commentId] || [],
    [groupedComments]
  );

  const handleDateChange = (date, type) => {
    if (type === 'start') setStartDate(date);
    else if (type === 'end') setEndDate(date);
  };

  const handleClean = () => {
    setStartDate(null);
    setEndDate(null);
    setSearchQuery('');
  };

  const filterCommentsRecursively = (commentsList) => {
    return commentsList
      .map((comment) => {
        const username = comment.user?.username?.toLowerCase() || 'anonymous';
        const commentText = comment.comment_data?.toLowerCase() || '';
        const commentDate = comment.createdAt;

        const matches =
          username.includes(searchQuery) || commentText.includes(searchQuery);
        // const isWithinDateRange =
        //   (!startDate || new Date(commentDate) >= new Date(startDate)) &&
        //   (!endDate || new Date(commentDate) <= new Date(endDate));
        const isWithinDateRange =
          (!startDate || new Date(commentDate).setHours(0, 0, 0, 0) >= new Date(startDate).setHours(0, 0, 0, 0)) &&
          (!endDate || new Date(commentDate).setHours(0, 0, 0, 0) <= new Date(endDate).setHours(0, 0, 0, 0));

        const filteredReplies = findReplies(comment.id)
          .map((reply) => {
            const replyMatches =
              reply.user?.username?.toLowerCase().includes(searchQuery) ||
              reply.comment_data?.toLowerCase().includes(searchQuery);

            const nestedFilteredReplies = filterCommentsRecursively(findReplies(reply.id));

            if (replyMatches || nestedFilteredReplies.length > 0) {
              return { ...reply, filteredReplies: nestedFilteredReplies };
            }
            return null;
          })
          .filter(Boolean);

        if ((matches || filteredReplies.length > 0) && isWithinDateRange) {
          return { ...comment, filteredReplies };
        }
        return null;
      })
      .filter(Boolean);
  };

  const filteredComments = filterCommentsRecursively(groupedComments[parentId] || []);
  const totalPages = Math.ceil(filteredComments.length / commentsPerPage);
  const currentComments = filteredComments.slice(
    (currentPage - 1) * commentsPerPage,
    currentPage * commentsPerPage
  );

  useEffect(() => {
    const initialRange = [];
    const rangeSize = 4;
    const totalVisiblePages = Math.min(rangeSize, totalPages);

    for (let i = 1; i <= totalVisiblePages; i++) {
      initialRange.push(i);
    }
    setPageRange(initialRange);
  }, [totalPages]);

  const updatePageRange = (startPage) => {
    const rangeSize = 4;
    const newRange = [];

    for (let i = startPage; i < startPage + rangeSize && i <= totalPages; i++) {
      newRange.push(i);
    }

    setPageRange(newRange);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
    if (page === pageRange[pageRange.length - 1] && page < totalPages) {
      updatePageRange(page);
    }
    if (page === pageRange[0] && page > 1) {
      updatePageRange(page - 3);
    }
  };

  const handleFirst = () => {
    setCurrentPage(1);
    updatePageRange(1);
  };

  const handleLast = () => {
    setCurrentPage(totalPages);
    const lastRangeStart = Math.max(1, Math.floor((totalPages - 1) / 4) * 4 + 1);
    updatePageRange(lastRangeStart);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      if (currentPage === pageRange[0]) {
        const previousRangeStart = Math.max(pageRange[0] - 4, 1);
        updatePageRange(previousRangeStart);
      } else {
        setCurrentPage(currentPage - 1);
      }
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      if (currentPage === pageRange[pageRange.length - 1]) {
        updatePageRange(pageRange[pageRange.length - 1]);
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const HandleCommentEdit = (id, data) => {
    setEditmodel(true)
    handleedit(id, data)
  }

  const HadleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      HandleCommentDelete(id);
    }
  }
  const containerStyles = {
    marginBottom: '20px',
  };

  const searchInputStyles = {
    padding: '10px',
    marginBottom: '20px',
    width: '100%',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ddd',
  };

  const tableStyles = {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
  };

  const thStyles = {
    borderBottom: '1px solid #ddd',
    padding: '10px',
    fontWeight: 'bold',
    backgroundColor: '#f7f7f7',
    textAlign: 'left',
  };

  const tdStyles = {
    padding: '10px',
    borderBottom: '1px solid #ddd',
    textAlign: 'left',
  };

  const buttonStyles = {
    backgroundColor: '#007BFF',
    color: 'white',
    padding: '5px 10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
    margin: '2px',
  };

  const replyRowStyles = {
    backgroundColor: '#f9f9f9',
    paddingLeft: `${level * 20}px`,
  };
  const scrollableContentStyles = {
    maxHeight: 'calc(32vh - 7px)',
    overflowY: 'auto',
  };
  const buttonStyless = {
    backgroundColor: '#007BFF',
    color: 'white',
    padding: '5px 10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
    margin: '2px',
  };
  const [selectedRow, setSelectedRow] = useState(null); // State to track selected row

  const handleRowClick = (transactionId) => {
    setSelectedRow(transactionId);
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      {level === 0 && (
        <div style={{ marginBottom: '20px' }}>
          {/* Search and Date Filters */}
          <input
            type="text"
            placeholder="Search by username or comment..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
            style={{
              padding: '10px',
              marginBottom: '10px',
              width: '100%',
              fontSize: '16px',
              borderRadius: '5px',
              border: '1px solid #ddd',
            }}
          />
          <div>
            <DatePicker
              selected={startDate}
              onChange={(date) => handleDateChange(date, 'start')}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
              maxDate={new Date()}
              dateFormat="dd:MM:yyyy"
            />
            <span style={{ margin: '0 10px' }}>to</span>
            <DatePicker
              selected={endDate}
              onChange={(date) => handleDateChange(date, 'end')}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              placeholderText="End Date"
              minDate={startDate}
              maxDate={new Date()}
              dateFormat="dd:MM:yyyy"
            />
            <button onClick={handleClean}>Clean</button>
          </div>
        </div>
      )}

      {/* Comments Table */}
      <div style={scrollableContentStyles}>

        <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
          <thead>
            <tr>
              <th>ID</th>
              <th>User</th>
              <th>Comment</th>
              <th>Created Date</th>
              <th>Replies</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentComments.map((comment) => (
              <React.Fragment key={comment.id}>
                <tr
                  onClick={() => handleRowClick(comment.id)}

                  style={{
                    backgroundColor: selectedRow === comment.id ? '#91b7e6' : 'transparent',
                    color: selectedRow === comment.id ? 'white' : 'black',
                    cursor: 'pointer', // Change cursor to pointer for better UX
                  }}
                >
                  <td>{comment.id}</td>
                  <td>{comment.user?.username || 'Anonymous'}</td>
                  <td>{comment.comment_data}</td>
                  <td>{new Date(comment.createdAt).toLocaleString()}</td>
                  <td>
                    {comment.filteredReplies.length > 0 && (
                      <button onClick={() => toggleReplies(comment.id)} style={buttonStyless}>
                        {visibleReplies[comment.id] ? 'Hide Replies' : 'Show Replies'}
                      </button>
                    )}
                  </td>
                  <td>
                    <button onClick={() => {
                      if (hassubfetureEditComment) {
                        HandleCommentEdit(comment.id, comment.comment_data)

                      } else {
                        handleNoAccess()
                      }
                    }} style={{
                      ...buttonStyless,
                      backgroundColor: hassubfetureEditComment ? '' : '#C5C6C7',


                      cursor: !hassubfetureEditComment ? 'not-allowed' : 'pointer', // Changes cursor when disabled
                    }}>


                      Edit</button>




                    <button onClick={() => {
                      if (hassubfetureDeleteComment) {
                        HadleDelete(comment.id)
                      } else {
                        handleNoAccess()
                      }
                    }
                    }


                      style={{
                        ...buttonStyless,
                        backgroundColor: hassubfetureDeleteComment ? 'red' : '#C5C6C7',


                        cursor: !hassubfetureDeleteComment ? 'not-allowed' : 'pointer', // Changes cursor when disabled
                      }}
                    >Delete</button>

                  </td>
                </tr>
                {visibleReplies[comment.id] && comment.filteredReplies.length > 0 && (
                  <tr>
                    <td colSpan={6}>
                      <CommentsTableContent
                        groupedComments={groupedComments}
                        parentId={comment.id}
                        level={level + 1}
                        setEditmodel={setEditmodel}
                        handleedit={handleedit}
                        HandleCommentDelete={HandleCommentDelete}
                        hassubfetureEditComment={hassubfetureEditComment}
                        hassubfetureDeleteComment={hassubfetureDeleteComment}
                        handleNoAccess={handleNoAccess}

                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      {/* Pagination Controls */}
      {level === 0 && (
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <button onClick={handleFirst} disabled={currentPage === 1}>
            First
          </button>
          <button onClick={handlePrevious} disabled={currentPage === 1}>
            Previous
          </button>
          {pageRange.map((page) => (
            <button
              key={page}
              onClick={() => handlePageClick(page)}
              style={{
                padding: '5px 10px',
                backgroundColor: currentPage === page ? '#007bff' : '#f8f9fa',
                color: currentPage === page ? 'white' : 'black',
                border: '1px solid #ddd',
                cursor: 'pointer',
              }}
            >
              {page}
            </button>
          ))}
          <button onClick={handleNext} disabled={currentPage === totalPages}>
            Next
          </button>
          <button onClick={handleLast} disabled={currentPage === totalPages}>
            Last
          </button>
        </div>
      )}

    </div>
  );
};


// ***********************Block Model******************
const CustomTimePicker = ({ label, value, onChange, options }) => (
  <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
    <label style={{ fontWeight: '600', marginRight: '10px', flex: '1' }}>{label}:</label>
    <select
      value={value}
      onChange={(e) => onChange(Number(e.target.value))}
      style={{
        flex: '2',
        padding: '8px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        textAlign: 'center',
        boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
      }}
    >
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  </div>
);


const BlockCommentModel = ({ CloseModel, FeatchUsers, searchresult,onMute }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUsers, setSelectedUsers] = useState({});
  const [reason, setReason] = useState("");
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [days, setDays] = useState(0);
  const [openModel, setOpenModel] = useState(false);
  const [link, setLink] = useState("");
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);

  const dayOptions = Array.from({ length: 31 }, (_, i) => i); // 0 to 30 days
  const hourOptions = Array.from({ length: 24 }, (_, i) => i); // 0 to 23 hours
  const minuteOptions = Array.from({ length: 60 }, (_, i) => i); // 0 to 59 minutes
  const secondOptions = Array.from({ length: 60 }, (_, i) => i); // 0 to 59 seconds

  const handleSearch = () => {
    FeatchUsers(searchQuery);
    setSearchQuery("");
    setOpenModel(false)
    setReason('')
  };

  const handleSelect = (user) => {
    setSelectedUsers(user);
    setOpenModel(true);
  };

  const closeinternalModel=()=>{
    setOpenModel(false)
    CloseModel()
  }

//     const handleMute= () => {
//     const totalSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;
// console.log(totalSeconds,reason,'reasonreasonreasonreason')
//        if (window.confirm(`Are you sure you want to Mute the Comments for ${days} ${hours} hours, ${minutes} minutes, and ${seconds} seconds?`)) {
//         onMute(selectedUsers.id,totalSeconds, reason);
//     }
   
   
//   };
const handleMute = () => {
  const totalSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;
 const mutefor='feed'
  if (
    window.confirm(
      `Are you sure you want to Mute the Comments for ${days} days, ${hours} hours, ${minutes} minutes, and ${seconds} seconds?`
    )
  ) {
    const formData = new FormData();
    formData.append("userId", selectedUsers.id);
    formData.append("totalSeconds", totalSeconds);
    formData.append("reason", reason);
    formData.append("mutefor",mutefor)

    if (link) formData.append("link", link);
    if (image) formData.append("image", image);
    if (video) formData.append("video", video);

    onMute(formData); // Pass formData to the onMute function
  }
};

  const modalStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 1000,
  };

  const modalContentStyles = {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "10px",
    width: "90%",
    maxWidth: "700px",
    maxHeight: "80vh",
    overflow: "hidden",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    position: "relative",
  };

  const scrollContentStyles = {
    maxHeight: "40vh",
    overflowY: "auto",
    padding: "10px",
  };

  const closeButtonStyles = {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "#6c757d",
    color: "#fff",
    padding: "8px 12px",
    borderRadius: "5px",
    cursor: "pointer",
    border: "none",
  };

  const inputStyles = {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ddd",
  };

  const buttonStyles = {
    backgroundColor: "#007BFF",
    color: "#fff",
    padding: "8px 16px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "14px",
    margin: "5px 0",
  };

  const textareaStyles = {
    width: "100%",
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #ccc",
    resize: "none",
    boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  };

  return (
    <div style={modalStyles}>
      <div style={modalContentStyles}>
        <button style={closeButtonStyles} onClick={()=>{
          closeinternalModel()
        }}>
          Close
        </button>
        <h1 style={{ textAlign: "center", marginBottom: "20px" }}>Mute Feed Comment</h1>
        <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
          <input
            type="text"
            placeholder="Search by username"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
            style={inputStyles}
          />
          <button style={buttonStyles} onClick={handleSearch}>
            Search
          </button>
        </div>

        {searchresult?.id  && (
          <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "20px" }}>
            <thead>
              <tr>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>ID</th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>Username</th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr key={searchresult.id}>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>{searchresult.id}</td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>{searchresult.username}</td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  <button style={buttonStyles} onClick={() => handleSelect(searchresult)}>
                    Select
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {openModel && (
          <div style={scrollContentStyles}>
            <CustomTimePicker label="Days" value={days} onChange={setDays} options={dayOptions} />
            <CustomTimePicker label="Hours" value={hours} onChange={setHours} options={hourOptions} />
            <CustomTimePicker label="Minutes" value={minutes} onChange={setMinutes} options={minuteOptions} />
            <CustomTimePicker label="Seconds" value={seconds} onChange={setSeconds} options={secondOptions} />

            <textarea
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              placeholder="Reason for muting"
              rows="4"
              style={textareaStyles}
            />

<input
              type="text"
              placeholder="Enter a link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              style={{ ...inputStyles, marginBottom: "10px" }}
            />
            {/* Image Upload */}
            <div style={{ marginBottom: "10px" }}>
              <label style={{ display: "block", marginBottom: "5px" }}>
                Image:
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
                style={{ fontSize: "14px" }}
              />
            </div>
            {/* Video Upload */}
            <div style={{ marginBottom: "10px" }}>
              <label style={{ display: "block", marginBottom: "5px" }}>
                Video:
              </label>
              <input
                type="file"
                accept="video/*"
                onChange={(e) => setVideo(e.target.files[0])}
                style={{ fontSize: "14px" }}
              />
            </div>

            <div style={{ textAlign: "center" }}>
              <button style={buttonStyles} onClick={()=>handleMute()}>Confirm Mute</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};



// const MuteCommentModal = ({ onClose,onMute }) => {
//   const [reason, setReason] = useState('');
//   const [hours, setHours] = useState(0);
//   const [minutes, setMinutes] = useState(0);
//   const [seconds, setSeconds] = useState(0);
//   const [days, setDays] = useState(0);

//   const dayOptions = Array.from({ length: 31 }, (_, i) => i); // 0 to 30 days
//   const hourOptions = Array.from({ length: 24 }, (_, i) => i); // 0 to 23 hours
//   const minuteOptions = Array.from({ length: 60 }, (_, i) => i); // 0 to 59 minutes
//   const secondOptions = Array.from({ length: 60 }, (_, i) => i); // 0 to 59 seconds

//   const handleBlock = () => {
//     const totalSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;
// console.log(totalSeconds,reason,'reasonreasonreasonreason')
//        if (window.confirm(`Are you sure you want to block the user for ${hours} hours, ${minutes} minutes, and ${seconds} seconds?`)) {
//         onMute(totalSeconds, reason);
//     }
//     {
//       onClose();
//       // Perform mute action with totalSeconds and reason
//     }
   
//   };

//   return (
//     <div style={modalOverlayStyles}>
//       <div style={modalContainerStyles}>
//         <h2 style={modalTitleStyles}>Mute User Comments</h2>
//         <p style={modalDescriptionStyles}>
//           Specify the duration for muting the user and provide a reason:
//         </p>

//         <CustomTimePicker label="Days" value={days} onChange={setDays} options={dayOptions} />
//         <CustomTimePicker label="Hours" value={hours} onChange={setHours} options={hourOptions} />
//         <CustomTimePicker label="Minutes" value={minutes} onChange={setMinutes} options={minuteOptions} />
//         <CustomTimePicker label="Seconds" value={seconds} onChange={setSeconds} options={secondOptions} />

//         <textarea
//           value={reason}
//           onChange={(e) => setReason(e.target.value)}
//           placeholder="Reason for muting"
//           rows="4"
//           style={textareaStyles}
//         />

//         <div style={buttonGroupStyles}>
//           <button
//             style={primaryButtonStyles}
//             onClick={handleBlock}
//             onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056b3')}
//             onMouseLeave={(e) => (e.target.style.backgroundColor = '#007BFF')}
//           >
//             Confirm Mute
//           </button>
//           <button
//             style={secondaryButtonStyles}
//             onClick={onClose}
//             onMouseEnter={(e) => (e.target.style.backgroundColor = '#5a6268')}
//             onMouseLeave={(e) => (e.target.style.backgroundColor = '#6c757d')}
//           >
//             Cancel
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// const modalOverlayStyles = {
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   position: 'fixed',
//   top: '0',
//   left: '0',
//   width: '100%',
//   height: '100%',
//   backgroundColor: 'rgba(0, 0, 0, 0.8)',
//   zIndex: '1000',
// };

// const modalContainerStyles = {
//   backgroundColor: '#fff',
//   padding: '30px',
//   borderRadius: '15px',
//   width: '450px',
//   boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
//   textAlign: 'center',
// };

// const modalTitleStyles = {
//   fontSize: '24px',
//   fontWeight: '700',
//   color: '#333',
//   marginBottom: '15px',
// };

// const modalDescriptionStyles = {
//   fontSize: '14px',
//   color: '#666',
//   marginBottom: '20px',
// };

// const textareaStyles = {
//   width: '100%',
//   padding: '12px',
//   borderRadius: '8px',
//   border: '1px solid #ccc',
//   resize: 'none',
//   boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
//   marginBottom: '20px',
// };

// const buttonGroupStyles = {
//   display: 'flex',
//   justifyContent: 'space-between',
// };

// const primaryButtonStyles = {
//   backgroundColor: '#007BFF',
//   color: '#fff',
//   padding: '12px 20px',
//   borderRadius: '8px',
//   border: 'none',
//   cursor: 'pointer',
//   fontSize: '14px',
//   transition: 'background-color 0.3s ease',
// };

// const secondaryButtonStyles = {
//   backgroundColor: '#6c757d',
//   color: '#fff',
//   padding: '12px 20px',
//   borderRadius: '8px',
//   border: 'none',
//   cursor: 'pointer',
//   fontSize: '14px',
//   transition: 'background-color 0.3s ease',
// };
