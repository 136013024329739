import React, { useEffect, useState } from 'react';
import IncludeSideBar from '../../../Components/Sidebar/IncludeSideBar'
import '../../Users/All/AllUser.css'
import useAuth from '../../../useAuth'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Loader component
function Loader() {
  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );
}

function AllVideos() {
  const allVideoApis = require('../../../apis/all_user_video');
  const { user } = useAuth()
  const role = user ? user.role : null;
  console.log(user?.permissions?.Videos, "video section user?.permissions?.Users")
  const hasvideoDelete = user?.permissions?.Videos?.Delete?.selected || user?.role === 'superadmin';
  const hasBlockVideo = user?.permissions?.Videos?.['Block Video']?.selected || user?.role === 'superadmin';
  const hasRankVideo = user?.permissions?.Videos?.['Rank Video']?.selected || user?.role === 'superadmin';
  const hasProfileRankVideo = user?.permissions?.Videos?.['Profile Rank Video']?.selected || user?.role === 'superadmin';
  const hasViewComment = user?.permissions?.Videos?.['View Comment']?.selected || user?.role === 'superadmin';

  const [videos, setVideos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [videosPerPage] = useState(10); // Number of videos to display per page
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [thumbnailClicked, setThumbnailClicked] = useState(false);
  const [loading, setLoading] = useState(true); // Add a loading state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);






  //testing
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('')
  const [pageWindow, setPageWindow] = useState({ start: 1, end: 20 });


  const [inputPageNumber, setInputPageNumber] = useState(''); // State to hold the input page number
  const [inputFocus, setInputFocus] = useState(false);
  const [buttonFocus, setButtonFocus] = useState(false);


  const getAllVideo = async (page, searchTerm = '') => {

    try {
      setLoading(true);

      const response = await allVideoApis.getAllUserVideo(page, searchTerm);
      console.log(response.videos, 'response.videos response.videos')
      setVideos(response.videos);
      // setVideos(response.videos || []);



      setTotalPages(response.pagination.totalPages);

    } catch (error) {
      console.error("Error fetching AllVideos:", error);
    } finally {
      setLoading(false); // Hide loader after data retrieval (success or failure)
    }
  };

  useEffect(() => {
    getAllVideo(currentPage, searchTerm);

  }, [currentPage, searchTerm]);

  const handleSearch = (searchValue) => {
    setSearchTerm(searchValue);
  };


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      // setCurrentPage((prevPage) => prevPage + 1);
      setCurrentPage(currentPage + 1);
      setPageWindow({
        start: Math.floor((currentPage - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((currentPage - 1) / 20) * 20 + 20, totalPages),
      });
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      // setCurrentPage((prevPage) => prevPage - 1);
      setCurrentPage(currentPage - 1);
      setPageWindow({
        start: Math.floor((currentPage - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((currentPage - 1) / 20) * 20 + 20, totalPages),
      });
    }
  };


  const handleNextPageWindow = () => {
    if (pageWindow.end < totalPages) {
      setPageWindow({
        start: pageWindow.start + 19,
        end: Math.min(pageWindow.end + 19, totalPages),
      });
    }
  };

  const handlePreviousPageWindow = () => {
    if (pageWindow.start > 1) {
      setPageWindow({
        start: pageWindow.start - 20,
        end: pageWindow.end - 20,
      });
    }
  };

  const handlePageClick = (page) => {
    handlePageChange(page);
    if (page === pageWindow.end && pageWindow.end < totalPages) {
      handleNextPageWindow();
    } else if (page === pageWindow.start && pageWindow.start > 1) {
      handlePreviousPageWindow();
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    setPageWindow({
      start: Math.floor((totalPages - 1) / 20) * 20 + 1,
      end: totalPages,
    });
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    setPageWindow({
      start: 1,
      end: Math.min(20, totalPages),
    });
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(inputPageNumber, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setPageWindow({
        start: Math.floor((pageNumber - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((pageNumber - 1) / 20) * 20 + 20, totalPages),
      });
      setInputPageNumber('');
    } else {
      alert(`Please enter a valid page number between 1 and ${totalPages}`);
    }
  };


  //testing end


  const blockvideohandler = async (id) => {
    const userConfirmed = window.confirm("Are you sure you want to block this video?");

    // If the user did not confirm, exit the function
    if (!userConfirmed) {
      return;
    }
    try {
      const response = await allVideoApis.blockVideo(id);
      if (response.message === 'Video blocked successfully') {
        alert('Successfully initiated Video Block request');

      }
      // After data is fetched, set loading to false
      setLoading(false);
    } catch (error) {
      console.error("Error fetching blockVideos:", error);
      setLoading(false); // Handle loading state on error as well
    }
  }

  //   // Calculate the index range for the videos to display on the current page
  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo);
  // console.log(currentVideos,'currentVideos')
  // Function to change the current page
  // const paginate = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  //   setSelectedVideo(null); // Clear selected video when changing pages
  // };

  // Function to show the video when a user clicks on the thumbnail
  const showVideo = (video) => {
    setSelectedVideo(video);
    setThumbnailClicked(true);
  };

  // Function to go back to the video list
  const closeVideo = () => {
    setSelectedVideo(null);
    setThumbnailClicked(false);
  };



  const handleDeleteVideo = async (video, days, reason) => {
    console.log(video.user.email, 'videovideovideovideo')
    try {
      const data = {
        videoid: video.id,
        video: video.video,
        deleteAfter: days.toString(),
        reason: reason,
        thum: video.thum,
        email: video.user.email

      }
      setLoading(true);
      const response = await allVideoApis.VideoDeletionRequest(data);
      console.log(response, "response")

      if (response.success === true) {
        alert('Successfully initiated Video deletion request');

      } else if (response.message == 'all ready exit') {
        alert('Video deletion request already exists for this user')
      }
    } catch (error) {
      console.error("Error fetching user photos:", error);
    } finally {
      setLoading(false);
    }
  }

  const openModal = (userId) => {
    console.log(userId, 'userId open model')
    setSelectedUserId(userId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUserId(null);
  };

  const handleDelete = (days, reason) => {
    handleDeleteVideo(selectedUserId, days, reason);
    closeModal();
  };


  //openRankedModal
  const [isrankedModalOpen, setrankedIsModalOpen] = useState(false);
  const [Selectedrankedvideoid, setSelectedrankedvideoid] = useState(null);

  const handleRankedVideo = async (videoid, totalSeconds, ranked, links, images, addlink, linktext) => {
    setLoading(true)
    // Create a FormData object
    const formData = new FormData();
    formData.append('videoid', videoid);
    formData.append('addlink', addlink);
    formData.append('linktext', linktext);


    formData.append('totalSeconds', totalSeconds);
    formData.append('rank', ranked);

    // Append each link and corresponding image to the FormData object
    links.forEach((link, index) => {
      formData.append(`link`, link);
      // console.log(`link`, link)

    });
    images.forEach((image, index) => {
      formData.append(`image`, image);
      // console.log(`rankimage`, image)

    });
    try {
      // const data = {
      //   videoid: videoid,
      //   totalSeconds: totalSeconds,
      //   ranked: ranked,
      //   link:link
      // }
      const response = await allVideoApis.addrankedvideo(formData);
      console.log(response)
      // After data is fetched, set loading to false
      setLoading(false);
      if (response.success === true) {
        alert('Successfully add ranked');

      } else {
        alert(response.message)
      }
      setrankedIsModalOpen(false);

    } catch (error) {
      console.error("Error fetching AllVideos:", error);
      setLoading(false); // Handle loading state on error as well
    }
  };

  const openRankedModal = (videoid) => {
    setSelectedrankedvideoid(videoid);
    setrankedIsModalOpen(true);
  };

  const closeblockModal = () => {
    setrankedIsModalOpen(false);
    setisprofilerankedModalOpen(false)
    setSelectedrankedvideoid(null);
    setSelectedprofilerankedvideoid(null)
  };


  //profile rank
  const handleprofileRankedVideo = async (videoid, totalSeconds, ranked, links, images, addlink, linktext, username) => {
    setLoading(true)
    // Create a FormData object
    const formData = new FormData();
    formData.append('videoid', videoid);
    formData.append('addlink', addlink);
    formData.append('linktext', linktext);
    formData.append('username', username);

    formData.append('totalSeconds', totalSeconds);
    formData.append('rank', ranked);

    // Append each link and corresponding image to the FormData object
    links.forEach((link, index) => {
      formData.append(`link`, link);
      // console.log(`link`, link)

    });
    images.forEach((image, index) => {
      formData.append(`image`, image);
      // console.log(`rankimage`, image)

    });
    try {

      const response = await allVideoApis.addprofilerankedvideo(formData);
      console.log(response)
      // After data is fetched, set loading to false
      setLoading(false);
      if (response.success === true) {
        alert('Successfully add ranked');

      } else {
        alert(response.message)
      }
      setisprofilerankedModalOpen(false);

    } catch (error) {
      console.error("Error fetching AllVideos:", error);
      setLoading(false); // Handle loading state on error as well
    }

  }
  const [isprofilerankedModalOpen, setisprofilerankedModalOpen] = useState(false);
  const [Selectedprofilerankedvideoid, setSelectedprofilerankedvideoid] = useState(null);
  const openprofileRankedModal = (videoid) => {
    setSelectedprofilerankedvideoid(videoid);
    setisprofilerankedModalOpen(true);
  };
  const handleNoAccess = () => {
    alert('You are not able to access this feature.');
  };

  const [ShowAllCommentModel, setShowAllCommentModel] = useState(false)
  const [SelectCommentVideoid, setSelectCommentVideoid] = useState(null)
  const [CommentData, setCommentData] = useState([])
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEditReplyCommentModalOpen, setisEditReplyCommentModalOpen] = useState(false)
  const OpenShowCommentModel = (videoid) => {
    setSelectCommentVideoid(videoid)
    GetAllCommentByVideoId(videoid)
    setShowAllCommentModel(true)
  }
  const closeViewCommentModal = () => {
    setShowAllCommentModel(false)
    setIsEditModalOpen(false)
    setisEditReplyCommentModalOpen(false)
  };
  const GetAllCommentByVideoId = async (videoid) => {
    setLoading(true)

    try {
      const response = await allVideoApis.GetAllCommentByVideoId(videoid);
      console.log(response.video, 'response.video')
      setCommentData(response.video)
      // After data is fetched, set loading to false
      setLoading(false)

    } catch (e) {
      console.log(e)
    }
  }


  const HandleComment = async (payload) => {
    try {
      const response = await allVideoApis.EditComment(payload);
      if (response.success) {

        alert(response.message)
        setIsEditModalOpen(false)

        GetAllCommentByVideoId(SelectCommentVideoid)

      }
      // After data is fetched, set loading to false
      setLoading(false)

    } catch (e) {
      console.log(e)
    }
  }

  const HandleCommentDelet = async (id) => {
    try {
      const response = await allVideoApis.DeleteComment(id);
      if (response.success) {

        alert(response.message)

        GetAllCommentByVideoId(SelectCommentVideoid)

      }
      // After data is fetched, set loading to false
      setLoading(false)

    } catch (e) {
      console.log(e)
    }
  }

  const HandleReplyComment = async (payload) => {
    try {
      const response = await allVideoApis.EditReplyComment(payload);
      if (response.success) {

        alert(response.message)
        setisEditReplyCommentModalOpen(false)

        GetAllCommentByVideoId(SelectCommentVideoid)

      }
      // After data is fetched, set loading to false
      setLoading(false)

    } catch (e) {
      console.log(e)
    }
  }

  const HandleCommentReplyDelete=async(id)=>{
    try {
      const response = await allVideoApis.DeleteReplyComment(id);
      if (response.success) {

        alert(response.message)

        GetAllCommentByVideoId(SelectCommentVideoid)

      }
      // After data is fetched, set loading to false
      setLoading(false)

    } catch (e) {
      console.log(e)
    }
  }
  return (
    <IncludeSideBar>
      <div>
        <h2>All Videos</h2>
        <input
          type="text"
          placeholder="Search by video ID"
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
        />

        {isModalOpen && <DeleteModal onClose={closeModal} onDelete={handleDelete} />}

        {loading ? (
          <Loader />
        ) : selectedVideo ? (
          <div>
            <h3 style={{ fontSize: '1.5em', marginBottom: '10px' }}>Video Details</h3>
            <img
              src={`https://dpcst9y3un003.cloudfront.net/${selectedVideo?.user?.profile_pic}`}
              style={{
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                marginBottom: '10px',
                marginLeft: '48%',
              }}
            />
            <p style={{ marginBottom: '10px' }}>
              <strong>User ID:</strong> {selectedVideo?.user.id}
            </p>
            <p style={{ marginBottom: '10px' }}>
              <strong>Comments:</strong> {selectedVideo?.comments.length}
            </p>
            <p style={{ marginBottom: '10px' }}>
              <strong>Views:</strong> {selectedVideo?.viewCount}
            </p>
            <p style={{ marginBottom: '10px' }}>
              <strong>User Name:</strong> {selectedVideo?.user?.nickname}
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
              <video controls width="400">
                <source
                  src={`https://dpcst9y3un003.cloudfront.net/${selectedVideo?.video}`}
                  type="video/mp4"
                />
              </video>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
              <button
                onClick={closeVideo}
                style={{
                  backgroundColor: 'red',
                  color: 'white',
                  border: 'none',
                  padding: '5px 10px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
              >
                Close Video
              </button>
            </div>
          </div>
        ) : (

          <table className="table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Video</th>
                <th>User Name</th>
                {/* <th >Title</th> */}

                <th >Description</th>
                <th>Created Date</th>
                <th>Likes</th>
                <th>Comments</th>
                <th>Diamond Value</th>
                <th>Shared</th>
                <th>Views</th>
                <th>Action</th>

              </tr>
            </thead>
            <tbody>
              {videos.map((video) => (

                <tr key={video?.id}>
                  <td>{video?.id}</td>
                  <td>
                    <img
                      src={`https://dpcst9y3un003.cloudfront.net/${video?.thum}`}
                      alt="Thumbnail"
                      onClick={() => showVideo(video)}
                      style={{ cursor: 'pointer', width: '50px', height: '50px' }}
                    />
                  </td>
                  <td>{video?.user?.username}</td>
                  {/* <td>{video.title}</td> */}

                  <td>{video?.description}</td>
                  <td>{new Date(video?.created).toLocaleString()}</td>
                  <td>{video?.like}</td>
                  <td>{video?.comments?.length}</td>
                  <td>{video?.diamond_value}</td>
                  <td>{video?.shared}</td>
                  <td>{video?.viewCount}</td>
                  <td>

                    <div style={{ display: 'flex', gap: '10px' }}>
                      {/* <button onClick={() => handleDeleteVideo(video)}>Delete</button> */}
                      <button onClick={() => {
                        if (hasvideoDelete) {
                          openModal(video)
                        } else {
                          handleNoAccess()
                        }
                      }}
                        style={{
                          backgroundColor:
                            !hasvideoDelete
                              ? '#C5C6C7'
                              : '', // Set red background when there is access
                          cursor: !hasvideoDelete ? 'not-allowed' : 'pointer', // Changes cursor when disabled
                        }}
                      >Delete</button>

                      <button onClick={() => {
                        if (hasBlockVideo) {
                          blockvideohandler(video?.id)
                        } else {
                          handleNoAccess()
                        }
                      }}
                        style={{
                          backgroundColor:
                            !hasBlockVideo
                              ? '#C5C6C7'
                              : video.block === true
                                ? 'Red'
                                : '',
                          cursor: !hasBlockVideo ? 'not-allowed' : 'pointer'
                        }}
                      >{video.block === true ? 'All Ready Block' : 'Block Video'}</button>

                      <button onClick={() => {
                        if (hasRankVideo) {
                          openRankedModal(video.id)
                        } else {
                          handleNoAccess()
                        }
                      }}
                        style={{
                          backgroundColor:
                            !hasRankVideo
                              ? '#C5C6C7'
                              : '', // Set red background when there is access
                          cursor: !hasRankVideo ? 'not-allowed' : 'pointer', // Changes cursor when disabled
                        }}
                      >
                        Ranked Video</button>
                      <button onClick={() => {
                        if (hasProfileRankVideo) {
                          openprofileRankedModal(video?.id)
                        } else {
                          handleNoAccess()
                        }
                      }}
                        style={{
                          backgroundColor:
                            !hasProfileRankVideo
                              ? '#C5C6C7'
                              : '', // Set red background when there is access
                          cursor: !hasProfileRankVideo ? 'not-allowed' : 'pointer', // Changes cursor when disabled
                        }}
                      > Profile Ranked Video </button>

                      <button
                        onClick={() => {
                          if(hasViewComment){
                            OpenShowCommentModel(video.id)

                          }else{
                            handleNoAccess()
                          }
                        }}
                        style={{
                          backgroundColor:
                            !hasViewComment
                              ? '#C5C6C7'
                              : '', // Set red background when there is access
                          cursor: !hasViewComment ? 'not-allowed' : 'pointer', // Changes cursor when disabled
                        }}
                      > View Comment </button>


                    </div>





                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )
        }
        {!thumbnailClicked && (
          <>
            <div className="pagination">
              <button onClick={handleFirstPage} disabled={currentPage === 1}>
                First
              </button>
              <button onClick={handlePrevious} disabled={currentPage === 1}>
                Previous
              </button>
              {Array.from(
                { length: pageWindow.end - pageWindow.start + 1 },
                (_, index) => pageWindow.start + index
              ).map((page) => (
                <button
                  key={page}
                  onClick={() => handlePageClick(page)}
                  className={currentPage === page ? 'active' : ''}
                  style={{
                    backgroundColor: currentPage === page ? 'red' : '',
                    color: 'white',
                  }}
                >
                  {page}
                </button>
              ))}
              <button onClick={handleNext} disabled={currentPage === totalPages}>
                Next
              </button>
              <button onClick={handleLastPage} disabled={currentPage === totalPages}>
                Last
              </button>
            </div>



            {/* direct page */}
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
              <input
                type="number"
                value={inputPageNumber}
                onChange={(e) => setInputPageNumber(e.target.value)}
                placeholder="Page No"
                style={{
                  padding: '5px',
                  fontSize: '14px',
                  // border: '1px solid #ccc',
                  border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,

                  borderRadius: '4px 0 0 4px',
                  outline: 'none',
                  width: '90px'
                }}
                onFocus={() => setInputFocus(true)}
                onBlur={() => setInputFocus(false)}
              />
              <button
                onClick={handleGoToPage}
                style={{
                  padding: '6.5px 10px',
                  fontSize: '14px',
                  border: '1px solid #ccc',
                  borderLeft: 'none',
                  borderRadius: '0 4px 4px 0',
                  // backgroundColor: '#007bff',

                  backgroundColor: `${buttonFocus ? 'green' : '#007bff'}`,
                  color: 'white',
                  cursor: 'pointer',
                  outline: 'none',
                }}


              >
                Go
              </button>
            </div>
          </>
        )
        }


        {isrankedModalOpen && (
          <Addranked
            onClose={closeblockModal}
            loading={loading}
            onRanked={(totalSeconds, ranked, links, images, addlink, linktext) => handleRankedVideo(Selectedrankedvideoid, totalSeconds, ranked, links, images, addlink, linktext)}
          />
        )}
        {
          isprofilerankedModalOpen && (
            <ProfileAddranked
              onClose={closeblockModal}
              loading={loading}
              onRanked={(totalSeconds, ranked, links, images, addlink, linktext, username) => handleprofileRankedVideo(Selectedprofilerankedvideoid, totalSeconds, ranked, links, images, addlink, linktext, username)}

            />
          )
        }

        {
          ShowAllCommentModel && (
            <OpenCommeentModel
              onClose={closeViewCommentModal}
              loading={loading}
              commentdata={CommentData}
              HandleComment={HandleComment}
              // EditResponse={EditResponse}
              setIsEditModalOpen={setIsEditModalOpen}
              isEditModalOpen={isEditModalOpen}
              HandleCommentDelet={HandleCommentDelet}
              isEditReplyCommentModalOpen={isEditReplyCommentModalOpen}
              setisEditReplyCommentModalOpen={setisEditReplyCommentModalOpen}
              HandleReplyComment={HandleReplyComment}
              HandleCommentReplyDelete={HandleCommentReplyDelete}


            />
          )

        }
      </div>
    </IncludeSideBar>
  );
}

export default AllVideos;


//  model for account deletion request

const DeleteModal = ({ onClose, onDelete }) => {
  const [reason, setReason] = useState('');

  const modalStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)'
  };

  const modalContentStyles = {
    background: 'white',
    padding: '20px',
    borderRadius: '5px',
    textAlign: 'center'
  };

  const buttonStyles = {
    margin: '5px',
    background: 'green'

  };
  const buttoncancel = {
    margin: '5px',
    background: 'red'

  }
  const textareaStyles = {
    width: '100%',
    padding: '8px',
    boxSizing: 'border-box',
    marginBottom: '10px',
    resize: 'none'
  };
  const handleDelete = (days) => {

    onClose(); // Close the modal before showing the confirmation dialog
    const message = days === '2min'
      ? 'Are you sure you want to delete the video in 2 minutes?'
      : `Are you sure you want to delete the video in ${days} days?`;

    if (window.confirm(message)) {
      onDelete(days, reason);
    }
  };

  return (
    <div style={modalStyles}>
      <div style={modalContentStyles}>
        <h2 style={{ color: 'red' }}>Video Deletion Request</h2>
        <p>Select the number of days After the video is deleted:</p>
        <textarea
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Reason for deletion"
          rows="3"
          style={textareaStyles}
        />
        <button style={buttonStyles} onClick={() => handleDelete('2min')}>Now</button>

        <button style={buttonStyles} onClick={() => handleDelete(1)}>1 Day</button>
        <button style={buttonStyles} onClick={() => handleDelete(7)}>7 Days</button>
        <button style={buttonStyles} onClick={() => handleDelete(15)}>15 Days</button>
        <button style={buttonStyles} onClick={() => handleDelete(30)}>30 Days</button>
        <button style={buttoncancel} onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};




// add Ranked




const TimePicker = ({ label, value, onChange }) => (
  <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <label style={{ flex: '1', textAlign: 'left' }}>{label}: </label>
    <input
      type="number"
      value={value}
      onChange={(e) => onChange(parseInt(e.target.value))}
      style={{
        width: '70px',
        padding: '10px',
        margin: '0 10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        textAlign: 'center',
      }}
      min="0"
    />
  </div>
);
// const Addranked = ({ onClose, onRanked, loading }) => {
//   // const [reason, setReason] = useState('');
//   const [rank, setRank] = useState(''); // New state for rank
//   const [link, setlink] = useState(''); // New state for rank
//   const [pairs, setPairs] = useState([{ link: '', image: '' }]);

//   const [hours, setHours] = useState(0);
//   const [minutes, setMinutes] = useState(0);
//   const [seconds, setSeconds] = useState(0);
//   const [days, setDays] = useState(0);
//   const addPair = () => {
//     setPairs([...pairs, { link: '', image: '' }]);
//   };
//   const handlePairChange = (index, field, value) => {
//     const updatedPairs = pairs.map((pair, i) =>
//       i === index ? { ...pair, [field]: value } : pair
//     );
//     setPairs(updatedPairs);
//   };

// //   const handleaddranked = () => {
// //     // onClose();
// //     // const totalSeconds = hours * 3600 + minutes * 60 + seconds;
// //     const totalSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;
// // // console.log(link,'linklinklinklinklink')
// //     if (window.confirm(`Are you sure you want to add this as a Ranked Video `)) {
// //       onRanked(totalSeconds,rank,link);
// //     }
// //   };
// const handleaddranked = () => {
//   const totalSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;

//   if (window.confirm(`Are you sure you want to add this as a Ranked Video`)) {
//     const links = pairs.map(pair => pair.link);
//     const images = pairs.map(pair => pair.image);
//     onRanked(totalSeconds, rank, links, images);
//   }
// };

//   const modalStyles = {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     position: 'fixed',
//     top: '0',
//     left: '0',
//     width: '100%',
//     height: '100%',
//     backgroundColor: 'rgba(0, 0, 0, 0.7)',
//     zIndex: '1000',
//   };

//   const modalContentStyles = {
//     backgroundColor: '#fff',
//     padding: '30px',
//     borderRadius: '10px',
//     width: '400px',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     textAlign: 'center',
//   };

//   // const textareaStyles = {
//   //   width: '100%',
//   //   padding: '15px',
//   //   margin: '15px 0',
//   //   borderRadius: '5px',
//   //   border: '1px solid #ccc',
//   //   resize: 'none',
//   // };
//   const inputStyles = {
//     width: '100%',
//     padding: '15px',
//     margin: '15px 0',
//     borderRadius: '5px',
//     border: '1px solid #ccc',
//   };
//   const buttonStyles = {
//     backgroundColor: '#007BFF',
//     color: 'white',
//     padding: '12px 25px',
//     margin: '10px 5px',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     transition: 'background-color 0.3s ease',
//   };

//   const buttonCancelStyles = {
//     backgroundColor: '#6c757d',
//     color: 'white',
//     padding: '12px 25px',
//     margin: '10px 5px',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     transition: 'background-color 0.3s ease',
//   };

//   return (
//     <div style={modalStyles}>
//       <div style={modalContentStyles}>
//         <h2 style={{ color: 'red', marginBottom: '20px' }}>Add Video Ranked Request</h2>
//         {loading && (
//           <div className="loader-container">
//             <div className="loader"> </div>
//           </div>
//         )}
//         <p style={{ marginBottom: '20px' }}>Select the time for Ranked</p>
//         <TimePicker label="Days" value={days} onChange={setDays} />

//         <TimePicker label="Hours" value={hours} onChange={setHours} />
//         <TimePicker label="Minutes" value={minutes} onChange={setMinutes} />
//         <TimePicker label="Seconds" value={seconds} onChange={setSeconds} />
//         <input
//           type="text"
//           value={rank}
//           onChange={(e) => setRank(e.target.value)}
//           placeholder="Rank"
//           style={inputStyles}
//         />
//         <input
//           type="text"
//           value={link}
//           onChange={(e) => setlink(e.target.value)}
//           placeholder="Add Link"
//           style={inputStyles}
//         />
//         <button
//           style={{ ...buttonStyles, backgroundColor: '#007BFF' }}
//           onClick={handleaddranked}
//           onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056b3')}
//           onMouseLeave={(e) => (e.target.style.backgroundColor = '#007BFF')}
//         >
//           Add Ranked
//         </button>
//         <button
//           style={{ ...buttonCancelStyles, backgroundColor: '#6c757d' }}
//           onClick={onClose}
//           onMouseEnter={(e) => (e.target.style.backgroundColor = '#5a6268')}
//           onMouseLeave={(e) => (e.target.style.backgroundColor = '#6c757d')}
//         >
//           Cancel
//         </button>
//       </div>
//     </div>
//   );
// };

const Addranked = ({ onClose, onRanked, loading }) => {
  const [rank, setRank] = useState('');
  const [addlink, setaddlink] = useState('')
  const [linktext, setlinktext] = useState()
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [days, setDays] = useState(0);
  const [pairs, setPairs] = useState([{ link: '', image: '' }]);
  const MAX_PAIRS = 5;

  // const addPair = () => {
  //   setPairs([...pairs, { link: '', image: '' }]);
  // };
  const addPair = () => {
    if (pairs.length < MAX_PAIRS) {
      setPairs([...pairs, { link: '', image: '' }]);
    } else {
      alert(`You can only add up to ${MAX_PAIRS} pairs.`);
    }
  };
  const removePair = (index) => {
    setPairs(pairs.filter((_, i) => i !== index));
  };
  // const handlePairChange = (index, field, value) => {
  //   const updatedPairs = pairs.map((pair, i) =>
  //     i === index ? { ...pair, [field]: value } : pair
  //   );
  //   setPairs(updatedPairs);
  // };
  const handlePairChange = (index, field, value) => {
    const updatedPairs = pairs.map((pair, i) =>
      i === index ? { ...pair, [field]: value } : pair
    );
    setPairs(updatedPairs);

    if (field === 'image' && value) {
      const reader = new FileReader();
      reader.onload = () => {
        updatedPairs[index] = { ...updatedPairs[index], preview: reader.result };
        setPairs([...updatedPairs]);
      };
      reader.readAsDataURL(value);
    }
  };
  const handleaddranked = () => {
    let totalSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;

    if (window.confirm(`Are you sure you want to add this as a Ranked Video`)) {
      const links = pairs.map(pair => pair.link);
      const images = pairs.map(pair => pair.image);

      onRanked(totalSeconds, rank, links, images, addlink, linktext);
    }

  };
  // console.log('Rank:', rank);
  // console.log('Pairs:', pairs);
  const modalStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: '1000',
  };

  const modalContentStyles = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    width: '600px',
    height: '600px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    overflowY: 'auto',
  };

  const inputContainerStyles = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  };

  const inputStyles = {
    width: 'calc(50% - 10px)',
    padding: '10px',
    margin: '0 5px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  };

  const buttonStyles = {
    backgroundColor: '#007BFF',
    color: 'white',
    padding: '10px 20px',
    margin: '10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };
  const buttonaddStyles = {
    backgroundColor: pairs.length >= MAX_PAIRS ? '#6c757d' : 'green'
    ,
    color: 'white',
    padding: '10px 20px',
    margin: '10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };


  const buttonCancelStyles = {
    backgroundColor: '#6c757d',
    color: 'white',
    padding: '10px 20px',
    margin: '10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };
  const previewStyles = {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    borderRadius: '5px',
    marginLeft: '10px',
  };

  return (
    <div style={modalStyles}>
      <div style={modalContentStyles}>
        <h2 style={{ color: 'red', marginBottom: '20px' }}>Add Video Ranked Request</h2>
        {loading && (
          <div className="loader-container">
            <div className="loader"> </div>
          </div>
        )}
        <p style={{ marginBottom: '20px' }}>Select the time for Ranked</p>
        <TimePicker label="Days" value={days} onChange={setDays} />
        <TimePicker label="Hours" value={hours} onChange={setHours} />
        <TimePicker label="Minutes" value={minutes} onChange={setMinutes} />
        <TimePicker label="Seconds" value={seconds} onChange={setSeconds} />
        <input
          type="text"
          value={addlink}
          onChange={(e) => setaddlink(e.target.value)}
          placeholder="addlink"
          style={{ ...inputStyles, margin: '0 0 10px 0' }}
        />
        <input
          type="text"
          value={linktext}
          onChange={(e) => setlinktext(e.target.value)}
          placeholder="linktext"
          maxLength={10} // Add this attribute

          style={{ ...inputStyles, margin: '0 0 10px 0' }}
        />
        <input
          type="text"
          value={rank}
          onChange={(e) => setRank(e.target.value)}
          placeholder="Rank"

          style={{ ...inputStyles, margin: '0 0 10px 0' }}
        />

        {pairs.map((pair, index) => (
          <div key={index} style={inputContainerStyles}>
            <input
              type="text"
              value={pair.link}
              onChange={(e) => handlePairChange(index, 'link', e.target.value)}
              placeholder="Add Link"
              style={inputStyles}
            />


            <input
              type="file"
              onChange={(e) => handlePairChange(index, 'image', e.target.files[0])}
              style={inputStyles}
            />
            {pair.preview && <img src={pair.preview} alt="Preview" style={previewStyles} />}

            <button
              onClick={() => removePair(index)}
              style={{ ...buttonStyles, backgroundColor: '#dc3545', marginLeft: '10px' }}
            >
              Remove
            </button>
          </div>
        ))}
        <button onClick={addPair}
          style={buttonaddStyles}
          disabled={pairs.length >= MAX_PAIRS}

        >Add More</button>
        <button
          style={buttonStyles}
          onClick={handleaddranked}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056b3')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#007BFF')}
        >
          Add Ranked
        </button>
        <button
          style={buttonCancelStyles}
          onClick={onClose}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#5a6268')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#6c757d')}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};


// const ProfileAddranked = ({ onClose, onRanked, loading }) => {
//   const [rank, setRank] = useState('');
//   const [username, setusername] = useState('');

//   const [addlink, setaddlink] = useState('')
//   const [linktext, setlinktext] = useState()
//   const [hours, setHours] = useState(0);
//   const [minutes, setMinutes] = useState(0);
//   const [seconds, setSeconds] = useState(0);
//   const [days, setDays] = useState(0);
//   const [pairs, setPairs] = useState([{ link: '', image: '' }]);
//   const MAX_PAIRS = 5;

//   // const addPair = () => {
//   //   setPairs([...pairs, { link: '', image: '' }]);
//   // };
//   const addPair = () => {
//     if (pairs.length < MAX_PAIRS) {
//       setPairs([...pairs, { link: '', image: '' }]);
//     } else {
//       alert(`You can only add up to ${MAX_PAIRS} pairs.`);
//     }
//   };
//   const removePair = (index) => {
//     setPairs(pairs.filter((_, i) => i !== index));
//   };
//   // const handlePairChange = (index, field, value) => {
//   //   const updatedPairs = pairs.map((pair, i) =>
//   //     i === index ? { ...pair, [field]: value } : pair
//   //   );
//   //   setPairs(updatedPairs);
//   // };
//   const handlePairChange = (index, field, value) => {
//     const updatedPairs = pairs.map((pair, i) =>
//       i === index ? { ...pair, [field]: value } : pair
//     );
//     setPairs(updatedPairs);

//     if (field === 'image' && value) {
//       const reader = new FileReader();
//       reader.onload = () => {
//         updatedPairs[index] = { ...updatedPairs[index], preview: reader.result };
//         setPairs([...updatedPairs]);
//       };
//       reader.readAsDataURL(value);
//     }
//   };
//   const handleaddranked = () => {
//     let totalSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;

//     if (window.confirm(`Are you sure you want to add this as a Ranked Video`)) {
//       const links = pairs.map(pair => pair.link);
//       const images = pairs.map(pair => pair.image);

//       onRanked(totalSeconds, rank, links, images, addlink, linktext, username);
//     }

//   };
//   // console.log('Rank:', rank);
//   // console.log('Pairs:', pairs);
//   const modalStyles = {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     position: 'fixed',
//     top: '0',
//     left: '0',
//     width: '100%',
//     height: '100%',
//     backgroundColor: 'rgba(0, 0, 0, 0.7)',
//     zIndex: '1000',
//   };

//   const modalContentStyles = {
//     backgroundColor: '#fff',
//     padding: '20px',
//     borderRadius: '10px',
//     width: '600px',
//     height: '600px',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     textAlign: 'center',
//     overflowY: 'auto',
//   };

//   const inputContainerStyles = {
//     display: 'flex',
//     alignItems: 'center',
//     marginBottom: '10px',
//   };

//   const inputStyles = {
//     width: 'calc(50% - 10px)',
//     padding: '10px',
//     margin: '0 5px',
//     borderRadius: '5px',
//     border: '1px solid #ccc',
//   };

//   const buttonStyles = {
//     backgroundColor: '#007BFF',
//     color: 'white',
//     padding: '10px 20px',
//     margin: '10px',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     transition: 'background-color 0.3s ease',
//   };
//   const buttonaddStyles = {
//     backgroundColor: pairs.length >= MAX_PAIRS ? '#6c757d' : 'green'
//     ,
//     color: 'white',
//     padding: '10px 20px',
//     margin: '10px',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     transition: 'background-color 0.3s ease',
//   };


//   const buttonCancelStyles = {
//     backgroundColor: '#6c757d',
//     color: 'white',
//     padding: '10px 20px',
//     margin: '10px',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     transition: 'background-color 0.3s ease',
//   };
//   const previewStyles = {
//     width: '100px',
//     height: '100px',
//     objectFit: 'cover',
//     borderRadius: '5px',
//     marginLeft: '10px',
//   };

//   return (
//     <div style={modalStyles}>
//       <div style={modalContentStyles}>
//         <h2 style={{ color: 'red', marginBottom: '20px' }}>Add Video Ranked Request For Profile</h2>
//         {loading && (
//           <div className="loader-container">
//             <div className="loader"> </div>
//           </div>
//         )}
//         <p style={{ marginBottom: '20px' }}>Select the time for Ranked</p>
//         <TimePicker label="Days" value={days} onChange={setDays} />
//         <TimePicker label="Hours" value={hours} onChange={setHours} />
//         <TimePicker label="Minutes" value={minutes} onChange={setMinutes} />
//         <TimePicker label="Seconds" value={seconds} onChange={setSeconds} />
//         <input
//           type="text"
//           value={addlink}
//           onChange={(e) => setaddlink(e.target.value)}
//           placeholder="addlink"
//           style={{ ...inputStyles, margin: '0 0 10px 0' }}
//         />
//         <input
//           type="text"
//           value={linktext}
//           onChange={(e) => setlinktext(e.target.value)}
//           placeholder="linktext"
//           maxLength={15} // Add this attribute

//           style={{ ...inputStyles, margin: '0 0 10px 0' }}
//         />
//         <input
//           type="text"
//           value={username}
//           onChange={(e) => setusername(e.target.value)}
//           placeholder="Username"
//           required
//           style={{ ...inputStyles, margin: '0 0 10px 0' }}
//         />
//         <input
//           type="number"
//           value={rank}
//           onChange={(e) => setRank(e.target.value)}
//           placeholder="Rank"
//           min="1" // Ensures the number is greater than 0
//           required // Makes the field required
//           style={{ ...inputStyles, margin: '0 0 10px 0' }}
//         />

//         {pairs.map((pair, index) => (
//           <div key={index} style={inputContainerStyles}>
//             <input
//               type="text"
//               value={pair.link}
//               onChange={(e) => handlePairChange(index, 'link', e.target.value)}
//               placeholder="Add Link"
//               style={inputStyles}
//             />


//             <input
//               type="file"
//               onChange={(e) => handlePairChange(index, 'image', e.target.files[0])}
//               style={inputStyles}
//             />
//             {pair.preview && <img src={pair.preview} alt="Preview" style={previewStyles} />}

//             <button
//               onClick={() => removePair(index)}
//               style={{ ...buttonStyles, backgroundColor: '#dc3545', marginLeft: '10px' }}
//             >
//               Remove
//             </button>
//           </div>
//         ))}
//         <button onClick={addPair}
//           style={buttonaddStyles}
//           disabled={pairs.length >= MAX_PAIRS}

//         >Add More</button>
//         <button
//           style={buttonStyles}
//           onClick={handleaddranked}
//           onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056b3')}
//           onMouseLeave={(e) => (e.target.style.backgroundColor = '#007BFF')}
//         >
//           Add Ranked
//         </button>
//         <button
//           style={buttonCancelStyles}
//           onClick={onClose}
//           onMouseEnter={(e) => (e.target.style.backgroundColor = '#5a6268')}
//           onMouseLeave={(e) => (e.target.style.backgroundColor = '#6c757d')}
//         >
//           Cancel
//         </button>
//       </div>
//     </div>
//   );
// };

const ProfileAddranked = ({ onClose, onRanked, loading }) => {
  const [rank, setRank] = useState('');
  const [username, setUsername] = useState('');
  const [addlink, setAddlink] = useState('');
  const [linktext, setLinktext] = useState('');
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [days, setDays] = useState(0);
  const [pairs, setPairs] = useState([{ link: '', image: '' }]);
  const MAX_PAIRS = 5;

  const addPair = () => {
    if (pairs.length < MAX_PAIRS) {
      setPairs([...pairs, { link: '', image: '' }]);
    } else {
      alert(`You can only add up to ${MAX_PAIRS} pairs.`);
    }
  };

  const removePair = (index) => {
    setPairs(pairs.filter((_, i) => i !== index));
  };

  const handlePairChange = (index, field, value) => {
    const updatedPairs = pairs.map((pair, i) =>
      i === index ? { ...pair, [field]: value } : pair
    );
    setPairs(updatedPairs);

    if (field === 'image' && value) {
      const reader = new FileReader();
      reader.onload = () => {
        updatedPairs[index] = { ...updatedPairs[index], preview: reader.result };
        setPairs([...updatedPairs]);
      };
      reader.readAsDataURL(value);
    }
  };

  const handleAddranked = () => {
    // Calculate total time in seconds
    let totalSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;

    // Validate required fields
    if (!rank || rank <= 0) {
      alert('Rank is required and must be greater than 0.');
      return;
    }

    if (!username) {
      alert('Username is required.');
      return;
    }

    if (totalSeconds <= 0) {
      alert('Please select a valid time duration.');
      return;
    }

    if (window.confirm(`Are you sure you want to add this as a Ranked Video?`)) {
      const links = pairs.map((pair) => pair.link);
      const images = pairs.map((pair) => pair.image);

      onRanked(totalSeconds, rank, links, images, addlink, linktext, username);
    }
  };

  const modalStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: '1000',
  };

  const modalContentStyles = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    width: '600px',
    height: '600px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    overflowY: 'auto',
  };

  const inputContainerStyles = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  };

  const inputStyles = {
    width: 'calc(50% - 10px)',
    padding: '10px',
    margin: '0 5px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  };

  const buttonStyles = {
    backgroundColor: '#007BFF',
    color: 'white',
    padding: '10px 20px',
    margin: '10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };

  const buttonAddStyles = {
    backgroundColor: pairs.length >= MAX_PAIRS ? '#6c757d' : 'green',
    color: 'white',
    padding: '10px 20px',
    margin: '10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };

  const buttonCancelStyles = {
    backgroundColor: '#6c757d',
    color: 'white',
    padding: '10px 20px',
    margin: '10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };

  const previewStyles = {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    borderRadius: '5px',
    marginLeft: '10px',
  };

  return (
    <div style={modalStyles}>
      <div style={modalContentStyles}>
        <h2 style={{ color: 'red', marginBottom: '20px' }}>Add Video Ranked Request For Profile</h2>
        {loading && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
        <p style={{ marginBottom: '20px' }}>Select the time for Ranked</p>
        <TimePicker label="Days" value={days} onChange={setDays} />
        <TimePicker label="Hours" value={hours} onChange={setHours} />
        <TimePicker label="Minutes" value={minutes} onChange={setMinutes} />
        <TimePicker label="Seconds" value={seconds} onChange={setSeconds} />
        <input
          type="text"
          value={addlink}
          onChange={(e) => setAddlink(e.target.value)}
          placeholder="addlink"
          style={{ ...inputStyles, margin: '0 0 10px 0' }}
        />
        <input
          type="text"
          value={linktext}
          onChange={(e) => setLinktext(e.target.value)}
          placeholder="linktext"
          maxLength={15}
          style={{ ...inputStyles, margin: '0 0 10px 0' }}
        />
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          required
          style={{ ...inputStyles, margin: '0 0 10px 0' }}
        />
        <input
          type="number"
          value={rank}
          onChange={(e) => setRank(e.target.value)}
          placeholder="Rank"
          min="1"
          required
          style={{ ...inputStyles, margin: '0 0 10px 0' }}
        />
        {pairs.map((pair, index) => (
          <div key={index} style={inputContainerStyles}>
            <input
              type="text"
              value={pair.link}
              onChange={(e) => handlePairChange(index, 'link', e.target.value)}
              placeholder="Add Link"
              style={inputStyles}
            />
            <input
              type="file"
              onChange={(e) => handlePairChange(index, 'image', e.target.files[0])}
              style={inputStyles}
            />
            {pair.preview && <img src={pair.preview} alt="Preview" style={previewStyles} />}
            <button
              onClick={() => removePair(index)}
              style={{ ...buttonStyles, backgroundColor: '#dc3545', marginLeft: '10px' }}
            >
              Remove
            </button>
          </div>
        ))}
        <button
          onClick={addPair}
          style={buttonAddStyles}
          disabled={pairs.length >= MAX_PAIRS}
        >
          Add More
        </button>
        <button
          style={buttonStyles}
          onClick={handleAddranked}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056b3')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#007BFF')}
        >
          Add Ranked
        </button>
        <button
          style={buttonCancelStyles}
          onClick={onClose}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#5a6268')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#6c757d')}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

// const OpenCommeentModel = ({ onClose,loading,commentdata}) => {

//   const modalStyles = {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     position: 'fixed',
//     top: '0',
//     left: '0',
//     width: '100%',
//     height: '100%',
//     backgroundColor: 'rgba(0, 0, 0, 0.7)',
//     zIndex: '1000',
//   };

//   const modalContentStyles = {
//     backgroundColor: '#fff',
//     padding: '20px',
//     borderRadius: '10px',
//     width: '600px',
//     height: '600px',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     textAlign: 'center',
//     overflowY: 'auto',
//   };

//   const inputContainerStyles = {
//     display: 'flex',
//     alignItems: 'center',
//     marginBottom: '10px',
//   };

//   const inputStyles = {
//     width: 'calc(50% - 10px)',
//     padding: '10px',
//     margin: '0 5px',
//     borderRadius: '5px',
//     border: '1px solid #ccc',
//   };

//   const buttonStyles = {
//     backgroundColor: '#007BFF',
//     color: 'white',
//     padding: '10px 20px',
//     margin: '10px',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     transition: 'background-color 0.3s ease',
//   };


//   const buttonCancelStyles = {
//     backgroundColor: '#6c757d',
//     color: 'white',
//     padding: '10px 20px',
//     margin: '10px',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     transition: 'background-color 0.3s ease',
//   };

//   const previewStyles = {
//     width: '100px',
//     height: '100px',
//     objectFit: 'cover',
//     borderRadius: '5px',
//     marginLeft: '10px',
//   };

//   return (
//     <div style={modalStyles}>
//       <div style={modalContentStyles}>
//         <h2 style={{ color: 'red', marginBottom: '20px' }}>Comment Data</h2>

//       </div>
//     </div>
//   );
// };
// const OpenCommeentModel = ({ onClose, loading, commentdata }) => {
//   const [expandedReplies, setExpandedReplies] = useState({});
//   const [isEditModalOpen, setIsEditModalOpen] = useState(false);
//   const [editedText, setEditedText] = useState('');

//   const toggleReplies = (commentId) => {
//     setExpandedReplies((prev) => ({
//       ...prev,
//       [commentId]: !prev[commentId],
//     }));
//   };
// const openEditmodel=()=>{
//   setIsEditModalOpen(true)
// }
//   const modalStyles = {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     position: 'fixed',
//     top: '0',
//     left: '0',
//     width: '100%',
//     height: '100%',
//     backgroundColor: 'rgba(0, 0, 0, 0.7)',
//     zIndex: '1000',
//   };

//   const modalContentStyles = {
//     backgroundColor: '#fff',
//     padding: '20px',
//     borderRadius: '10px',
//     width: '700px',
//     height: 'auto',
//     maxHeight: '80vh',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     overflowY: 'auto',
//   };

//   const tableStyles = {
//     width: '100%',
//     borderCollapse: 'collapse',
//     marginBottom: '20px',
//   };

//   const thStyles = {
//     borderBottom: '1px solid #ddd',
//     padding: '10px',
//     fontWeight: 'bold',
//     backgroundColor: '#f7f7f7',
//     textAlign: 'left',
//   };

//   const tdStyles = {
//     padding: '10px',
//     borderBottom: '1px solid #ddd',
//     textAlign: 'left',

//   };

//   const buttonStyles = {
//     backgroundColor: '#007BFF',
//     color: 'white',
//     padding: '5px 10px',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     fontSize: '14px',
//     margin:'2px'
//   };
//   const buttonStylesreply = {
//     backgroundColor: 'red',
//     color: 'white',
//     padding: '5px 10px',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     fontSize: '14px',
//     margin:'2px'
//   };

//   const buttonCancelStyles = {
//     backgroundColor: '#6c757d',
//     color: 'white',
//     padding: '10px 20px',
//     margin: '10px',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     transition: 'background-color 0.3s ease',
//   };

//   // Styling for replies (color, padding, italics)
//   const replyStyles = {
//     backgroundColor: '#f1f1f1',  // Light gray background for replies
//     paddingLeft: '40px',         // Indent the reply to show it's a child of the comment
//     color: '#c0392b',            // Red color for reply text
//     fontStyle: 'italic',         // Italicize the reply message
//   };

//   return (
//     <div style={modalStyles}>
//       <div style={modalContentStyles}>
//         <h2 style={{ color: 'red', marginBottom: '20px',textAlign:'center' }}>Comment Data</h2>

//         <table style={tableStyles}>
//           <thead>
//             <tr>
//               <th style={thStyles}>ID</th>
//               <th style={thStyles}>Comment</th>
//               <th style={thStyles}>Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {/* Loop through the comments */}
//             {(Array.isArray(commentdata?.comments) ? commentdata?.comments : []).map((comment) => (
//               <React.Fragment key={comment.id}>
//                 {/* Main Comment Row */}
//                 <tr>
//                   <td style={tdStyles}>{comment.id}</td>
//                   <td style={tdStyles}>
//                     <strong>{comment.user?.username}</strong>: {comment.comment_data}
//                   </td>
//                   <td style={tdStyles}>
//                     <button style={buttonStyles} onClick={()=>{
// openEditmodel(comment.id)
//                     }}>Edit</button>
//                     <button style={buttonStyles}>Delete</button>

//                   </td>
//                 </tr>

//                 {/* Reply Comments Section */}
//                 {comment.replies &&
//                   comment.replies.slice(0, expandedReplies[comment.id] ? comment.replies.length : 3).map((reply) => (
//                     <tr key={reply.id}>
//                       <td style={{ ...tdStyles, paddingLeft: '30px', color: 'red' }}></td>
//                       <td style={{ ...tdStyles, ...replyStyles }}>
//                         <strong>{reply.user?.username}</strong> replied: {reply.reply_message}
//                       </td>
//                       <td style={tdStyles}>
//                         <button style={buttonStylesreply}>Edit</button>
//                         <button style={buttonStylesreply}>Delete</button>

//                       </td>
//                     </tr>
//                   ))}

//                 {/* "Show More" Button for Replies */}
//                 {comment.replies && comment.replies.length > 3 && (
//                   <tr>
//                     <td colSpan="3" style={{ textAlign: 'center', padding: '10px' }}>
//                       <button style={buttonStylesreply} onClick={() => toggleReplies(comment.id)}>
//                         {expandedReplies[comment.id] ? 'Show Less' : 'Show More'}
//                       </button>
//                     </td>
//                   </tr>
//                 )}
//               </React.Fragment>
//             ))}
//           </tbody>
//         </table>

//         {/* Close Button */}
//         <button style={buttonCancelStyles} onClick={onClose}>Close</button>
//       </div>
//       {
//         isEditModalOpen &&(
//           <div>
//              <div style={modalContentStyles}>
//           <h2>Edit Comment</h2>
//           <input 
//             type="text" 
//             value={editedText} 
//             onChange={(e) => setEditedText(e.target.value)} 
//             style={{ width: '100%', padding: '10px', marginBottom: '20px', borderRadius: '5px' }} 
//           />
//           <div>
//             <button style={buttonStyles} >Save</button>
//             <button style={buttonCancelStyles} onClick={()=>{
//               setIsEditModalOpen(false)
//             }}>Cancel</button>
//           </div>
//         </div>
//             </div>
//         )
//       }
//     </div>
//   );
// };

// const OpenCommeentModel = ({ onClose, loading, commentdata, HandleComment, setIsEditModalOpen, isEditModalOpen, HandleCommentDelet, isEditReplyCommentModalOpen, setisEditReplyCommentModalOpen, HandleReplyComment,HandleCommentReplyDelete }) => {
//   const [expandedReplies, setExpandedReplies] = useState({});
//   const [editedText, setEditedText] = useState('');
//   const [commentId, setCommentId] = useState(null);

//   const toggleReplies = (commentId) => {
//     setExpandedReplies((prev) => ({
//       ...prev,
//       [commentId]: !prev[commentId],
//     }));
//   };

//   const openEditModel = (id, commentData) => {
//     setCommentId(id);
//     setEditedText(commentData);
//     setIsEditModalOpen(true);
//   };

//   const HandleEditComment = () => {
//     let payload = {
//       id: commentId,
//       commentdata: editedText
//     }
//     HandleComment(payload)
//   }
//   const openReplyEditModel = (id, commentData) => {
//     setCommentId(id);
//     setEditedText(commentData);
//     setisEditReplyCommentModalOpen(true);
//   };
//   const ReplyComment = () => {
//     let payload = {
//       id: commentId,
//       commentdata: editedText
//     }
//     console.log(payload, 'payloadpayload reply')
//     HandleReplyComment(payload)
//   }
//   const HandleDelete = (id) => {
//     HandleCommentDelet(id)
//   }

//   const HandleClean=()=>{
//     setEditedText('')
//   }
//   const HandleReplyDelete=(id)=>{
//          HandleCommentReplyDelete(id)
//   }
//   const modalStyles = {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     position: 'fixed',
//     top: '0',
//     left: '0',
//     width: '100%',
//     height: '100%',
//     backgroundColor: 'rgba(0, 0, 0, 0.7)',
//     zIndex: '1000',
//   };

//   const modalContentStyles = {
//     backgroundColor: '#fff',
//     padding: '20px',
//     borderRadius: '10px',
//     width: '700px',
//     height: '80vh',
//     maxHeight: '80vh',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     overflow: 'hidden',
//     position: 'relative',
//   };

//   const headerStyles = {
//     color: 'red',
//     marginBottom: '20px',
//     textAlign: 'center',
//     position: 'sticky',
//     top: '0',
//     backgroundColor: '#fff',
//     padding: '10px 0',
//     zIndex: '1',
//   };

//   const scrollableContentStyles = {
//     maxHeight: 'calc(55vh - 20px)', // Adjust based on header height
//     overflowY: 'auto',
//   };
//   const tableStyles = {
//     width: '100%',
//     borderCollapse: 'collapse',
//     marginBottom: '20px',
//   };

//   const thStyles = {
//     borderBottom: '1px solid #ddd',
//     padding: '10px',
//     fontWeight: 'bold',
//     backgroundColor: '#f7f7f7',
//     textAlign: 'left',
//   };

//   const tdStyles = {
//     padding: '10px',
//     borderBottom: '1px solid #ddd',
//     textAlign: 'left',
//   };

//   const buttonStyles = {
//     backgroundColor: '#007BFF',
//     color: 'white',
//     padding: '5px 10px',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     fontSize: '14px',
//     margin: '2px',
//   };

//   const buttonStylesReply = {
//     backgroundColor: 'red',
//     color: 'white',
//     padding: '5px 10px',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     fontSize: '14px',
//     margin: '2px',
//   };

//   const buttonCancelStyles = {
//     backgroundColor: '#6c757d',
//     color: 'white',
//     padding: '10px 20px',
//     margin: '10px',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     transition: 'background-color 0.3s ease',
//   };

//   // Styling for replies (color, padding, italics)
//   const replyStyles = {
//     backgroundColor: '#f1f1f1',  // Light gray background for replies
//     paddingLeft: '40px',         // Indent the reply to show it's a child of the comment
//     color: '#c0392b',            // Red color for reply text
//     fontStyle: 'italic',         // Italicize the reply message
//   };

//   // Other styles remain the same...

//   return (
//     <div style={modalStyles}>
//       <div style={modalContentStyles}>
//         <h2 style={headerStyles}>Comment Data</h2>

//         <div style={scrollableContentStyles}>
//           <table style={tableStyles}>
//             <thead>
//               <tr>
//                 <th style={thStyles}>ID</th>
//                 <th style={thStyles}>Comment</th>
//                 <th style={thStyles}>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {Array.isArray(commentdata?.comments)
//                 ? commentdata?.comments.map((comment) => (
//                   <React.Fragment key={comment.id}>
//                     <tr>
//                       <td style={tdStyles}>{comment.id}</td>
//                       <td style={tdStyles}>
//                         <strong>{comment.user?.username}</strong>: {comment.comment_data}
//                       </td>
//                       <td style={tdStyles}>
//                         <button
//                           style={buttonStyles}
//                           onClick={() => openEditModel(comment.id, comment.comment_data)}
//                         >
//                           Edit
//                         </button>
//                         <button style={buttonStyles} onClick={() => {
//                           HandleDelete(comment.id)
//                         }}>Delete</button>
//                       </td>
//                     </tr>

//                     {comment.replies &&
//                       comment.replies
//                         .slice(0, expandedReplies[comment.id] ? comment.replies.length : 3)
//                         .map((reply) => (
//                           <tr key={reply.id}>
//                             <td style={{ ...tdStyles, paddingLeft: '30px', color: 'red' }}></td>
//                             <td style={{ ...tdStyles, ...replyStyles }}>
//                               <strong>{reply.user?.username}</strong> replied: {reply.reply_message}
//                             </td>
//                             <td style={tdStyles}>
//                               <button style={buttonStylesReply} onClick={() => openReplyEditModel(reply.id, reply.reply_message)}
//                               >Edit</button>
//                               <button style={buttonStylesReply} onClick={()=>HandleReplyDelete(reply.id)}>Delete</button>
//                             </td>
//                           </tr>
//                         ))}

//                     {comment.replies && comment.replies.length > 3 && (
//                       <tr>
//                         <td colSpan="3" style={{ textAlign: 'center', padding: '10px' }}>
//                           <button
//                             style={buttonStylesReply}
//                             onClick={() => toggleReplies(comment.id)}
//                           >
//                             {expandedReplies[comment.id] ? 'Show Less' : 'Show More'}
//                           </button>
//                         </td>
//                       </tr>
//                     )}
//                   </React.Fragment>
//                 ))
//                 : null}
//             </tbody>
//           </table>
//         </div>

//         <button style={buttonCancelStyles} onClick={onClose}>
//           Close
//         </button>
//       </div>

//       {isEditModalOpen && (
//         <div style={modalStyles}>
//           <div style={modalContentStyles}>
//             <h2>Edit Comment (ID: {commentId})</h2>

//             <textarea
//               value={editedText}
//               onChange={(e) => setEditedText(e.target.value)}
//               rows={4} // Number of visible rows by default
//               style={{
//                 width: '100%',
//                 padding: '10px',
//                 marginBottom: '20px',
//                 borderRadius: '5px',
//                 resize: 'vertical', // Allows users to manually adjust the height
//               }}
//             />

//             <div>
//               <button style={buttonStyles} onClick={() => { HandleEditComment() }}>Save</button>
//               <button style={{ ...buttonStyles, background: 'red' }} onClick={()=>{
//                  HandleClean()
//               }}>Clean</button>

//               <button
//                 style={{ ...buttonStyles, background: 'gray' }}
//                 onClick={() => {
//                   setIsEditModalOpen(false)
//                 }}
//               >
//                 Cancel
//               </button>
//             </div>
//           </div>
//         </div>
//       )}

//       {isEditReplyCommentModalOpen && (
//         <div style={modalStyles}>
//           <div style={modalContentStyles}>
//             <h2>Edit Comment (ID: {commentId})</h2>

//             <textarea
//               value={editedText}
//               onChange={(e) => setEditedText(e.target.value)}
//               rows={4} // Number of visible rows by default
//               style={{
//                 width: '100%',
//                 padding: '10px',
//                 marginBottom: '20px',
//                 borderRadius: '5px',
//                 resize: 'vertical', // Allows users to manually adjust the height
//               }}
//             />

//             <div>
//               <button style={buttonStyles} onClick={() => { ReplyComment() }}>Save</button>
//               <button style={{ ...buttonStyles, background: 'red' }} onClick={()=>{
//                 HandleClean()
//               }} >Clean</button>

//               <button
//                 style={{ ...buttonStyles, background: 'gray' }}
//                 onClick={() => {
//                   setisEditReplyCommentModalOpen(false)
//                 }}
//               >
//                 Cancel
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

const OpenCommeentModel = ({
  onClose,
  loading,
  commentdata,
  HandleComment,
  setIsEditModalOpen,
  isEditModalOpen,
  HandleCommentDelet,
  isEditReplyCommentModalOpen,
  setisEditReplyCommentModalOpen,
  HandleReplyComment,
  HandleCommentReplyDelete,
}) => {
  const [expandedReplies, setExpandedReplies] = useState({});
  const [editedText, setEditedText] = useState('');
  const [commentId, setCommentId] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const [currentPage, setCurrentPage] = useState(1);
  const commentsPerPage = 5; // Set comments per page
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const startDateObj = startDate ? new Date(startDate) : null;
  const endDateObj = endDate ? new Date(endDate) : null;
  const [pageRange, setPageRange] = useState([1, 2, 3, 4]); // Visible page range

  const toggleReplies = (commentId) => {
    setExpandedReplies((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };
console.log(commentdata,'commentdatacommentdata')
  const openEditModel = (id, commentData) => {
    setCommentId(id);
    setEditedText(commentData);
    setIsEditModalOpen(true);
  };

  const HandleEditComment = () => {
    const payload = {
      id: commentId,
      commentdata: editedText,
    };
    HandleComment(payload);
  };

  const openReplyEditModel = (id, commentData) => {
    setCommentId(id);
    setEditedText(commentData);
    setisEditReplyCommentModalOpen(true);
  };

  const ReplyComment = () => {
    const payload = {
      id: commentId,
      commentdata: editedText,
    };
    HandleReplyComment(payload);
  };

  const HandleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      HandleCommentDelet(id);
    }
    // HandleCommentDelet(id);
  };

  const HandleClean = () => {
    setEditedText('');
  };

  const HandleReplyDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      HandleCommentReplyDelete(id);
    }
    // HandleCommentReplyDelete(id);
  };


  const filteredComments = Array.isArray(commentdata?.comments)
  ? commentdata.comments.filter((comment) => {
      // Match the search term for comment or replies
      const commentMatches =
        comment.user?.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        comment.comment_data.toLowerCase().includes(searchTerm.toLowerCase());

      const replyMatches = comment.replies?.some((reply) =>
        reply.user?.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        reply.reply_message.toLowerCase().includes(searchTerm.toLowerCase())
      );

      // Convert comment's createdAt to a Date object
      const commentDate = new Date(comment.createdAt).getTime(); // Get timestamp in UTC
      
      // Debugging to see the comment date
      console.log(commentDate, 'commentDate');

      const isWithinDateRange = (
        (!startDateObj || new Date(commentDate).setHours(0, 0, 0, 0) >= new Date(startDateObj).setHours(0, 0, 0, 0)) &&
        (!endDateObj || new Date(commentDate).setHours(0, 0, 0, 0) <= new Date(endDateObj).setHours(0, 0, 0, 0))
      );
      
      // Return true if either the search term matches or the comment is within the date range
      return (commentMatches || replyMatches) && isWithinDateRange;
    })
  : [];


// Pagination logic
const totalPages = Math.ceil(filteredComments.length / commentsPerPage);
const currentComments = filteredComments.slice(
  (currentPage - 1) * commentsPerPage,
  currentPage * commentsPerPage
);

const handleSearchChange = (e) => {
  setSearchTerm(e.target.value);
  setCurrentPage(1); // Reset to first page on search
};

// const goToNextPage = () => {
//   if (currentPage < totalPages) setCurrentPage(currentPage + 1);
// };

// const goToPreviousPage = () => {
//   if (currentPage > 1) setCurrentPage(currentPage - 1);
// };

const handleDateChange = (date, type) => {
  if (type === 'start') {
    setStartDate(date);
  } else {
    setEndDate(date);
  }
};

const handleClean=()=>{
  setStartDate(null)
  setEndDate(null)
}


  // Dynamically update the initial page range based on totalPages
  useEffect(() => {
    const initialRange = [];
    const rangeSize = 4;
    const totalVisiblePages = Math.min(rangeSize, totalPages);

    for (let i = 1; i <= totalVisiblePages; i++) {
      initialRange.push(i);
    }
    setPageRange(initialRange);
  }, [totalPages]);

  const updatePageRange = (startPage) => {
    const rangeSize = 4; // Number of pages visible at a time
    const newRange = [];

    for (
      let i = startPage;
      i < startPage + rangeSize && i <= totalPages;
      i++
    ) {
      newRange.push(i);
    }

    setPageRange(newRange);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);

    // If the clicked page is the last in the current range, load the next range
    if (page === pageRange[pageRange.length - 1] && page < totalPages) {
      updatePageRange(page);
    }

    // If the clicked page is the first in the current range and not the first page
    if (page === pageRange[0] && page > 1) {
      updatePageRange(page - 3);
    }
  };

  const handleFirst = () => {
    setCurrentPage(1);
    updatePageRange(1);
  };

  const handleLast = () => {
    setCurrentPage(totalPages);
    const lastRangeStart = Math.max(
      1,
      Math.floor((totalPages - 1) / 4) * 4 + 1
    );
    updatePageRange(lastRangeStart);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      // Check if we are at the start of the current visible range
      if (currentPage === pageRange[0]) {
        // If we are at the first page of the range, move to the previous set of pages
        const previousRangeStart = Math.max(pageRange[0] - 4, 1); // Ensure start page is >= 1
        updatePageRange(previousRangeStart);
      } else {
        setCurrentPage(currentPage - 1);
      }
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      // If we are at the end of the current visible range, load the next range
      if (currentPage === pageRange[pageRange.length - 1] && currentPage < totalPages) {
        updatePageRange(pageRange[pageRange.length - 1]);
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const modalStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: '1000',
  };

  const modalContentStyles = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    width: '700px',
    height: '80vh',
    maxHeight: '80vh',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    position: 'relative',
  };

  const headerStyles = {
    color: 'red',
    marginBottom: '20px',
    textAlign: 'center',
    position: 'sticky',
    top: '0',
    backgroundColor: '#fff',
    padding: '10px 0',
    zIndex: '1',
  };

  const scrollableContentStyles = {
    maxHeight: 'calc(32vh - 7px)',
    overflowY: 'auto',
  };

  const tableStyles = {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
  };

  const thStyles = {
    borderBottom: '1px solid #ddd',
    padding: '10px',
    fontWeight: 'bold',
    backgroundColor: '#f7f7f7',
    textAlign: 'left',
  };

  const tdStyles = {
    padding: '10px',
    borderBottom: '1px solid #ddd',
    textAlign: 'left',
  };

  const buttonStyles = {
    backgroundColor: '#007BFF',
    color: 'white',
    padding: '5px 10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
    margin: '2px',
  };

  const buttonStylesReply = {
    backgroundColor: 'red',
    color: 'white',
    padding: '5px 10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '14px',
    margin: '2px',
  };

  const buttonCancelStyles = {
    backgroundColor: '#6c757d',
    color: 'white',
    padding: '10px 20px',
    margin: '10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };

  const replyStyles = {
    backgroundColor: '#f1f1f1',
    paddingLeft: '40px',
    color: '#c0392b',
    fontStyle: 'italic',
  };

  return (
    <div style={modalStyles}>
      <div style={modalContentStyles}>
        <h2 style={headerStyles}>Comment Data</h2>

        {/* Search input for filtering comments */}
        <input
          type="text"
          placeholder="Search by username"
          value={searchTerm}
          onChange={handleSearchChange}
          style={{
            width: '100%',
            padding: '10px',
            marginBottom: '20px',
            borderRadius: '5px',
            border: '1px solid #ddd',
          }}
        />
 {/* Date Pickers for Start and End Date */}
 <div style={{ marginBottom: '20px' }}>
        <DatePicker
          selected={startDate}
          onChange={(date) => handleDateChange(date, 'start')}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
          maxDate={new Date()} // Ensure start date is not in the future
          dateFormat="dd:MM:yyyy"
          style={{
            padding: '5px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            fontSize: '14px',
          }}
        />
        <span style={{ margin: '0 10px' }}>to</span>
        <DatePicker
          selected={endDate}
          onChange={(date) => handleDateChange(date, 'end')}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          placeholderText="End Date"
          minDate={startDate} // Ensure end date is not before start date
          maxDate={new Date()} // Disable selecting future dates
          dateFormat="dd:MM:yyyy"
          style={{
            padding: '5px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            fontSize: '14px',
          }}
        />
     
          <button
            style={buttonStyles}
            onClick={handleClean}
          >
            Clean
          </button>
      </div>

        <div style={scrollableContentStyles}>
          <table style={tableStyles}>
            <thead>
              <tr>
                <th style={thStyles}>ID</th>
                <th style={thStyles}>Comment</th>
                <th style={thStyles}>Date</th>

                <th style={thStyles}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentComments.map((comment) => (
                <React.Fragment key={comment.id}>
                  <tr>
                    <td style={tdStyles}>{comment.id}</td>
                    <td style={tdStyles}>
                      <strong>{comment.user?.username}</strong>: {comment.comment_data}
                    </td>
                    <td style={tdStyles}>
                    {new Date(comment.createdAt).toLocaleString()} {/* Format the date to the local string */}
                    </td>
                    <td style={tdStyles}>
                      <button
                        style={buttonStyles}
                        onClick={() => openEditModel(comment.id, comment.comment_data)}
                      >
                        Edit
                      </button>
                      <button style={buttonStyles} onClick={() => HandleDelete(comment.id)}>
                        Delete
                      </button>
                    </td>
                  </tr>

                  {comment.replies &&
                    comment.replies
                      .slice(0, expandedReplies[comment.id] ? comment.replies.length : 3)
                      .map((reply) => (
                        <tr key={reply.id}>
                          <td style={{ ...tdStyles, paddingLeft: '30px', color: 'red' }}></td>
                          <td style={{ ...tdStyles, ...replyStyles,

                           }} >
                            <strong>{reply.user?.username}</strong> replied: {reply.reply_message}
                          </td>

                          <td style={tdStyles}>
                    {new Date(reply.createdAt).toLocaleString()} {/* Format the date to the local string */}
                    </td>
                         
                          <td style={tdStyles}>
                            <button
                              style={buttonStylesReply}
                              onClick={() => openReplyEditModel(reply.id, reply.reply_message)}
                            >
                              Edit
                            </button>
                            <button style={buttonStylesReply} onClick={() => HandleReplyDelete(reply.id)}>
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}

                  {comment.replies && comment.replies.length > 3 && (
                    <tr>
                      <td colSpan="3" style={{ textAlign: 'center', padding: '10px' }}>
                        <button
                          style={buttonStylesReply}
                          onClick={() => toggleReplies(comment.id)}
                        >
                          {expandedReplies[comment.id] ? 'Show Less' : 'Show More'}
                        </button>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
      <button onClick={handleFirst} disabled={currentPage === 1}>
        First
      </button>
      <button onClick={handlePrevious} disabled={currentPage === 1}>
        Previous
      </button>
      {pageRange.map((page) => (
        <button
          key={page}
          onClick={() => handlePageClick(page)}
          style={{
            padding: "5px 10px",
            backgroundColor: currentPage === page ? "#007bff" : "#f8f9fa",
            color: currentPage === page ? "white" : "black",
            border: "1px solid #ddd",
            cursor: "pointer",
          }}
        >
          {page}
        </button>
      ))}
      <button onClick={handleNext} disabled={currentPage === totalPages}>
        Next
      </button>
      <button onClick={handleLast} disabled={currentPage === totalPages}>
        Last
      </button>
    </div>


        <button style={buttonCancelStyles} onClick={onClose}>
          Close
        </button>
      </div>

      {isEditModalOpen && (
        <div style={modalStyles}>
          <div style={modalContentStyles}>
            <h2>Edit Comment (ID: {commentId})</h2>

            <textarea
              value={editedText}
              onChange={(e) => setEditedText(e.target.value)}
              rows={4}
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '20px',
                borderRadius: '5px',
                resize: 'vertical',
              }}
            />

            <div>
              <button style={buttonStyles} onClick={HandleEditComment}>
                Save
              </button>
              <button style={{ ...buttonStyles, background: 'red' }} onClick={HandleClean}>
                Clean
              </button>
              <button
                style={{ ...buttonStyles, background: 'gray' }}
                onClick={() => setIsEditModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isEditReplyCommentModalOpen && (
        <div style={modalStyles}>
          <div style={modalContentStyles}>
            <h2>Edit Comment (ID: {commentId})</h2>

            <textarea
              value={editedText}
              onChange={(e) => setEditedText(e.target.value)}
              rows={4}
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '20px',
                borderRadius: '5px',
                resize: 'vertical',
              }}
            />

            <div>
              <button style={buttonStyles} onClick={ReplyComment}>
                Save
              </button>
              <button style={buttonStylesReply} onClick={HandleClean}>
                Clean
              </button>
              <button
                style={{ ...buttonStyles, background: 'gray' }}
                onClick={() => setisEditReplyCommentModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


