import React, { useEffect, useState } from 'react';
// Assuming allVideoApis is an instance of an API object
import IncludeSideBar from '../../../Components/Sidebar/IncludeSideBar'
import useAuth from "../../../useAuth";

function BlockedVideos() {
  const allVideoApis = require('../../../apis/all_user_video');
  const { user } = useAuth();
  const role = user ? user.role : null;
  console.log(user?.permissions?.Users?.['View Video'], "roooolllee user?.permissions?.Users")
  const hasUnblockVideo = user?.permissions?.Videos?.['Unblock Video']?.selected || user?.role === 'superadmin';

  const [blockedVideos, setBlockedVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('')
  const [inputPageNumber, setInputPageNumber] = useState(''); // State to hold the input page number
  const [inputFocus, setInputFocus] = useState(false);
  const [buttonFocus, setButtonFocus] = useState(false);

  const getAllBlockedVideo = async (page, searchTerm = '') => {
    try {
      const response = await allVideoApis.getBlockedUserVideo(page, searchTerm);
      console.log(response.data); // Check the response structure and ensure it's an array of objects

      setBlockedVideos(response.data); // Store the fetched data in state
      setTotalPages(response.pagination.totalPages);

      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error("Error fetching Blocked Videos:", error);
      setLoading(false); // Handle loading state on error as well
    }
  };

  // useEffect(() => {
  //   getAllBlockedVideo();
  // }, []);

  useEffect(() => {
    getAllBlockedVideo(currentPage, searchTerm);
}, [currentPage, searchTerm]);

const handleSearch = (searchValue) => {
    setSearchTerm(searchValue);
};


const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
};

const handleNext = () => {
    if (currentPage < totalPages) {
        // setCurrentPage((prevPage) => prevPage + 1);
        setCurrentPage(currentPage + 1);
    }
};

const handlePrevious = () => {
    if (currentPage > 1) {
        // setCurrentPage((prevPage) => prevPage - 1);
        setCurrentPage(currentPage - 1);
    }
};
const handleGoToPage = () => {
    const pageNumber = parseInt(inputPageNumber, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
        setInputPageNumber('')
    } else {
        alert(`Please enter a valid page number between 1 and ${totalPages}`);
    }
};


  const handleUnblock = async (videoId) => {
    try {
      // Make an API call to update the status of the video to unblocked
      const response = await allVideoApis.updateBlockedVideoStatus(videoId);

      // Update the state to reflect the change in the UI
      const updatedVideos = blockedVideos.map(video => {
        if (video.id === videoId) {
          return { ...video, blocked: false };
        }
        return video;
      });
      // setBlockedVideos(blockedVideos.filter(video => video.video_id !== videoId));

      if (response.message === 'Video unblocked successfully') {
        alert('Successfully unblocked this Video');

      }
      setBlockedVideos(updatedVideos);
        getAllBlockedVideo(1,'');
    } catch (error) {
      console.error(`Error unblocking video with ID ${videoId}:`, error);
    }
  };

  const handleNoAccess = () => {
    alert('You are not able to access this feature.');
};



  return (
    <IncludeSideBar>
      <div>
        <p>Here we are displaying the Blocked videos details</p>
        <input
          type="text"
          placeholder="Search by UserName"
          value={searchTerm}
        onChange={(e) => handleSearch(e.target.value)}
        />
        {loading ? (
          <p>Loading...</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>USER_ID</th>
                <th>VIDEO Thum</th>
                <th>USERNAME</th>

                <th>Action</th>
                {/* Add more table headers for other video attributes */}
              </tr>
            </thead>
            <tbody>
              {blockedVideos.map((video, index) => (
                <tr key={index}>
                  <td>{video.id}</td>
                  <td>{video.user_id}</td>

                  <td> <img
                    src={`https://dpcst9y3un003.cloudfront.net/${video.thum}`}
                    alt="Thumbnail"

                    style={{ cursor: 'pointer', width: '50px', height: '50px' }}
                  /></td>
                  {/* <td><img
                      //   src={`https://dpcst9y3un003.cloudfront.net/${user.profile_pic}`}
                      src={video.profile_pic}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "20%",
                      }}
                    /></td> */}
                  <td>{video.user.username
                  }</td>
                  <td>
                    <button onClick={() => {
                      if(hasUnblockVideo){
                        handleUnblock(video.id)
                      }else{
                        handleNoAccess()
                      }
                    }}
                    style={{
                      backgroundColor:
                          !hasUnblockVideo
                              ? '#C5C6C7'
                              : '', // Set red background when there is access
                      cursor: !hasUnblockVideo ? 'not-allowed' : 'pointer', // Changes cursor when disabled
                  }}
                    >
                      Unblock
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
                <div className="pagination">
                    <button onClick={handlePrevious}>Previous</button>
                    {Array.from({ length: Math.min(totalPages, 20) }, (_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => handlePageChange(index + 1)}
                            className={currentPage === index + 1 ? "active" : ""}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button onClick={handleNext}>Next</button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
                    <input
                        type="number"
                        value={inputPageNumber}
                        onChange={(e) => setInputPageNumber(e.target.value)}
                        placeholder="Page No"
                        style={{
                            padding: '5px',
                            fontSize: '14px',
                            // border: '1px solid #ccc',
                            border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,

                            borderRadius: '4px 0 0 4px',
                            outline: 'none',
                            width: '90px'
                        }}
                        onFocus={() => setInputFocus(true)}
                        onBlur={() => setInputFocus(false)}
                    />
                    <button
                        onClick={handleGoToPage}
                        style={{
                            padding: '6.5px 10px',
                            fontSize: '14px',
                            border: '1px solid #ccc',
                            borderLeft: 'none',
                            borderRadius: '0 4px 4px 0',
                            // backgroundColor: '#007bff',
                            
                        backgroundColor: `${buttonFocus ? 'green' : '#007bff'}`,
                        color: 'white',
                    cursor: 'pointer',
                    outline: 'none',
                        }}

                   
                    >
                    Go
                </button>
            </div>

      </div>
    </IncludeSideBar>
  );
}

export default BlockedVideos;
