import { SERVER_API_URL, SERVER_DOMAIN } from "../constants/constants";
import axios from "axios";

const getAllUserVideo = async (page, searchTerm = '') => {
  const getToken = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  // const url = `${SERVER_API_URL}/admin/userallvideo/getAllUserVideos/${page}/${perPage}`;
  const url = `${SERVER_API_URL}/admin/userallvideo/getAllUserVideos?page=${page}&search=${searchTerm}`;

  const result = await axios.get(url, config);
  // console.log(result)
  return result.data;
};

const deleteVideos = async (id) => {
  console.log(id, "IDFROMAPI")
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/userallvideo/deleteVideos/${id}`;

  try {
    const result = await axios.delete(url, config);
    return result.data;
  } catch (error) {
    throw error; // Propagate the error for handling in your component
  }
};


const updateLikeVideos = async (id, like) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/userallvideo/updateLikeVideos/${id}`;

  try {
    // Send the like value as part of the request body
    const data = { like };
    console.log(data, "data")

    const result = await axios.patch(url, data, config);
    return result.data;
  } catch (error) {
    throw error; // Propagate the error for handling in your component
  }
};



const updateDiamondVideos = async (id, diamond_value) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/userallvideo/updateVideoDiamond/${id}`;

  try {
    // Send the Diamond_value value as part of the request body
    const data = { diamond_value };
    console.log(data, "data")

    const result = await axios.patch(url, data, config);
    return result.data;
  } catch (error) {
    throw error; // Propagate the error for handling in your component
  }
};



const updateVideoShared = async (id, shared) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/userallvideo/updateVideoShare/${id}`;

  try {
    // Send the Diamond_value value as part of the request body
    const data = { shared };
    console.log(data, "data")

    const result = await axios.patch(url, data, config);
    return result.data;
  } catch (error) {
    throw error; // Propagate the error for handling in your component
  }
};
const updateVideoCount = async (id, viewCount) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/userallvideo/updateVideoCount/${id}`;

  try {
    // Send the Diamond_value value as part of the request body
    const data = { viewCount };
    console.log(data, "data")

    const result = await axios.patch(url, data, config);
    return result.data;
  } catch (error) {
    throw error; // Propagate the error for handling in your component
  }
};



const blockVideo = async (id) => {
  console.log(id)
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/userallvideo/BlockVideo/${id}`;

  try {
    const result = await axios.patch(url, null, config);

    return result.data;
  } catch (error) {
    throw error; // Propagate the error for handling in your component
  }
};

const UnblockVideo = async (id) => {
  console.log(id)
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/userallvideo/updateUnBlockVideo/${id}`;

  try {
    const result = await axios.patch(url, null, config);

    return result.data;
  } catch (error) {
    throw error; // Propagate the error for handling in your component
  }
};

const getBlockedUserVideo = async (page,searchTerm = '') => {
  const getToken = localStorage.getItem("token");
console.log(page,'pagepagepage')
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/userallvideo/getBlockedVideo?page=${page}&search=${searchTerm}`;
  const result = await axios.get(url, config);
  // console.log(result)
  return result.data;
};






const updateBlockedVideoStatus = async (videoId) => {
  const getToken = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
const data={
   blocked: false 
}
  const url = `${SERVER_API_URL}/admin/userallvideo/UnblockVideoStatus/${videoId}`;

  try {
    const result = await axios.patch(url,data , config);
    return result.data; // Return response data (if needed)
  } catch (error) {
    // Handle error or throw it for the caller to handle
    throw error;
  }
};

const deleteVideoViewCount = async (id, viewCount) => {
  const getToken = localStorage.getItem("token");
  console.log(getToken);
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/userallvideo/deleteVideoCount/${id}?viewCount=${viewCount}`;

  try {
    const result = await axios.delete(url, config);
    console.log(result);
    return result.data;
  } catch (error) {
    throw error;
  }
};
const deleteVideolikeCount=async(id, likeCount)=>{
  const getToken = localStorage.getItem("token");
  console.log(getToken);
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/userallvideo/deleteVideolikeCount/${id}?likeCount=${likeCount}`;

  try {
    const result = await axios.delete(url, config);
    console.log(result);
    return result.data;
  } catch (error) {
    throw error;
  }
}
const deleteVideoshareCount=async(id, shareCount)=>{
  const getToken = localStorage.getItem("token");
  console.log(getToken);
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/userallvideo/deleteshareVideoCount/${id}?shareCount=${shareCount}`;

  try {
    const result = await axios.delete(url, config);
    console.log(result);
    return result.data;
  } catch (error) {
    throw error;
  }
}

const VideoDeletionRequest=async(data)=>{
  // console.log(data,'data')
  const getToken=localStorage.getItem("token");
  const config={
    headers:{Authorization:`Bearer ${getToken}`}
  }
  const url= `${SERVER_API_URL}/admin/userallvideo/videodeletionrequestfromadmin`
  const result=await axios.post(url,data,config);
  return result.data

}

const getdeletionrequestdata=async(page,searchTerm = '')=>{
  console.log(page,'searchTerm')
  const getToken=localStorage.getItem("token");
  const config={
    headers:{Authorization:`Bearer ${getToken}`}
  }
  // const url = `${SERVER_API_URL}/admin/userallvideo/getvideoDeletionRequestFromAdmin`;
  const url = `${SERVER_API_URL}/admin/userallvideo/getvideoDeletionRequestFromAdmin?page=${page}&search=${searchTerm}`;

  const result=await axios.get(url,config);
  return result.data
}

const Videodeltionrequest=async(video_id)=>{
  console.log(video_id,'video_id')
  const getToken=localStorage.getItem("token");
  const config={
    headers:{Authorization:`Bearer ${getToken}`}
  }
  const url= `${SERVER_API_URL}/admin/userallvideo/videoDeletionrequst/${video_id}`
  const result=await axios.delete(url,config);
  return result.data
}

const updateVideocomment=async(id,comment)=>{
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/userallvideo/updateVideocommentcount/${id}`;

  try {
    // Send the Diamond_value value as part of the request body
    const data = { comment :comment};

    const result = await axios.patch(url, data, config);
    return result.data;
  } catch (error) {
    throw error; // Propagate the error for handling in your component
  }
}
const deleteVideocomment=async(id,deletecommentcount)=>{
  const getToken = localStorage.getItem("token");
  console.log(getToken);
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/userallvideo/deletecomentVideoCount/${id}?deletecount=${deletecommentcount}`;

  try {
    const result = await axios.delete(url, config);
    console.log(result);
    return result.data;
  } catch (error) {
    throw error;
  }
}
const addrankedvideo=async(formdata)=>{
  console.log(formdata,'formdataformdata')
  const getToken=localStorage.getItem("token");
  const config={
    headers:{Authorization:`Bearer ${getToken}`},

  }
  const url= `${SERVER_API_URL}/admin/userallvideo/addvideoranked`
  const result=await axios.post(url,formdata,config);
  return result.data
}

const addprofilerankedvideo=async(formdata)=>{
  const getToken=localStorage.getItem("token");
  const config={
    headers:{Authorization:`Bearer ${getToken}`},

  }
  const url= `${SERVER_API_URL}/admin/userallvideo/addprofilevideoranked`
  const result=await axios.post(url,formdata,config);
  return result.data

}
const GetAllCommentByVideoId=async(id)=>{
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/userallvideo/viewallcommentbyid/${id}`;
  const result = await axios.get(url, config);
  // console.log(result)
  return result.data;
}
const EditComment=async(data)=>{
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/userallvideo/editcomment`;

  try {

    const result = await axios.put(url, data, config);
    return result.data;
  } catch (error) {
    throw error; // Propagate the error for handling in your component
  }
}
const DeleteComment=async(id)=>{
  const getToken=localStorage.getItem("token");
  const config={
    headers:{Authorization:`Bearer ${getToken}`},

  }
  const url= `${SERVER_API_URL}/admin/userallvideo/deletecomment/${id}`
  const result=await axios.delete(url,config);
  return result.data

}
const EditReplyComment=async(data)=>{
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/userallvideo/editreplycomment`;

  try {

    const result = await axios.put(url, data, config);
    return result.data;
  } catch (error) {
    throw error; // Propagate the error for handling in your component
  }
}

const DeleteReplyComment=async(id)=>{
  const getToken=localStorage.getItem("token");
  const config={
    headers:{Authorization:`Bearer ${getToken}`},

  }
  const url= `${SERVER_API_URL}/admin/userallvideo/deletereplycomment/${id}`
  const result=await axios.delete(url,config);
  return result.data
}
export {
  getAllUserVideo,
  deleteVideos,
  updateLikeVideos,
  blockVideo,
  UnblockVideo,
  getBlockedUserVideo,
  updateBlockedVideoStatus,
  updateDiamondVideos,
  updateVideoShared,
  updateVideoCount,
  deleteVideoViewCount,
  VideoDeletionRequest,
  getdeletionrequestdata,
  Videodeltionrequest,
  deleteVideoshareCount,
  deleteVideolikeCount,
  updateVideocomment,
  deleteVideocomment,
  addrankedvideo,
  addprofilerankedvideo,
  GetAllCommentByVideoId,
  EditComment,
  DeleteComment,
  EditReplyComment,
  DeleteReplyComment
}