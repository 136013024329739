import { SERVER_API_URL, SERVER_DOMAIN } from "../constants/constants";
import axios from "axios";

// const getRoseTransaction = async () => {
//   const getToken = localStorage.getItem("token");
//   // console.log(page,perPage,getToken)
//   const config = {
//     headers: { Authorization: `Bearer ${getToken}` },
//   };
//   const url = `${SERVER_API_URL}/admin/DiamondTransaction/getRoseTransaction`;
//   const result = await axios.get(url, config);
//   return result;
// };

const getRoseTransaction = async (filters) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };

  // Extract parameters from filters object
  const { page, searchTerm = '', startDate = null, endDate = null } = filters;

  // Build the base URL with page and searchTerm
  let url = `${SERVER_API_URL}/admin/DiamondTransaction/getRoseTransaction?page=${page}&search=${searchTerm}`;

  // Append startDate and endDate if they exist
  if (startDate) {
    url += `&startDate=${startDate}`;
  }
  if (endDate) {
    url += `&endDate=${endDate}`;
  }

  // Make the API request
  const result = await axios.get(url, config);
  return result;
};



// const getMessageSubscriptionTransaction = async () => {
//   const getToken = localStorage.getItem("token");
//   // console.log(page,perPage,getToken)
//   const config = {
//     headers: { Authorization: `Bearer ${getToken}` },
//   };
//   const url = `${SERVER_API_URL}/admin/DiamondTransaction/getMessageSubscriptionTransaction`;
//   const result = await axios.get(url, config);
//   return result;
// };


const getMessageSubscriptionTransaction = async (filters) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };

  // Extract parameters from filters object
  const { page, searchTerm = '', startDate = null, endDate = null } = filters;

  // Build the base URL with page and searchTerm
  let url = `${SERVER_API_URL}/admin/DiamondTransaction/getMessageSubscriptionTransaction?page=${page}&search=${searchTerm}`;

  // Append startDate and endDate if they exist
  if (startDate) {
    url += `&startDate=${startDate}`;
  }
  if (endDate) {
    url += `&endDate=${endDate}`;
  }

  // Make the API request
  const result = await axios.get(url, config);
  return result;
};


const getVideoGiftTransaction = async (filters) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };

  // Extract parameters from filters object
  const { page, searchTerm = '', startDate = null, endDate = null } = filters;

  // Build the base URL with page and searchTerm
  let url = `${SERVER_API_URL}/admin/DiamondTransaction/getVideoGiftTransaction?page=${page}&search=${searchTerm}`;

  // Append startDate and endDate if they exist
  if (startDate) {
    url += `&startDate=${startDate}`;
  }
  if (endDate) {
    url += `&endDate=${endDate}`;
  }

  // Make the API request
  const result = await axios.get(url, config);
  return result;
};




const getAdminTransation=async(filters)=>{
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };

  // Extract parameters from filters object
  const { page, searchTerm = '', startDate = null, endDate = null } = filters;

  // Build the base URL with page and searchTerm
  let url = `${SERVER_API_URL}/admin/DiamondTransaction/getAdminToUserTransation?page=${page}&search=${searchTerm}`;

  // Append startDate and endDate if they exist
  if (startDate) {
    url += `&startDate=${startDate}`;
  }
  if (endDate) {
    url += `&endDate=${endDate}`;
  }

  // Make the API request
  const result = await axios.get(url, config);
  return result;
}



export { getRoseTransaction,getMessageSubscriptionTransaction,getVideoGiftTransaction,getAdminTransation};
