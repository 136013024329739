import { SERVER_API_URL, SERVER_DOMAIN } from "../constants/constants";
import axios from "axios";

const GetAllFeed = async (page,searchTerm = '') => {
  const getToken = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/feed/getallfeed?page=${page}&search=${searchTerm}`;


  const result = await axios.get(url, config);
  // console.log(result)
  return result.data;
};


const GetAllfeedCommentById=async(id)=>{
  const getToken = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/feed/getallfeedcommentbyid/${id}`;


  const result = await axios.get(url, config);
  // console.log(result)
  return result.data;
}


const EditFeedcomment=async(data)=>{
  const getToken = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/feed/editfeedcomment`;


  const result = await axios.put(url, data,config);
  // console.log(result)
  return result.data;
}

const DeleteFeedComment=async(id)=>{
  const getToken=localStorage.getItem("token");
  const config={
    headers:{Authorization:`Bearer ${getToken}`},

  }
  const url= `${SERVER_API_URL}/admin/feed/deletefeedcomment/${id}`
  const result=await axios.delete(url,config);
  return result.data
}
const FetchUserInfo=async(username)=>{
  const getToken=localStorage.getItem("token");
  const config={
    headers:{Authorization:`Bearer ${getToken}`},

  }

  const url= `${SERVER_API_URL}/admin/feed/featchuser?username=${username}`
  const result=await axios.get(url,config);
  return result.data
}


const GetMutedUser = async (filters) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };

  console.log(filters, "filtersfilters");

  // Extract parameters from filters object
  const { page, searchTerm = '', startDate = null, endDate = null } = filters;

  // Build the base URL with page and searchTerm
  let url = `${SERVER_API_URL}/admin/feed/getmutedusersdata?page=${page}&search=${searchTerm}`;

  // Append startDate and endDate if they exist
  if (startDate) {
    url += `&startDate=${(startDate)}`;
  }
  if (endDate) {
    url += `&endDate=${(endDate)}`;
  }

  try {
    // Make the API request
    const result = await axios.get(url, config);
    return result.data;
  } catch (error) {
    console.error("Error fetching muted users:", error);
    throw error;
  }
};



const UnMuteComment=async(id)=>{
  const getToken = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/feed/unmutecomment/${id}`;


  const result = await axios.delete(url, config);
  // console.log(result)
  return result.data;
}

export {
    GetAllFeed,
    GetAllfeedCommentById,
    EditFeedcomment,
    DeleteFeedComment,
    FetchUserInfo,
    GetMutedUser,
    UnMuteComment
}