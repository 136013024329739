import React, { useEffect, useState } from "react";
import VideoModal from './VideoModal';
import PhotoModal from './PhotoModal';
import './AllUser.css'
import IncludeSideBar from '../../../Components/Sidebar/IncludeSideBar'
import useAuth from '../../../useAuth'


const AllUser = () => {
  const allUserApis = require("../../../apis/users");
  const { user } = useAuth()
  const role = user ? user.role : null;
  console.log(user?.permissions?.Users?.['View Video']?.subfeatures?.Like, "roooolllee user?.permissions?.Users")
  const hasViewAccess = user?.permissions?.Users?.['View Video']?.selected || user?.role === 'superadmin';
  const hasBlockUserAccess = user?.permissions?.Users?.['Block User']?.selected || user?.role === 'superadmin';
  const hasDelete = user?.permissions?.Users?.Delete?.selected || user?.role === 'superadmin';
  const hasAddfollow = user?.permissions?.Users?.['Add follow']?.selected || user?.role === 'superadmin';
  const hasUnfollow = user?.permissions?.Users?.Unfollow?.selected || user?.role === 'superadmin';
  const hasUsageinfo = user?.permissions?.Users?.['Usage info']?.selected || user?.role === 'superadmin';
  //  const hassubfeturelike=user?.permissions?.Users?.['View Video']?.subfeatures?.Like || user?.role === 'superadmin';
  //  const hassubfeturecomment=user?.permissions?.Users?.['View Video']?.subfeatures?.Comment || user?.role === 'superadmin';

  // console.log(hasDelete, 'hasDelete', hasBlockUserAccess, 'hasBlockUserAccess')
  const [userData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [videoData, setVideoData] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [interactionData, setInteractionData] = useState([]);
  const [interactionTime1Day, setInteractionTime1Day] = useState(0);
  const [interactionTime15Days, setInteractionTime15Days] = useState(0);
  const [interactionTime1Month, setInteractionTime1Month] = useState(0);
  const [interactionTime3Month, setInteractionTime3Month] = useState(0);
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false);
  const [photoData, setPhotoData] = useState(null);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [userid, setuserid] = useState(null)

  const [likedata, setlikedata] = useState([])
  const [commentdata, setcommentdata] = useState([])
  const [diamonddata, setdiamonddata] = useState([])
  const [sharedata, setsharedata] = useState([])
  const [interactionType, setInteractionType] = useState(null);
  const [senddiamonddata, setsenddiamonddata] = useState([])
  const [sendlikedata, setsendlikedata] = useState([])
  const [recivedcommentdata, setrecivedcommentdata] = useState([])
  const [recivesharedata, setrecivesharedata] = useState([])
  const [pageWindow, setPageWindow] = useState({ start: 1, end: 20 });


  const [inputPageNumber, setInputPageNumber] = useState(''); // State to hold the input page number
  const [inputFocus, setInputFocus] = useState(false);
  const [buttonFocus, setButtonFocus] = useState(false);

  // for user model delete
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  //for block model

  const [isblockModalOpen, setblockIsModalOpen] = useState(false);
  const [selectedblockUserId, setSelectedblockUserId] = useState(null);
  //active button
  const [clickedButton, setClickedButton] = useState(null);


  const getUsers = async (page, searchTerm = '') => {
    try {
      setLoading(true);
      const response = await allUserApis.getAllUsers(page, searchTerm);
      setUserData(response.users);
      console.log(response.users.user_interactions, 'user_interactions.users')
      setTotalPages(response.pagination.totalPages);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers(currentPage, searchTerm);
  }, [currentPage, searchTerm]);

  const handleSearch = (searchValue) => {
    setSearchTerm(searchValue);
  };


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setPageWindow({
        start: Math.floor((currentPage - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((currentPage - 1) / 20) * 20 + 20, totalPages),
      });
    }

  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      // setCurrentPage((prevPage) => prevPage - 1);
      setCurrentPage(currentPage - 1);
      setPageWindow({
        start: Math.floor((currentPage - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((currentPage - 1) / 20) * 20 + 20, totalPages),
      });

    }
  };

  const handleNextPageWindow = () => {
    if (pageWindow.end < totalPages) {
      setPageWindow({
        start: pageWindow.start + 19,
        end: Math.min(pageWindow.end + 19, totalPages),
      });
    }
  };

  const handlePreviousPageWindow = () => {
    if (pageWindow.start > 1) {
      setPageWindow({
        start: pageWindow.start - 20,
        end: pageWindow.end - 20,
      });
    }
  };

  const handlePageClick = (page) => {
    handlePageChange(page);
    if (page === pageWindow.end && pageWindow.end < totalPages) {
      handleNextPageWindow();
    } else if (page === pageWindow.start && pageWindow.start > 1) {
      handlePreviousPageWindow();
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    setPageWindow({
      start: Math.floor((totalPages - 1) / 20) * 20 + 1,
      end: totalPages,
    });
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    setPageWindow({
      start: 1,
      end: Math.min(20, totalPages),
    });
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(inputPageNumber, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setPageWindow({
        start: Math.floor((pageNumber - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((pageNumber - 1) / 20) * 20 + 20, totalPages),
      });
      setInputPageNumber('');
    } else {
      alert(`Please enter a valid page number between 1 and ${totalPages}`);
    }
  };

  const handleViewVideo = async (userId) => {
    try {
      setLoading(true);

      const response = await allUserApis.getUserVideos(userId);
      setVideoData(response);
      setShowVideoModal(true);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewPhoto = async (userId) => {
    try {
      setLoading(true);
      const response = await allUserApis.getAllUsersPost(userId);
      console.log(response, "responsefrom alluser")
      setPhotoData(response.photos);
      setShowPhotoModal(true);
    } catch (error) {
      console.error("Error fetching user photos:", error);
    } finally {
      setLoading(false);
    }
  };

  // console.log(photoData, 'photoDataphotoData')
  const handleClosePhotoModal = () => {
    setShowPhotoModal(false);
    setPhotoData(null);

  };



  const handleViewUsage = (userId) => {
    console.log(userId, 'handleUsage')
    console.log(interactionData, 'interactionData')
    const user = userData.find((user) => user.id === userId);
    setuserid(userId)
    if (user && user.user_interactions) {
      const currentTime = new Date();
      setInteractionData(user.user_interactions);
      const interactionTimes = calculateInteractionTimes(user.user_interactions, currentTime);
      setInteractionTime1Day(interactionTimes.interactionTime1Day);
      setInteractionTime15Days(interactionTimes.interactionTime15Days);
      setInteractionTime1Month(interactionTimes.interactionTime1Month);
      setInteractionTime3Month(interactionTimes.interactionTime3Month);

    }
    const element = document.getElementById('user-interaction-details');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleCloseUsageDetails = () => {
    // Scroll back to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Clear the interaction data and close the section
    setInteractionData([]);
    setInteractionTime1Day(0);
    setInteractionTime15Days(0);
    setInteractionTime1Month(0);
    setInteractionTime3Month(0);
  };

  // Calculate interaction times for different durations
  const calculateInteractionTimes = (interactionData, currentTime) => {
    const oneDayAgo = new Date(currentTime);
    oneDayAgo.setDate(currentTime.getDate() - 1);
    const fifteenDaysAgo = new Date(currentTime);
    fifteenDaysAgo.setDate(currentTime.getDate() - 15);
    const oneMonthAgo = new Date(currentTime);
    oneMonthAgo.setMonth(currentTime.getMonth() - 1);
    const threeMonthsAgo = new Date(currentTime);
    threeMonthsAgo.setMonth(currentTime.getMonth() - 3);

    const interactionTime1Day = calculateInteractionTime(interactionData, oneDayAgo);
    const interactionTime15Days = calculateInteractionTime(interactionData, fifteenDaysAgo);
    const interactionTime1Month = calculateInteractionTime(interactionData, oneMonthAgo);
    const interactionTime3Month = calculateInteractionTime(interactionData, threeMonthsAgo);


    return {
      interactionTime1Day,
      interactionTime15Days,
      interactionTime1Month,
      interactionTime3Month
    };
  };

  // Calculate total interaction time in human-readable format
  const calculateInteractionTime = (interactionData, startDate) => {
    const interactionsWithinRange = interactionData.filter((interaction) => {
      const interactionStart = new Date(interaction.interaction_start);
      return interactionStart >= startDate;
    });

    const totalInteractionTimeInMilliseconds = interactionsWithinRange.reduce(
      (total, interaction) => total + interaction.interacted_time,
      0
    );

    const totalSeconds = Math.floor(totalInteractionTimeInMilliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours} hours ${minutes} minutes ${seconds} seconds`;
  };

  // function for handling the active user to unactive 

  // const handleBlockUser = async (userId) => {


  //   // Ask for confirmation before blocking the user
  //   const confirmed = window.confirm("Are you sure you want to block this user?");

  //   if (confirmed) {
  //     const updatedUserData = userData.map(user => {
  //       if (user.id === userId && user.active === 1) {
  //         return { ...user, active: 0 };
  //       }
  //       return user;
  //     });

  //     // Update local state with the updated user data
  //     setUserData(updatedUserData);

  //     try {
  //       console.log('Calling updateResourceActiveStatus with role:', role);
  //       const response = await allUserApis.updateResourceActiveStatus(userId, 0,role);
  //       console.log('User status updated successfully:', response);
  //       // Perform any necessary action after a successful update on the server
  //     } catch (error) {
  //       console.error('Error updating user status:', error);

  //     }
  //   } else {

  //     console.log('Block action canceled by the user.');
  //     return;
  //   }
  // };

  const handleBlockUser = async (userId, totalSeconds, reason) => {
    console.log(userId, totalSeconds, reason, 'userId,totalSeconds,reason')
    const updatedUserData = userData.map(user => {
      if (user.id === userId && user.active === 1) {
        return { ...user, active: 0 };
      }
      return user;
    });

    setUserData(updatedUserData);

    try {
      console.log('Calling updateResourceActiveStatus with role:', role);
      const response = await allUserApis.updateResourceActiveStatus(userId, 0, role, totalSeconds, reason);
      console.log('User status updated successfully:', response);
      // Perform any necessary action after a successful update on the server
    } catch (error) {
      console.error('Error updating user status:', error);
    }
  };

  const openblockModal = (userId) => {
    setSelectedblockUserId(userId);
    setblockIsModalOpen(true);
  };

  const closeblockModal = () => {
    setblockIsModalOpen(false);
    setSelectedblockUserId(null);
  };

  const handleDeleteUser = async (userId, days) => {
    try {
      const data = {
        userId: userId,
        // deleteAfter:'1'
        deleteAfter: days.toString()

      }
      setLoading(true);
      const response = await allUserApis.AccountDeletionRequest(data);
      console.log(response, "response")

      if (response.success === true) {
        alert('Successfully initiated account deletion request');

      } else if (response.message == 'all ready exit') {
        alert('Account deletion request already exists for this user')
      }
    } catch (error) {
      console.error("Error fetching user photos:", error);
    } finally {
      setLoading(false);
    }
  }


  const openModal = (userId) => {
    console.log(userId, 'userId open model')
    setSelectedUserId(userId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUserId(null);
  };

  const handleDelete = (days) => {
    handleDeleteUser(selectedUserId, days);
    closeModal();
  };





  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
    setVideoData(null);
  };

  const updateVideoData = (updatedData) => {
    setVideoData(updatedData);
  };




  const tdStyle = {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'center'
  };

  const tdEmailStyle = {
    ...tdStyle,
    width: '150px', // Set the desired width for the email cell
  };

  // *********** letter use this code

  //Add follower
  const [CurrentfolloweruserIndex, setCurrentfolloweruserIndex] = useState(null)
  const [ShowfollowerModal, setShowfollowerModal] = useState(false)
  const [CurrentunfolloweruserIndex, setCurrentunfolloweruserIndex] = useState(null)
  const [ShowunfollowerModal, setShowunfollowerModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const openAddfollowerModal = (index) => {
    setCurrentfolloweruserIndex(index);
    setShowfollowerModal(true);
  }
  const closeaddfollowerModal = () => {
    setShowfollowerModal(false)
    setShowunfollowerModal(false)
  }
  const handleaddfollower = async (addfollower) => {
    if (addfollower !== null) {
      try {
        setIsLoading(true);
        await allUserApis.updateuserfollower(CurrentfolloweruserIndex, addfollower);
        getUsers(currentPage, '')

        setIsLoading(false);

        alert(`Add ${addfollower} Follower succsefully  `);

        setShowfollowerModal(false)
      } catch (error) {
        setIsLoading(false);
        console.error('Error updating shared count:', error);
      }
    }
  };
  //unfollow
  const openunfollowerModal = (index) => {
    setCurrentunfolloweruserIndex(index);
    setShowunfollowerModal(true);
  }

  const handleunfollow = async (unfollow) => {

    if (unfollow !== null) {
      try {
        setIsLoading(true);

        // Simulating an API call to update the shared count (replace this with the actual API call)
        await allUserApis.updateuserunfollow(CurrentunfolloweruserIndex, unfollow);
        getUsers(currentPage, searchTerm)

        setIsLoading(false);

        alert(`${unfollow} Unfollow succsefully  `);

        setShowunfollowerModal(false)
      } catch (error) {
        setIsLoading(false);
        console.error('Error updating shared count:', error);
      }
    }
  }

  // intraction code
  const [isIntractionModalOpen, setisIntractionModalOpen] = useState(false);
  const [interactionInfo, setInteractionInfo] = useState(null);
  const [interationuserid, setinterationuserid] = useState('')
  const handleuserintract = async (userid) => {
    setisIntractionModalOpen(true)
    setIsLoading(true);
    setinterationuserid(userid)
    try {
      console.log(userid, 'useriduserid')
      const response = await allUserApis.userIntraction(userid);
      console.log(response, 'responseresponse')
      setInteractionInfo(response?.userInteractionInfo);

      setIsLoading(false);


    } catch (error) {
      setIsLoading(false);
      console.error('Error updating ', error);
    }
  }

  const fetchInteractionData = async (type) => {
    setIsLoading(true);

    try {
      try {
        setLoading(true);

        if (type === 'like') {
          const response = await allUserApis.getlikeintraction(interationuserid);
          // setlikedata(response.payload);
          setlikedata(response?.receivedLikesInfo)

        }
        else if (type === 'sendLike') {
          const response = await allUserApis.getsendlikeintraction(interationuserid)
          setsendlikedata(response.sendLikesInfo)
          console.log(response.sendLikesInfo, 'response.sendLikesInfo')


        }
        else if (type === 'postComment') {
          const response = await allUserApis.getcommentintraction(interationuserid);
          setcommentdata(response.totalReplyCommentsInfo);
          console.log(response, 'response comment')

        }
        else if (type === 'receivedpostComment') {
          const response = await allUserApis.getrecivedcommetintraction(interationuserid)
          // console.log(response.payload,'recived comment')
          setrecivedcommentdata(response.totalReceivedCommentsInfo)

        }
        else if (type === 'giftDiamond') {
          const response = await allUserApis.getdiamondintraction(interationuserid)
          //       // console.log(response,'responsediamond')
          setdiamonddata(response.totalGiftDiamondsInfo)

        }
        else if (type === 'postCommentRoseDiamond') {
          const response = await allUserApis.getPostCommentRoseDiamond(interationuserid)
          //       // console.log(response,'responsediamond')
          setdiamonddata(response.totalPostCommentRoseDiamondsInfo)

        }
        else if (type === 'replyCommentRoseDiamond') {
          const response = await allUserApis.getReplyPostCommentRoseDiamond(interationuserid)
          //       // console.log(response,'responsediamond')
          setdiamonddata(response.totalReplycommentRoseDiamondsInfo)

        }
        else if (type === 'adminDiamond') {
          const response = await allUserApis.getAdminDiamond(interationuserid)
          //       // console.log(response,'responsediamond')
          setdiamonddata(response.totalDiamondfromAdmin)

        }
        else if (type === 'sendgiftDiamond') {
          const response = await allUserApis.getSendGiftDiamond(interationuserid)
          console.log(response, 'totalSendGiftDiamondsInfo')
          setsenddiamonddata(response.totalSendGiftDiamondsInfo)

        }
        else if (type === 'sendpostCommentRoseDiamond') {
          const response = await allUserApis.getSendCommentRoseDiamond(interationuserid)
          console.log(response, 'totalSendGiftDiamondsInfo')
          setsenddiamonddata(response.totalSendCommentRoseDiamondsInfo)

        }
        else if (type === 'sendreplyCommentRoseDiamond') {
          const response = await allUserApis.getSendReplyCommentRoseDiamond(interationuserid)
          // console.log(response, 'totalSendGiftDiamondsInfo')
          setsenddiamonddata(response.totalSendReplycommentRoseDiamondsInfo)

        }
        else if (type === 'share') {
          const response = await allUserApis.getshareintraction(interationuserid)
          setsharedata(response.totalSendShareInfo)

        }
        else if (type === 'receivedShare') {
          const response = await allUserApis.getrecivedshare(interationuserid)
          setsharedata(response.totalRecivedShareInfo)

        }
        else if (type === 'commentreply') {
          const response = await allUserApis.getcommentReplyintraction(interationuserid);
          setcommentdata(response.totalReplyCommentInfo);
          console.log(response, 'response comment')

        }
        else if (type === 'recivedcommentreply') {
          const response = await allUserApis.getRecivedCommentReplyintraction(interationuserid);
          setrecivedcommentdata(response.totalReceivedreplyCommentsInfo);
          console.log(response, 'response comment')
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }

    } catch (error) {
      setIsLoading(false);
      console.error('Error updating ', error);
    }
  }

  //pagination

  const handleButtonClick = (userId, buttonType) => {
    setClickedButton({ userId, buttonType }); // Set the clicked button
  };

  const handleNoAccess = () => {
    alert('You are not able to access this feature.');
  };


// *******************Mute Comment************
const [CommentMuteModelOpen,setCommentMuteModelOpen]=useState(false)
const [SelectedCommentMuteUser,setSelectedCommentMuteUser]=useState(null)
const openMuteCommentModal = (userId) => {
  setSelectedCommentMuteUser(userId);
  setCommentMuteModelOpen(true);
};

const closeMuteCommentModal = () => {
  setCommentMuteModelOpen(false);
  setSelectedCommentMuteUser(null);

};

const handleCommentMuteUser=async(userId,totalSeconds, reason)=>{
  console.log(userId, totalSeconds, reason, 'userId,totalSeconds,reason')
 


  try {
    console.log('Calling updateResourceActiveStatus with role:', role);
    const response = await allUserApis.MuteUserComment(userId,  totalSeconds, reason);
    // console.log('User status updated successfully:', response);
    // Perform any necessary action after a successful update on the server
  } catch (error) {
    console.error('Error updating user status:', error);
  }
}

  return (
    <IncludeSideBar>
      <div>

        <input
          type="text"
          placeholder="Search by username"
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
        />
        {loading && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
        {isModalOpen && <DeleteModal onClose={closeModal} onDelete={handleDelete} />}

        {isblockModalOpen && (
          <BlockModal
            onClose={closeblockModal}
            onBlock={(totalSeconds, reason) => handleBlockUser(selectedblockUserId, totalSeconds, reason)}
          />
        )}
        <table>

          <thead>
            <tr>
              <th>ID</th>
              <th>Profile Pic</th>
              <th>Username</th>
              <th>Account Type</th>
              <th>Nickname</th>
              <th>Email</th>
              <th>followers</th>

              <th>Gender</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {userData.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>
                  <img
                    //   src={`https://dpcst9y3un003.cloudfront.net/${user.profile_pic}`}
                    src={user.profile_pic}

                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "20%",
                    }}
                  />
                </td>
                <td>{user.username}</td>
                <td>{user.account_type}</td>
                <td>{user.nickname}</td>
                <td style={tdEmailStyle}>{user.email}</td>
                <td style={tdEmailStyle}>{user.followersCount}</td>

                <td>{user.gender}</td>
                <td>
                  {user.active === 0 ? "Blocked" : (
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <button
                        onClick={() => {
                          if (hasViewAccess) {
                            handleViewVideo(user.id);
                            handleButtonClick(user.id, 'viewVideo');
                          } else {
                            handleNoAccess();
                          }
                        }}
                        style={{
                          backgroundColor:
                            clickedButton?.userId === user.id &&
                              clickedButton?.buttonType === 'viewVideo'
                              ? 'red'
                              : !hasViewAccess
                                ? '#C5C6C7'
                                : '',
                          cursor: !hasViewAccess ? 'not-allowed' : 'pointer', // Changes cursor when disabled
                        }}
                      // disabled={!hasViewAccess} // Disables button if no access
                      >
                        View Video
                      </button>
                      {/* <button onClick={() => handleViewUsage(user.id)}>Usage</button> */}

                      <button onClick={() => {
                        if (hasBlockUserAccess) {
                          openblockModal(user.id);
                          handleButtonClick(user.id, 'blockUser');
                        } else {
                          handleNoAccess();

                        }
                      }}
                        style={{
                          backgroundColor:
                            clickedButton?.userId === user.id &&
                              clickedButton?.buttonType === 'blockUser'
                              ? 'red'
                              : !hasBlockUserAccess
                                ? '#C5C6C7'
                                : '',
                          cursor: !hasBlockUserAccess ? 'not-allowed' : 'pointer', // Changes cursor when disabled

                        }}
                      >Block User</button>

                      {/* <button
                        onClick={() => { handleViewPhoto(user.id); handleButtonClick(user.id, 'photo') }}
                        style={{
                          backgroundColor:
                            clickedButton?.userId === user.id &&
                              clickedButton?.buttonType === 'photo'
                              ? 'red'
                              : '',
                        }}

                      >Photo</button> */}

                      <button onClick={() => {
                        if (hasDelete) {
                          openModal(user.id)
                        } else {
                          handleNoAccess();

                        }

                      }}
                        style={{
                          backgroundColor:
                            clickedButton?.userId === user.id &&
                              clickedButton?.buttonType === 'Delete'
                              ? 'red'
                              : !hasDelete
                                ? '#C5C6C7'
                                : '',
                          cursor: !hasDelete ? 'not-allowed' : 'pointer', // Changes cursor when disabled

                        }}
                      >

                        Delete </button>

                      <button onClick={() => {
                        if (hasAddfollow) {
                          openAddfollowerModal(user.id);
                          handleButtonClick(user.id, 'AddFollow');
                        } else {
                          handleNoAccess();
                        }
                      }}
                        style={{
                          backgroundColor:
                            clickedButton?.userId === user.id &&
                              clickedButton?.buttonType === 'AddFollow'
                              ? 'red'
                              : !hasAddfollow
                                ? '#C5C6C7'
                                : '',
                          cursor: !hasAddfollow ? 'not-allowed' : 'pointer',

                        }}
                      >
                        Add Follow </button>
                      <button onClick={() => {
                        if (hasUnfollow) {
                          openunfollowerModal(user.id);
                          handleButtonClick(user.id, 'UnFollow')
                        } else {
                          handleNoAccess();

                        }
                      }}
                        style={{
                          backgroundColor:
                            clickedButton?.userId === user.id &&
                              clickedButton?.buttonType === 'UnFollow'
                              ? 'red'

                              : !hasUnfollow
                                ? '#C5C6C7'
                                : '',
                          cursor: !hasUnfollow ? 'not-allowed' : 'pointer',
                        }}
                      >UnFollow</button>
                      <button onClick={() => {
                        if (hasUsageinfo) {
                          handleuserintract(user.id);
                          handleButtonClick(user.id, 'UsageInfo')
                        } else {
                          handleNoAccess();

                        }
                      }}
                        style={{
                          backgroundColor:
                            clickedButton?.userId === user.id &&
                              clickedButton?.buttonType === 'UsageInfo'
                              ? 'red'
                              : !hasUsageinfo
                                ? '#C5C6C7'
                                : '',
                          cursor: !hasUsageinfo ? 'not-allowed' : 'pointer',
                        }}
                      >Usage Info</button>


                      <button onClick={()=>{openMuteCommentModal(user.id)}}
                      >Mute Comment</button>

                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          {/* <button onClick={() => setInteractionType('like')}>Recived Like</button>
          <button onClick={() => setInteractionType('Send Like')}>Send Like</button>

          <button onClick={() => setInteractionType('comment')}> Reply Comment</button>
          <button onClick={() => setInteractionType('recived comment')}>Recived Comment</button>
          <button onClick={() => setInteractionType('Diamond')}>Recived Diamond</button>
          <button onClick={() => setInteractionType('Send Diamond')}>Send Diamond</button>
          <button onClick={() => setInteractionType('Share')}>Send Share</button>
          <button onClick={() => setInteractionType('recive share')}>Recived Share</button> */}
        </div>

        {interactionData.length > 0 && (
          <div id="user-interaction-details">
            <button onClick={handleCloseUsageDetails} style={{ float: 'right', marginBottom: '10px' }}>
              Close
            </button>

            <h2>User Usage</h2>
            {/* <div>
              <button onClick={getlikedata}>Like</button>
              <button> Comments</button>
              <button>Share</button>

              </div> */}
            <table>
              <thead>
                <tr>
                  <th>Time Range</th>
                  <th>Interaction Time</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <td>Last 1 Day</td>
                  <td>{interactionTime1Day} seconds</td>
                </tr> */}
                <tr>
                  <td>Last 15 Days</td>
                  <td>{interactionTime15Days} seconds</td>
                </tr>
                <tr>
                  <td>Last 1 Month</td>
                  <td>{interactionTime1Month} seconds</td>
                </tr>
                <tr>
                  <td>Last 3 Month</td>
                  <td>{interactionTime3Month} seconds</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}


        {/* //like intraction data  */}


        {/* {likedata.ninty_days > 0 && interactionType === 'like' && (
          <div id="user-interaction-details">
            <button onClick={handleCloseUsageDetails} style={{ float: 'right', marginBottom: '10px' }}>
              Close
            </button>

            <h2>User Usage</h2>
            <div>
              <button onClick={getlikedata}>Like</button>
              <button> Comments</button>
              <button>Share</button>

              </div>
            <table>
              <thead>
                <tr>
                  <th>Time Range</th>
                  <th>Number Of Like During Interaction Time</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Last 1 Day</td>
                  <td>{interactionTime1Day} seconds</td>
                </tr>
                <tr>
                  <td>Last 15 Days</td>
                  <td>{likedata.fifteen_days} Like</td>
                </tr>
                <tr>
                  <td>Last 1 Month</td>
                  <td>{likedata.thirty_days} Like</td>
                </tr>
                <tr>
                  <td>Last 3 Month</td>
                  <td>{likedata.ninty_days} Like</td>
                </tr>
              </tbody>
            </table>
          </div>
        )} */}

        {/* {
          sendlikedata.ninty_days > 0 && interactionType === 'Send Like' && (
            <div id="user-interaction-details">
              <button onClick={handleCloseUsageDetails} style={{ float: 'right', marginBottom: '10px' }}>
                Close
              </button>

              <h2>User Usages</h2>
              <table>
                <thead>
                  <th>Time Range</th>
                  <th>Number Of Send Like During Interaction Time</th>

                </thead>
                <tbody>
                  <tr>
                    <td>Last 15 Days</td>
                    <td>{sendlikedata.fifteen_days} like Sended by User </td>
                  </tr>
                  <tr>
                    <td>Last 1 Month</td>
                    <td>{sendlikedata.thirty_days} like Sended by User</td>
                  </tr>
                  <tr>
                    <td>Last 3 Month</td>
                    <td>{sendlikedata.ninty_days} like Sended by User</td>
                  </tr>

                </tbody>
              </table>
            </div>
          )
        } */}


        {/* {commentdata.ninty_days > 0 && interactionType === 'comment' && (
          <div id="user-interaction-details">
            <button onClick={handleCloseUsageDetails} style={{ float: 'right', marginBottom: '10px' }}>
              Close
            </button>

            <h2>User Usage</h2>
            <div>
              <button onClick={getlikedata}>Like</button>
              <button> Comments</button>
              <button>Share</button>

              </div>
            <table>
              <thead>
                <tr>
                  <th>Time Range</th>
                  <th>Number Of Comments During Interaction Time</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Last 1 Day</td>
                  <td>{interactionTime1Day} seconds</td>
                </tr>
                <tr>
                  <td>Last 15 Days</td>
                  <td>{commentdata.fifteen_days} Comments</td>
                </tr>
                <tr>
                  <td>Last 1 Month</td>
                  <td>{commentdata.thirty_days} Comments</td>
                </tr>
                <tr>
                  <td>Last 3 Month</td>
                  <td>{commentdata.ninty_days} Comments</td>
                </tr>
              </tbody>
            </table>
          </div>
        )} */}


        {/* {
          recivedcommentdata.ninty_days > 0 && interactionType === 'recived comment' && (
            <div id="user-interaction-details">
              <h2>User Usage</h2>
              <table>
                <thead>
                  <tr>
                    <th>Time Range</th>
                    <th>Number Of Recived Comment During Interaction Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  <td>Last 1 Day</td>
                  <td>{interactionTime1Day} seconds</td>
                </tr>
                  <tr>
                    <td>Last 15 Days</td>
                    <td>{recivedcommentdata.fifteen_days} comment</td>
                  </tr>
                  <tr>
                    <td>Last 1 Month</td>
                    <td>{recivedcommentdata.thirty_days} comment</td>
                  </tr>
                  <tr>
                    <td>Last 3 Month</td>
                    <td>{recivedcommentdata.ninty_days} comment</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )
        } */}

        {/* {diamonddata.ninty_days > 0 && interactionType === 'Diamond' && (
          <div id="user-interaction-details">
            <h2>User Usage</h2>

            <table>
              <thead>
                <tr>
                  <th>Time Range</th>
                  <th>Number Of Diamond During Interaction Time</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Last 1 Day</td>
                  <td>{interactionTime1Day} seconds</td>
                </tr>
                <tr>
                  <td>Last 15 Days</td>
                  <td>{diamonddata.fifteen_days} Diamond</td>
                </tr>
                <tr>
                  <td>Last 1 Month</td>
                  <td>{diamonddata.thirty_days} Diamond</td>
                </tr>
                <tr>
                  <td>Last 3 Month</td>
                  <td>{diamonddata.ninty_days} Diamond</td>
                </tr>
              </tbody>
            </table>

          </div>
        )

        }{
          senddiamonddata.ninty_days > 0 && interactionType === 'Send Diamond' && (
            <div id="user-interaction-details">
              <h2>User Usage</h2>

              <table>
                <thead>
                  <tr>
                    <th>Time Range</th>
                    <th>Number Of Diamond During Interaction Time</th>
                  </tr>
                </thead>
                <tbody>

                  <tr>
                    <td>Last 15 Days</td>
                    <td>{senddiamonddata.fifteen_days} Sended Diamond</td>
                  </tr>
                  <tr>
                    <td>Last 1 Month</td>
                    <td>{senddiamonddata.thirty_days} Sended Diamond</td>
                  </tr>
                  <tr>
                    <td>Last 3 Month</td>
                    <td>{senddiamonddata.ninty_days}  Sended Diamond</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )
        }
        {
          sharedata.ninty_days > 0 && interactionType === 'Share' && (
            <div id="user-interaction-details">
              <h2>User Usage</h2>
              <table>
                <thead>
                  <tr>
                    <th>Time Range</th>
                    <th>Number Of Share During Interaction Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Last 15 Days</td>
                    <td>{sharedata.fifteen_days} Share</td>
                  </tr>
                  <tr>
                    <td>Last 1 Month</td>
                    <td>{sharedata.thirty_days} share</td>
                  </tr>
                  <tr>
                    <td>Last 3 Month</td>
                    <td>{sharedata.ninty_days} share</td>
                  </tr>

                </tbody>
              </table>
            </div>
          )
        }
        {
          recivesharedata.ninty_days > 0 && interactionType === 'recive share' && (
            <div id="user-interaction-details">
              <h2>User Usage</h2>
              <table>
                <thead>
                  <tr>
                    <th>Time Range</th>
                    <th>Number Of Recived Share During Interaction Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Last 15 Days</td>
                    <td>{recivesharedata.fifteen_days} Share</td>
                  </tr>
                  <tr>
                    <td>Last 1 Month</td>
                    <td>{recivesharedata.thirty_days} share</td>
                  </tr>
                  <tr>
                    <td>Last 3 Month</td>
                    <td>{recivesharedata.ninty_days} share</td>
                  </tr>

                </tbody>
              </table>

            </div>
          )
        } */}

        <div className="pagination">
          <button onClick={handleFirstPage} disabled={currentPage === 1}>
            First
          </button>
          <button onClick={handlePrevious} disabled={currentPage === 1}>
            Previous
          </button>
          {Array.from(
            { length: pageWindow.end - pageWindow.start + 1 },
            (_, index) => pageWindow.start + index
          ).map((page) => (
            <button
              key={page}
              onClick={() => handlePageClick(page)}
              className={currentPage === page ? 'active' : ''}
              style={{
                backgroundColor: currentPage === page ? 'red' : '',
                color: 'white',
              }}
            >
              {page}
            </button>
          ))}
          <button onClick={handleNext} disabled={currentPage === totalPages}>
            Next
          </button>
          <button onClick={handleLastPage} disabled={currentPage === totalPages}>
            Last
          </button>
        </div>



        <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
          <input
            type="number"
            value={inputPageNumber}
            onChange={(e) => setInputPageNumber(e.target.value)}
            placeholder="Page No"
            style={{
              padding: '5px',
              fontSize: '14px',
              // border: '1px solid #ccc',
              border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,

              borderRadius: '4px 0 0 4px',
              outline: 'none',
              width: '90px'
            }}
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
          />
          <button
            onClick={handleGoToPage}
            style={{
              padding: '6.5px 10px',
              fontSize: '14px',
              border: '1px solid #ccc',
              borderLeft: 'none',
              borderRadius: '0 4px 4px 0',
              // backgroundColor: '#007bff',

              backgroundColor: `${buttonFocus ? 'green' : '#007bff'}`,
              color: 'white',
              cursor: 'pointer',
              outline: 'none',
            }}


          >
            Go
          </button>
        </div>

      </div>
      <Addfollowers

        showaddfollowerModal={ShowfollowerModal}
        closeaddfollowerModal={closeaddfollowerModal}
        handleaddfollower={handleaddfollower}
        loading={isLoading}

      />
      <Unfollow
        showunfollowModal={ShowunfollowerModal}
        closeunfollowModal={closeaddfollowerModal}
        handleunfollow={handleunfollow}
        loading={isLoading}

      />
      <VideoModal
        showVideoModal={showVideoModal}
        handleCloseVideoModal={handleCloseVideoModal}
        videoData={videoData}
        setVideoData={updateVideoData}
        handleViewVideo={handleViewVideo}
      />
      <PhotoModal
        showPhotoModal={showPhotoModal}
        handleClosePhotoModal={handleClosePhotoModal}
        photoData={photoData}
      />
      <InteractionModal
        showModal={isIntractionModalOpen}
        closeModal={() => setisIntractionModalOpen(false)}
        interactionInfo={interactionInfo}
        loading={loading}
        fetchInteractionData={fetchInteractionData}
        likedata={likedata}
        sendlikedata={sendlikedata}
        commentdata={commentdata}
        recivedcommentdata={recivedcommentdata}
        diamonddata={diamonddata}
        senddiamonddata={senddiamonddata}
        sharedata={sharedata}
      />
      {CommentMuteModelOpen && (
          <MuteCommentModal
            onClose={closeMuteCommentModal}
            onMute={(totalSeconds, reason) => handleCommentMuteUser(SelectedCommentMuteUser, totalSeconds, reason)}
          />
        )}
    </IncludeSideBar>
  );
};


export default AllUser;

//  model for account deletion request

const DeleteModal = ({ onClose, onDelete }) => {
  const modalStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)'
  };

  const modalContentStyles = {
    background: 'white',
    padding: '20px',
    borderRadius: '5px',
    textAlign: 'center'
  };

  const buttonStyles = {
    margin: '5px'
  };

  const handleDelete = (days) => {
    onClose();
    // Close the modal before showing the confirmation dialog
    const message = days === '2min'
      ? 'Are you sure you want to delete the account in 2 minutes?'
      : `Are you sure you want to delete the account in ${days} days?`;
    if (window.confirm(message)) {
      onDelete(days);
    }
  };

  return (
    <div style={modalStyles}>
      <div style={modalContentStyles}>
        <h2>Deletion Account Request</h2>
        <p>Select the number of days After the account is deleted:</p>
        <button style={buttonStyles} onClick={() => handleDelete('2min')}>Now</button>

        <button style={buttonStyles} onClick={() => handleDelete(1)}>1 Day</button>
        <button style={buttonStyles} onClick={() => handleDelete(7)}>7 Days</button>
        <button style={buttonStyles} onClick={() => handleDelete(15)}>15 Days</button>
        <button style={buttonStyles} onClick={() => handleDelete(30)}>30 Days</button>
        <button style={buttonStyles} onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};



//user block model
const TimePicker = ({ label, value, onChange }) => (
  <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <label style={{ flex: '1', textAlign: 'left' }}>{label}: </label>
    <input
      type="number"
      value={value}
      onChange={(e) => onChange(parseInt(e.target.value))}
      style={{
        width: '70px',
        padding: '10px',
        margin: '0 10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        textAlign: 'center',
      }}
      min="0"
    />
  </div>
);
const BlockModal = ({ onClose, onBlock }) => {
  const [reason, setReason] = useState('');
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [days, setDays] = useState(0);

  const handleBlock = () => {
    onClose();
    // const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    const totalSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;

    if (window.confirm(`Are you sure you want to block the user for ${hours} hours, ${minutes} minutes, and ${seconds} seconds?`)) {
      onBlock(totalSeconds, reason);
    }
  };

  const modalStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: '1000',
  };

  const modalContentStyles = {
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '10px',
    width: '400px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  };

  const textareaStyles = {
    width: '100%',
    padding: '15px',
    margin: '15px 0',
    borderRadius: '5px',
    border: '1px solid #ccc',
    resize: 'none',
  };

  const buttonStyles = {
    backgroundColor: '#007BFF',
    color: 'white',
    padding: '12px 25px',
    margin: '10px 5px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };

  const buttonCancelStyles = {
    backgroundColor: '#6c757d',
    color: 'white',
    padding: '12px 25px',
    margin: '10px 5px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };

  return (
    <div style={modalStyles}>
      <div style={modalContentStyles}>
        <h2 style={{ color: 'red', marginBottom: '20px' }}>User Block Request</h2>
        <p style={{ marginBottom: '20px' }}>Select the time after which the user will be blocked:</p>
        <TimePicker label="Days" value={days} onChange={setDays} />

        <TimePicker label="Hours" value={hours} onChange={setHours} />
        <TimePicker label="Minutes" value={minutes} onChange={setMinutes} />
        <TimePicker label="Seconds" value={seconds} onChange={setSeconds} />
        <textarea
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Reason for Blocking"
          rows="3"
          style={textareaStyles}
        />
        <button
          style={{ ...buttonStyles, backgroundColor: '#007BFF' }}
          onClick={handleBlock}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056b3')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#007BFF')}
        >
          Block User
        </button>
        <button
          style={{ ...buttonCancelStyles, backgroundColor: '#6c757d' }}
          onClick={onClose}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#5a6268')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#6c757d')}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};


//add followers

const Addfollowers = ({ showaddfollowerModal, closeaddfollowerModal, handleaddfollower, loading }) => {
  const [addfollower, setaddfollower] = useState(0);

  const handleSubmit = () => {
    handleaddfollower(addfollower);
    setaddfollower(0)
  };

  const modalStyle = {
    display: showaddfollowerModal ? 'block' : 'none',
    position: 'fixed',
    zIndex: 1,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    height: '400px',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.8)',
    borderRadius: '10px',
    padding: '20px'
  };

  const modalContentStyle = {
    backgroundColor: '#fefefe',
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    padding: '20px',
    boxSizing: 'border-box',
    textAlign: 'center'
  };

  const closeButtonStyle = {
    color: '#aaa',
    float: 'right',
    fontSize: '28px',
    fontWeight: 'bold',
    cursor: 'pointer'
  };

  const inputStyle = {
    width: '80%',
    padding: '10px',
    margin: '10px auto',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box'
  };

  const buttonStyle = {
    padding: '8px 12px',
    margin: '10px 8px',
    background: 'blue',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer'
  };

  return (
    <div className="modal" style={modalStyle}>
      <div className="modal-content" style={modalContentStyle}>
        <span className="close" style={closeButtonStyle} onClick={closeaddfollowerModal}>&times;</span>
        <h2>Add Follower</h2>
        {loading && (
          <div className="loader-container">
            <div className="loader"> </div>
          </div>
        )}
        <input
          type="number"
          value={addfollower}
          onChange={(e) => setaddfollower(e.target.value)}
          style={inputStyle}
        />
        <button style={buttonStyle} onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

//unfollow

const Unfollow = ({ showunfollowModal, closeunfollowModal, handleunfollow, loading }) => {
  const [unfollow, setunfollow] = useState(0);

  const handleSubmit = () => {
    handleunfollow(unfollow);
    setunfollow(0)
  };

  const modalStyle = {
    display: showunfollowModal ? 'block' : 'none',
    position: 'fixed',
    zIndex: 1,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    height: '400px',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.8)',
    borderRadius: '10px',
    padding: '20px'
  };

  const modalContentStyle = {
    backgroundColor: '#fefefe',
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    padding: '20px',
    boxSizing: 'border-box',
    textAlign: 'center'
  };

  const closeButtonStyle = {
    color: '#aaa',
    float: 'right',
    fontSize: '28px',
    fontWeight: 'bold',
    cursor: 'pointer'
  };

  const inputStyle = {
    width: '80%',
    padding: '10px',
    margin: '10px auto',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box'
  };

  const buttonStyle = {
    padding: '8px 12px',
    margin: '10px 8px',
    background: 'blue',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer'
  };

  return (
    <div className="modal" style={modalStyle}>
      <div className="modal-content" style={modalContentStyle}>
        <span className="close" style={closeButtonStyle} onClick={closeunfollowModal}>&times;</span>
        <h2>UnFollow</h2>
        {loading && (
          <div className="loader-container">
            <div className="loader"> </div>
          </div>
        )}
        <input
          type="number"
          value={unfollow}
          onChange={(e) => setunfollow(e.target.value)}
          style={inputStyle}
        />
        <button style={buttonStyle} onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};



// **** user Intraction info model

const InteractionModal = ({ showModal, closeModal, interactionInfo, loading, fetchInteractionData, likedata, sendlikedata, commentdata, recivedcommentdata, diamonddata, senddiamonddata, sharedata }) => {
  // By default, 'userInteraction' is selected
  const [interactionType, setInteractionType] = useState('userInteraction');
  const [diamondType, setDiamondType] = useState(null); // New state to handle diamond type

  useEffect(() => {
    // Set default to 'userInteraction' when modal opens
    setInteractionType('userInteraction');
    // fetchInteractionData('userInteraction');
    setDiamondType(null); // Reset diamond type when modal opens

  }, [showModal]);

  const handleInteractionTypeChange = (type) => {
    setInteractionType(type);
    // Call the API function passed from the parent component
    setDiamondType(null); // Reset diamond type when interaction type changes

    fetchInteractionData(type);

    console.log(type, 'typetypetype')
  };

  const handleDiamondTypeChange = (type) => {
    setDiamondType(type);
    // Call fetch interaction data for the selected diamond type (you might need to adjust the fetchInteractionData function to accept a diamond type)
    fetchInteractionData(type);
    console.log(type, 'diamond type');
  };

  const formatSeconds = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const totalHours = Math.floor(totalSeconds / 3600);
    const days = Math.floor(totalHours / 24);
    const hours = totalHours % 24;
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${days}D ${hours}H ${minutes}M ${seconds}S`;
  };

  const modalStyle = {
    display: showModal ? 'block' : 'none',
    position: 'fixed',
    zIndex: 1000,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '900px',
    maxWidth: '90%',
    height: 'auto',
    maxHeight: '80%',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.75)',
    borderRadius: '12px',
    padding: '0',
    boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
    fontFamily: 'Arial, sans-serif'
  };

  const modalHeaderStyle = {
    padding: '16px',
    backgroundColor: '#007BFF',
    color: '#fff',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center'
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    textAlign: 'center'
  };

  const closeButtonStyle = {
    color: '#fff',
    float: 'right',
    fontSize: '20px',
    fontWeight: 'bold',
    cursor: 'pointer'
  };

  const loaderStyle = {
    margin: '20px auto',
    border: '4px solid #f3f3f3',
    borderRadius: '50%',
    borderTop: '4px solid #007BFF',
    width: '30px',
    height: '30px',
    animation: 'spin 2s linear infinite'
  };

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    margin: '20px 0'
  };

  const thStyle = {
    backgroundColor: '#007BFF',
    color: 'white',
    padding: '10px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd'
  };

  const tdStyle = {
    padding: '10px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd'
  };

  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '14px',
    margin: '5px'
  };

  const activeButtonStyle = {
    ...buttonStyle,
    backgroundColor: 'red'
  };

  const buttonContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: '20px'
  };

  // This function checks if the selected interactionType has data
  // const hasData = (type) => {
  //   console.log(type,'typetypetypetype')
  //   return interactionInfo && interactionInfo[type] && interactionInfo[type].length > 0;
  // };

  return (
    <div style={modalStyle}>
      <div className="modal-header" style={modalHeaderStyle}>
        User Interaction Info
        <span style={closeButtonStyle} onClick={closeModal}>&times;</span>
      </div>
      <div className="modal-content" style={modalContentStyle}>
        <div style={buttonContainerStyle}>
          <button
            style={interactionType === 'userInteraction' ? activeButtonStyle : buttonStyle}
            onClick={() => setInteractionType('userInteraction')}
          >
            User Interaction
          </button>
          <button
            style={interactionType === 'like' ? activeButtonStyle : buttonStyle}
            onClick={() => handleInteractionTypeChange('like')}

          >
            Received Like
          </button>
          <button
            style={interactionType === 'sendLike' ? activeButtonStyle : buttonStyle}
            onClick={() => handleInteractionTypeChange('sendLike')}
          >
            Send Like
          </button>
          <button
            style={interactionType === 'comment' ? activeButtonStyle : buttonStyle}
            onClick={() => handleInteractionTypeChange('comment')}
          >
            Comment
          </button>
          <button
            style={interactionType === 'receivedComment' ? activeButtonStyle : buttonStyle}
            onClick={() => handleInteractionTypeChange('receivedComment')}
          >
            Received Comment
          </button>
          <button
            style={interactionType === 'diamond' ? activeButtonStyle : buttonStyle}
            onClick={() => handleInteractionTypeChange('diamond')}
          >
            Received Diamond
          </button>

          <button
            style={interactionType === 'sendDiamond' ? activeButtonStyle : buttonStyle}
            // onClick={() => setInteractionType('sendDiamond')}
            onClick={() => handleInteractionTypeChange('sendDiamond')}

          >
            Send Diamond
          </button>
          <button
            style={interactionType === 'share' ? activeButtonStyle : buttonStyle}
            // onClick={() => setInteractionType('share')}
            onClick={() => handleInteractionTypeChange('share')}

          >
            Send Share
          </button>
          <button
            style={interactionType === 'receivedShare' ? activeButtonStyle : buttonStyle}
            // onClick={() => setInteractionType('receivedShare')}
            onClick={() => handleInteractionTypeChange('receivedShare')}

          >
            Received Share
          </button>
        </div>
        {interactionType === 'diamond' && (
          <div style={buttonContainerStyle}>
            {/* Diamond Type Buttons */}
            <button
              style={diamondType === 'giftDiamond' ? activeButtonStyle : buttonStyle}
              onClick={() => handleDiamondTypeChange('giftDiamond')}
            >
              Gift Diamond
            </button>
            <button
              style={diamondType === 'postCommentRoseDiamond' ? activeButtonStyle : buttonStyle}
              onClick={() => handleDiamondTypeChange('postCommentRoseDiamond')}
            >
              Rose Diamond
            </button>
            <button
              style={diamondType === 'replyCommentRoseDiamond' ? activeButtonStyle : buttonStyle}
              onClick={() => handleDiamondTypeChange('replyCommentRoseDiamond')}
            >
              Reply Comment  Rose Diamond
            </button>
            <button
              style={diamondType === 'adminDiamond' ? activeButtonStyle : buttonStyle}
              onClick={() => handleDiamondTypeChange('adminDiamond')}
            >
              Admin Diamond
            </button>
          </div>
        )}
        {interactionType === 'sendDiamond' && (
          <div style={buttonContainerStyle}>
            {/* Diamond Type Buttons */}
            <button
              style={diamondType === 'sendgiftDiamond' ? activeButtonStyle : buttonStyle}
              onClick={() => handleDiamondTypeChange('sendgiftDiamond')}
            >
              Send Gift Diamond
            </button>
            <button
              style={diamondType === 'sendpostCommentRoseDiamond' ? activeButtonStyle : buttonStyle}
              onClick={() => handleDiamondTypeChange('sendpostCommentRoseDiamond')}
            >
              Send Rose Diamond
            </button>
            <button
              style={diamondType === 'sendreplyCommentRoseDiamond' ? activeButtonStyle : buttonStyle}
              onClick={() => handleDiamondTypeChange('sendreplyCommentRoseDiamond')}
            >
              Send Reply Comment Rose Diamond
            </button>

          </div>
        )}
        {interactionType === 'comment' && (
          <div style={buttonContainerStyle}>
            {/* Diamond Type Buttons */}
            <button
              style={diamondType === 'postComment' ? activeButtonStyle : buttonStyle}
              onClick={() => handleDiamondTypeChange('postComment')}
            >
              Comment
            </button>
            <button
              style={diamondType === 'commentreply' ? activeButtonStyle : buttonStyle}
              onClick={() => handleDiamondTypeChange('commentreply')}
            >
              Reply Comment
            </button>


          </div>
        )}
        {interactionType === 'receivedComment' && (
          <div style={buttonContainerStyle}>
            {/* Diamond Type Buttons */}
            <button
              style={diamondType === 'receivedpostComment' ? activeButtonStyle : buttonStyle}
              onClick={() => handleDiamondTypeChange('receivedpostComment')}
            >
              Recived Comment
            </button>
            <button
              style={diamondType === 'recivedcommentreply' ? activeButtonStyle : buttonStyle}
              onClick={() => handleDiamondTypeChange('recivedcommentreply')}
            >
              Recived Reply Comment
            </button>


          </div>
        )}


        {loading ? (
          <div style={loaderStyle}></div>
        ) : (
          <>
            {/* <h3>{interactionType}</h3> */}
            {interactionInfo && interactionType === 'userInteraction' ? (
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={thStyle}>Time Period</th>
                    <th style={thStyle}>Interaction Time (DD:HH:MM:SS)</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                    <td style={tdStyle}><strong>Last Hour:</strong></td>
                    <td style={tdStyle}>{formatSeconds(interactionInfo?.totalInteractionTimeLastHour || 0)}</td>
                  </tr> */}
                  <tr>
                    <td style={tdStyle}><strong>Last Day:</strong></td>
                    <td style={tdStyle}>{formatSeconds(interactionInfo?.totalInteractionTimeLastDay || 0)}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last Week:</strong></td>
                    <td style={tdStyle}>{formatSeconds(interactionInfo?.totalInteractionTimeLastWeek || 0)}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last Month:</strong></td>
                    <td style={tdStyle}>{formatSeconds(interactionInfo?.totalInteractionTimeLastMonth || 0)}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 3 Months:</strong></td>
                    <td style={tdStyle}>{formatSeconds(interactionInfo?.totalInteractionTimeLast3Months || 0)}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 6 Months:</strong></td>
                    <td style={tdStyle}>{formatSeconds(interactionInfo?.totalInteractionTimeLast6Months || 0)}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 1 Year:</strong></td>
                    <td style={tdStyle}>{formatSeconds(interactionInfo?.totalInteractionTimeLastYear || 0)}</td>
                  </tr>
                </tbody>
              </table>
            ) : interactionType === 'like' && likedata ? (
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={thStyle}>Time Period</th>
                    <th style={thStyle}>Total Likes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={tdStyle}><strong>Last Day:</strong></td>
                    <td style={tdStyle}>{likedata.totalLikesLastDay}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 30 Days:</strong></td>
                    <td style={tdStyle}>{likedata.totalLikesLast30Days}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 3 Months:</strong></td>
                    <td style={tdStyle}>{likedata.totalLikesLast3Months}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 6 Months:</strong></td>
                    <td style={tdStyle}>{likedata.totalLikesLast6Months}</td>
                  </tr>
                  {/* <tr>
                    <td style={tdStyle}><strong>Last Year:</strong></td>
                    <td style={tdStyle}>{likedata.totalLikesLastYear}</td>
                  </tr> */}
                </tbody>
              </table>
            ) : interactionType === 'sendLike' && sendlikedata ? (
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={thStyle}>Time Period</th>
                    <th style={thStyle}>Total Likes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={tdStyle}><strong>Last Day:</strong></td>
                    <td style={tdStyle}>{sendlikedata.totalLikesLastDay}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 30 Days:</strong></td>
                    <td style={tdStyle}>{sendlikedata.totalLikesLast30Days}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 3 Months:</strong></td>
                    <td style={tdStyle}>{sendlikedata.totalLikesLast3Months}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 6 Months:</strong></td>
                    <td style={tdStyle}>{sendlikedata.totalLikesLast6Months}</td>
                  </tr>
                  {/* <tr>
                    <td style={tdStyle}><strong>Last Year:</strong></td>
                    <td style={tdStyle}>{likedata.totalLikesLastYear}</td>
                  </tr> */}
                </tbody>
              </table>

            ) : diamondType === 'postComment' && commentdata ? (
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={thStyle}>Time Period</th>
                    <th style={thStyle}>Total comment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={tdStyle}><strong>Last Day:</strong></td>
                    <td style={tdStyle}>{commentdata.totalCommentsLastDay}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 30 Days:</strong></td>
                    <td style={tdStyle}>{commentdata.totalCommentsLast30Days}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 3 Months:</strong></td>
                    <td style={tdStyle}>{commentdata.totalCommentsLast3Months}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 6 Months:</strong></td>
                    <td style={tdStyle}>{commentdata.totalCommentsLast6Months}</td>
                  </tr>
                  {/* <tr>
                  <td style={tdStyle}><strong>Last Year:</strong></td>
                  <td style={tdStyle}>{likedata.totalLikesLastYear}</td>
                </tr> */}
                </tbody>
              </table>
            ) : diamondType === 'receivedpostComment' && recivedcommentdata ? (
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={thStyle}>Time Period</th>
                    <th style={thStyle}>Total comment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={tdStyle}><strong>Last Day:</strong></td>
                    <td style={tdStyle}>{recivedcommentdata.totalCommentsLastDay}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 30 Days:</strong></td>
                    <td style={tdStyle}>{recivedcommentdata.totalCommentsLast30Days}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 3 Months:</strong></td>
                    <td style={tdStyle}>{recivedcommentdata.totalCommentsLast3Months}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 6 Months:</strong></td>
                    <td style={tdStyle}>{recivedcommentdata.totalCommentsLast6Months}</td>
                  </tr>
                  {/* <tr>
                  <td style={tdStyle}><strong>Last Year:</strong></td>
                  <td style={tdStyle}>{likedata.totalLikesLastYear}</td>
                </tr> */}
                </tbody>
              </table>
            ) : diamondType === 'giftDiamond' && diamonddata ? (
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={thStyle}>Time Period</th>
                    <th style={thStyle}>Total Diamond</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={tdStyle}><strong>Last Day:</strong></td>
                    <td style={tdStyle}>{diamonddata.last_day}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 30 Days:</strong></td>
                    <td style={tdStyle}>{diamonddata.last_30_days}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 3 Months:</strong></td>
                    <td style={tdStyle}>{diamonddata.last_3_months}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 6 Months:</strong></td>
                    <td style={tdStyle}>{diamonddata.last_6_months}</td>
                  </tr>
                </tbody>
              </table>

            ) : diamondType === 'postCommentRoseDiamond' && diamonddata ? (
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={thStyle}>Time Period</th>
                    <th style={thStyle}>Total Diamond</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={tdStyle}><strong>Last Day:</strong></td>
                    <td style={tdStyle}>{diamonddata.last_day}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 30 Days:</strong></td>
                    <td style={tdStyle}>{diamonddata.last_30_days}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 3 Months:</strong></td>
                    <td style={tdStyle}>{diamonddata.last_3_months}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 6 Months:</strong></td>
                    <td style={tdStyle}>{diamonddata.last_6_months}</td>
                  </tr>
                </tbody>
              </table>

            ) : diamondType === 'replyCommentRoseDiamond' && diamonddata ? (
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={thStyle}>Time Period</th>
                    <th style={thStyle}>Total Diamond</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={tdStyle}><strong>Last Day:</strong></td>
                    <td style={tdStyle}>{diamonddata.last_day}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 30 Days:</strong></td>
                    <td style={tdStyle}>{diamonddata.last_30_days}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 3 Months:</strong></td>
                    <td style={tdStyle}>{diamonddata.last_3_months}</td>
                  </tr>
                  <tr>
                    <td style={tdStyle}><strong>Last 6 Months:</strong></td>
                    <td style={tdStyle}>{diamonddata.last_6_months}</td>
                  </tr>
                </tbody>
              </table>

            ) : diamondType === 'adminDiamond' && diamonddata ?
              (
                <table style={tableStyle}>
                  <thead>
                    <tr>
                      <th style={thStyle}>Time Period</th>
                      <th style={thStyle}>Total Diamond</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={tdStyle}><strong>Last Day:</strong></td>
                      <td style={tdStyle}>{diamonddata.last_day}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 30 Days:</strong></td>
                      <td style={tdStyle}>{diamonddata.last_30_days}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 3 Months:</strong></td>
                      <td style={tdStyle}>{diamonddata.last_3_months}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 6 Months:</strong></td>
                      <td style={tdStyle}>{diamonddata.last_6_months}</td>
                    </tr>
                  </tbody>
                </table>


              ) : diamondType === 'sendgiftDiamond' && senddiamonddata ? (
                <table style={tableStyle}>
                  <thead>
                    <tr>
                      <th style={thStyle}>Time Period</th>
                      <th style={thStyle}>Total Diamond</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={tdStyle}><strong>Last Day:</strong></td>
                      <td style={tdStyle}>{senddiamonddata.last_day}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 30 Days:</strong></td>
                      <td style={tdStyle}>{senddiamonddata.last_30_days}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 3 Months:</strong></td>
                      <td style={tdStyle}>{senddiamonddata.last_3_months}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 6 Months:</strong></td>
                      <td style={tdStyle}>{senddiamonddata.last_6_months}</td>
                    </tr>
                  </tbody>
                </table>

              ) : diamondType === 'sendpostCommentRoseDiamond' && senddiamonddata ? (
                <table style={tableStyle}>
                  <thead>
                    <tr>
                      <th style={thStyle}>Time Period</th>
                      <th style={thStyle}>Total Diamond</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={tdStyle}><strong>Last Day:</strong></td>
                      <td style={tdStyle}>{senddiamonddata.last_day}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 30 Days:</strong></td>
                      <td style={tdStyle}>{senddiamonddata.last_30_days}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 3 Months:</strong></td>
                      <td style={tdStyle}>{senddiamonddata.last_3_months}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 6 Months:</strong></td>
                      <td style={tdStyle}>{senddiamonddata.last_6_months}</td>
                    </tr>
                  </tbody>
                </table>

              ) : diamondType === 'sendreplyCommentRoseDiamond' && senddiamonddata ? (
                <table style={tableStyle}>
                  <thead>
                    <tr>
                      <th style={thStyle}>Time Period</th>
                      <th style={thStyle}>Total Diamond</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={tdStyle}><strong>Last Day:</strong></td>
                      <td style={tdStyle}>{senddiamonddata.last_day}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 30 Days:</strong></td>
                      <td style={tdStyle}>{senddiamonddata.last_30_days}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 3 Months:</strong></td>
                      <td style={tdStyle}>{senddiamonddata.last_3_months}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 6 Months:</strong></td>
                      <td style={tdStyle}>{senddiamonddata.last_6_months}</td>
                    </tr>
                  </tbody>
                </table>

              ) : interactionType === 'share' && sharedata ? (
                <table style={tableStyle}>
                  <thead>
                    <tr>
                      <th style={thStyle}>Time Period</th>
                      <th style={thStyle}>Total Share</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={tdStyle}><strong>Last Day:</strong></td>
                      <td style={tdStyle}>{sharedata.last_day}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 30 Days:</strong></td>
                      <td style={tdStyle}>{sharedata.last_30_days}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 3 Months:</strong></td>
                      <td style={tdStyle}>{sharedata.last_3_months}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 6 Months:</strong></td>
                      <td style={tdStyle}>{sharedata.last_6_months}</td>
                    </tr>
                  </tbody>
                </table>

              ) : interactionType === 'receivedShare' && sharedata ? (
                <table style={tableStyle}>
                  <thead>
                    <tr>
                      <th style={thStyle}>Time Period</th>
                      <th style={thStyle}>Total Share</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={tdStyle}><strong>Last Day:</strong></td>
                      <td style={tdStyle}>{sharedata.last_day}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 30 Days:</strong></td>
                      <td style={tdStyle}>{sharedata.last_30_days}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 3 Months:</strong></td>
                      <td style={tdStyle}>{sharedata.last_3_months}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 6 Months:</strong></td>
                      <td style={tdStyle}>{sharedata.last_6_months}</td>
                    </tr>
                  </tbody>
                </table>
              ) : diamondType === 'commentreply' && commentdata ? (
                <table style={tableStyle}>
                  <thead>
                    <tr>
                      <th style={thStyle}>Time Period</th>
                      <th style={thStyle}>Total Comment</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={tdStyle}><strong>Last Day:</strong></td>
                      <td style={tdStyle}>{commentdata.last_day}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 30 Days:</strong></td>
                      <td style={tdStyle}>{commentdata.last_30_days}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 3 Months:</strong></td>
                      <td style={tdStyle}>{commentdata.last_3_months}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 6 Months:</strong></td>
                      <td style={tdStyle}>{commentdata.last_6_months}</td>
                    </tr>
                  </tbody>
                </table>
              ) : diamondType === 'recivedcommentreply' && recivedcommentdata ? (
                <table style={tableStyle}>
                  <thead>
                    <tr>
                      <th style={thStyle}>Time Period</th>
                      <th style={thStyle}>Total comment</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={tdStyle}><strong>Last Day:</strong></td>
                      <td style={tdStyle}>{recivedcommentdata.totalCommentsLastDay}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 30 Days:</strong></td>
                      <td style={tdStyle}>{recivedcommentdata.totalCommentsLast30Days}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 3 Months:</strong></td>
                      <td style={tdStyle}>{recivedcommentdata.totalCommentsLast3Months}</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}><strong>Last 6 Months:</strong></td>
                      <td style={tdStyle}>{recivedcommentdata.totalCommentsLast6Months}</td>
                    </tr>
                    {/* <tr>
                  <td style={tdStyle}><strong>Last Year:</strong></td>
                  <td style={tdStyle}>{likedata.totalLikesLastYear}</td>
                </tr> */}
                  </tbody>
                </table>
              ) :
                (
                  <p>No data available</p>
                )}

          </>
        )}
        <button style={buttonStyle} onClick={closeModal}>Close</button>
      </div>
    </div>
  );
};





// const MuteCommentModal = () => {
//   const [reason, setReason] = useState('');
//   const [hours, setHours] = useState(0);
//   const [minutes, setMinutes] = useState(0);
//   const [seconds, setSeconds] = useState(0);
//   const [days, setDays] = useState(0);

//   const handleBlock = () => {
//     onClose();
//     // const totalSeconds = hours * 3600 + minutes * 60 + seconds;
//     const totalSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;

//     if (window.confirm(`Are you sure you want to block the user for ${hours} hours, ${minutes} minutes, and ${seconds} seconds?`)) {
//       // onBlock(totalSeconds, reason);
//     }
//   };

//   const modalStyles = {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     position: 'fixed',
//     top: '0',
//     left: '0',
//     width: '100%',
//     height: '100%',
//     backgroundColor: 'rgba(0, 0, 0, 0.7)',
//     zIndex: '1000',
//   };

//   const modalContentStyles = {
//     backgroundColor: '#fff',
//     padding: '30px',
//     borderRadius: '10px',
//     width: '400px',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     textAlign: 'center',
//   };

//   const textareaStyles = {
//     width: '100%',
//     padding: '15px',
//     margin: '15px 0',
//     borderRadius: '5px',
//     border: '1px solid #ccc',
//     resize: 'none',
//   };

//   const buttonStyles = {
//     backgroundColor: '#007BFF',
//     color: 'white',
//     padding: '12px 25px',
//     margin: '10px 5px',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     transition: 'background-color 0.3s ease',
//   };

//   const buttonCancelStyles = {
//     backgroundColor: '#6c757d',
//     color: 'white',
//     padding: '12px 25px',
//     margin: '10px 5px',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     transition: 'background-color 0.3s ease',
//   };

//   return (
//     <div style={modalStyles}>
//       <div style={modalContentStyles}>
//         <h2 style={{ color: 'red', marginBottom: '20px' }}>User Comment Mute</h2>
//         <p style={{ marginBottom: '20px' }}>Select the time after which the user will be Mute:</p>
//         <TimePicker label="Days" value={days} onChange={setDays} />

//         <TimePicker label="Hours" value={hours} onChange={setHours} />
//         <TimePicker label="Minutes" value={minutes} onChange={setMinutes} />
//         <TimePicker label="Seconds" value={seconds} onChange={setSeconds} />
//         <textarea
//           value={reason}
//           onChange={(e) => setReason(e.target.value)}
//           placeholder="Reason for Blocking"
//           rows="3"
//           style={textareaStyles}
//         />
//         <button
//           style={{ ...buttonStyles, backgroundColor: '#007BFF' }}
//           onClick={handleBlock}
//           onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056b3')}
//           onMouseLeave={(e) => (e.target.style.backgroundColor = '#007BFF')}
//         >
//           Mute Comment 
//         </button>
//         <button
//           style={{ ...buttonCancelStyles, backgroundColor: '#6c757d' }}
//           onClick={onClose}
//           onMouseEnter={(e) => (e.target.style.backgroundColor = '#5a6268')}
//           onMouseLeave={(e) => (e.target.style.backgroundColor = '#6c757d')}
//         >
//           Cancel
//         </button>
//       </div>
//     </div>
//   );
// };


const CustomTimePicker = ({ label, value, onChange, options }) => (
  <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
    <label style={{ fontWeight: '600', marginRight: '10px', flex: '1' }}>{label}:</label>
    <select
      value={value}
      onChange={(e) => onChange(Number(e.target.value))}
      style={{
        flex: '2',
        padding: '8px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        textAlign: 'center',
        boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
      }}
    >
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  </div>
);

const MuteCommentModal = ({ onClose,onMute }) => {
  const [reason, setReason] = useState('');
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [days, setDays] = useState(0);

  const dayOptions = Array.from({ length: 31 }, (_, i) => i); // 0 to 30 days
  const hourOptions = Array.from({ length: 24 }, (_, i) => i); // 0 to 23 hours
  const minuteOptions = Array.from({ length: 60 }, (_, i) => i); // 0 to 59 minutes
  const secondOptions = Array.from({ length: 60 }, (_, i) => i); // 0 to 59 seconds

  const handleBlock = () => {
    const totalSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;
console.log(totalSeconds,reason,'reasonreasonreasonreason')
       if (window.confirm(`Are you sure you want to block the user for ${hours} hours, ${minutes} minutes, and ${seconds} seconds?`)) {
        onMute(totalSeconds, reason);
    }
    {
      onClose();
      // Perform mute action with totalSeconds and reason
    }
   
  };

  return (
    <div style={modalOverlayStyles}>
      <div style={modalContainerStyles}>
        <h2 style={modalTitleStyles}>Mute User Comments</h2>
        <p style={modalDescriptionStyles}>
          Specify the duration for muting the user and provide a reason:
        </p>

        <CustomTimePicker label="Days" value={days} onChange={setDays} options={dayOptions} />
        <CustomTimePicker label="Hours" value={hours} onChange={setHours} options={hourOptions} />
        <CustomTimePicker label="Minutes" value={minutes} onChange={setMinutes} options={minuteOptions} />
        <CustomTimePicker label="Seconds" value={seconds} onChange={setSeconds} options={secondOptions} />

        <textarea
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Reason for muting"
          rows="4"
          style={textareaStyles}
        />

        <div style={buttonGroupStyles}>
          <button
            style={primaryButtonStyles}
            onClick={handleBlock}
            onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056b3')}
            onMouseLeave={(e) => (e.target.style.backgroundColor = '#007BFF')}
          >
            Confirm Mute
          </button>
          <button
            style={secondaryButtonStyles}
            onClick={onClose}
            onMouseEnter={(e) => (e.target.style.backgroundColor = '#5a6268')}
            onMouseLeave={(e) => (e.target.style.backgroundColor = '#6c757d')}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const modalOverlayStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  zIndex: '1000',
};

const modalContainerStyles = {
  backgroundColor: '#fff',
  padding: '30px',
  borderRadius: '15px',
  width: '450px',
  boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
  textAlign: 'center',
};

const modalTitleStyles = {
  fontSize: '24px',
  fontWeight: '700',
  color: '#333',
  marginBottom: '15px',
};

const modalDescriptionStyles = {
  fontSize: '14px',
  color: '#666',
  marginBottom: '20px',
};

const textareaStyles = {
  width: '100%',
  padding: '12px',
  borderRadius: '8px',
  border: '1px solid #ccc',
  resize: 'none',
  boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
  marginBottom: '20px',
};

const buttonGroupStyles = {
  display: 'flex',
  justifyContent: 'space-between',
};

const primaryButtonStyles = {
  backgroundColor: '#007BFF',
  color: '#fff',
  padding: '12px 20px',
  borderRadius: '8px',
  border: 'none',
  cursor: 'pointer',
  fontSize: '14px',
  transition: 'background-color 0.3s ease',
};

const secondaryButtonStyles = {
  backgroundColor: '#6c757d',
  color: '#fff',
  padding: '12px 20px',
  borderRadius: '8px',
  border: 'none',
  cursor: 'pointer',
  fontSize: '14px',
  transition: 'background-color 0.3s ease',
};
